import useNotification from 'hooks/useNotification';
import { Avatar, Button, Card, Dropdown, Flex, Tag, Typography } from 'antd';
import {
  EllipsisOutlined,
  EyeOutlined,
  HistoryOutlined
} from '@ant-design/icons';
import { EvaluationsSubheader } from 'components/professor_area/EvaluationsSubheader';
import { ModalEditEvaluationScore } from 'components/professor_area/ModalEditEvaluationScore';
import { ModalEvaluationHistory } from 'components/professor_area/ModalEvaluationHistory';
import { ShowLayout } from 'components/professor_area/layout/ShowLayout';
import { UserAttemptsTable } from './components/UserAttemptsTable';
import { formatDate } from 'services/date';
import { getAPI } from 'requests/api';
import { getInitialsName } from 'utils/get-initials-name';
import { useCallback, useMemo, useState } from 'react';
import { useCustom, useModal, useNavigation } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { useProfessorAreaAssignmentsStore } from '../assignments.store';
import type { IProfessorAreaEvaluationAttemptShow } from 'interfaces/professor_area/evaluations';
import type { MenuInfo } from 'rc-menu/lib/interface';
import type { MenuProps } from 'antd/lib';

const itemsDropdown: MenuProps['items'] = [
  {
    key: 'history',
    icon: <HistoryOutlined />,
    label: 'Visualizar histórico'
  }
];

export const ProfessorAreaAssignmentAttemptShow = () => {
  const params = useParams();
  const classroomId = params.classroomId;
  const evaluationId = params.evaluationId;
  const attemptId = params.id!;
  const [correctionLoading, setCorrectionLoading] = useState({
    id: '',
    loading: false
  });

  const selectedEvaluation = useProfessorAreaAssignmentsStore(
    s => s.selectedEvaluation
  );

  const historyModal = useModal();
  const editScoreModal = useModal();

  const { edit } = useNavigation();
  const { showError } = useNotification();

  const res = useCustom<IProfessorAreaEvaluationAttemptShow>({
    url: `/admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${attemptId}`,
    method: 'get',
    config: {
      query: {
        user_id: selectedEvaluation?.user?.id
      }
    }
  });

  const { data, isLoading, isFetched } = res;

  const record = useMemo(
    () => data?.data || ({} as IProfessorAreaEvaluationAttemptShow),
    [data]
  );

  const menuEdit = useMemo(() => {
    if (record && record?.score === 0) {
      return null;
    }

    return {
      key: 'edit',
      icon: <EyeOutlined />,
      label: 'Editar nota'
    };
  }, [record]);

  const handleMenuClick = useCallback(
    (event: MenuInfo) => {
      switch (event.key) {
        case 'edit': {
          editScoreModal.show();
          break;
        }

        case 'history': {
          historyModal.show();
          break;
        }

        default: {
          break;
        }
      }
    },
    [editScoreModal, historyModal]
  );

  const menu = {
    items: [menuEdit, ...itemsDropdown],
    onClick: handleMenuClick
  };

  const navigateToEditAttempt = useCallback(
    (userAttemptId: string) => {
      edit(
        'professor_area/classroom_assignment/attempt',
        userAttemptId,
        'push',
        {
          classroomId,
          evaluationId
        }
      );
    },
    [classroomId, edit, evaluationId]
  );

  const startAttemptCorrection = useCallback(
    async (userAttemptId: string) => {
      setCorrectionLoading({
        id: userAttemptId,
        loading: true
      });

      try {
        if (record.status === 'pending_evaluation') {
          const { data, status } = await getAPI().put(
            `/admin/evaluations/assignments/attempts/${record.assignment_id}`
          );

          // TODO: Devemos mudar para esse endpoint, assim que o B.E estiver pronto
          // const { status } = await getAPI().put(
          //   `/admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${userAttemptId}`
          // );

          if (status === 200) {
            return navigateToEditAttempt(data.id);
          }
        }

        return navigateToEditAttempt(userAttemptId);
      } catch {
        showError(
          'Não foi possível iniciar o processo de correção. Tente novamente!'
        );
      } finally {
        setCorrectionLoading({
          id: '',
          loading: false
        });
      }
    },
    [navigateToEditAttempt, record.assignment_id, record.status, showError]
  );

  const onAttemptClick = useCallback(
    async (usetAttempt: IProfessorAreaEvaluationAttemptShow) => {
      if (usetAttempt.status === 'pending_evaluation') {
        await startAttemptCorrection(usetAttempt.id);
      } else {
        navigateToEditAttempt(usetAttempt.id);
      }
    },
    [navigateToEditAttempt, startAttemptCorrection]
  );

  return (
    <ShowLayout record={record} isLoading={isLoading} isFetched={isFetched}>
      <Card>
        <Flex align="center" justify="space-between" gap={16}>
          <Flex vertical gap={8} flex={1}>
            <Flex align="center" gap={8}>
              <Avatar className="bg-primary-500/10 text-primary-500">
                {getInitialsName(record?.user?.name)}
              </Avatar>
              <Typography.Text className="text-gray-400">
                {record?.user?.name}
              </Typography.Text>
            </Flex>

            <Typography.Title level={3} className="!mb-0">
              {selectedEvaluation?.evaluation.name}
            </Typography.Title>

            <Flex>
              <Tag>Atividade</Tag>
              <Tag>{selectedEvaluation?.classroom.code}</Tag>
              <Tag>{selectedEvaluation?.classroom.discipline}</Tag>
            </Flex>
          </Flex>

          <Dropdown menu={menu} trigger={['click']}>
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Flex>
      </Card>

      <EvaluationsSubheader
        fieldsData={[
          {
            label: 'Pontuação',
            value: record?.score?.toString() || '-'
          },
          {
            label: 'Nota mínima',
            value: record?.passing_score?.toString() || '-'
          },
          {
            label: 'Tentativas',
            value: record?.total_attempts?.toString() || '-'
          },
          {
            label: 'Data de último envio',
            value: formatDate(record?.created_at, 'DD/MM/YYYY hh:mm') || '-'
          }
        ]}
        status={record.status || 'pending'}
        score={record.score}
      />

      <Card>
        <Typography.Title level={4}>Tentativas do aluno</Typography.Title>

        <UserAttemptsTable
          dataSource={record?.user_attempts}
          correctionLoading={correctionLoading.loading}
          onAttemptClick={onAttemptClick}
        />
      </Card>

      {historyModal.visible && (
        <ModalEvaluationHistory
          resource=""
          attemptId={attemptId}
          modal={historyModal}
        />
      )}

      {editScoreModal.visible && (
        <ModalEditEvaluationScore
          resource=""
          attempt={record}
          modal={editScoreModal}
        />
      )}
    </ShowLayout>
  );
};
