import React from 'react';
import { EditButton, TagField, TextField } from '@refinedev/antd';
import { EyeOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { UserAttempt } from 'interfaces/professor_area/assessments';
import { evaluationsAttemptsStatus } from 'constants/admin/evaluations';
import { formatDate } from 'services/date';
import { getStatusData } from 'services/professor_area/evaluations';

export const AttemptsShowTable: React.FC<{
  dataSource: UserAttempt[];
}> = ({ dataSource }) => {
  return (
    <Table dataSource={dataSource}>
      <Table.Column
        key="id"
        title="Tentativa"
        render={(_, __, index) => <TextField value={index + 1} />}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de envio"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY HH:MM')} />
        )}
      />
      {/* //TODO: Add when the evaluator record will be available
      <Table.Column
        dataIndex="updated_at"
        key="updated_at"
        title="Data de correção"
        render={value => <TextField value={value ?? '-'} />}
      />
      <Table.Column
        dataIndex="professor"
        key="professor"
        title="Corrigido por"
        render={value => <TextField value={value ?? '-'} />}
      /> */}
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={getStatusData(value, evaluationsAttemptsStatus)?.color}
            value={getStatusData(value, evaluationsAttemptsStatus)?.label}
          />
        )}
      />
      <Table.Column
        dataIndex="score"
        key="score"
        title="Nota"
        render={value => <TextField value={value ?? '-'} />}
      />
      <Table.Column<UserAttempt>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <EditButton
              size="small"
              icon={
                !['pending_evaluation', 'evaluation_started'].includes(
                  record.status
                ) ? (
                  <EyeOutlined />
                ) : undefined
              }
              resource="professor_area/classroom_assessment/attempt"
              recordItemId={record.id}
            >
              {['pending_evaluation', 'evaluation_started'].includes(
                record.status
              )
                ? 'Corrigir'
                : ''}
            </EditButton>
          );
        }}
      />
    </Table>
  );
};
