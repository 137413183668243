import { Button, Pagination, Space, Spin, Tag, Typography } from 'antd';
import { ContentPreviewModal } from 'components/modal/content-tree/ContentPreviewModal';
import { PlusOutlined } from '@ant-design/icons';
import { SupplementarMaterialUploadModal } from 'components/modal/professor_area/classroom/SupplementarMaterialUploadModal';
import { SupplementaryMaterialItem } from 'components/forms/SupplementaryMaterialForm';
import { TableRowItem } from './TableRowItem';
import { list as listSupplementaryMaterials } from 'requests/supplementary-materials';
import { useCallback, useEffect, useState } from 'react';
import { useModal, useModalForm } from '@refinedev/antd';

export interface TabSupplementarMaterialProps {
  classroomId: string;
}

export const TabSupplementarMaterial = ({
  classroomId
}: TabSupplementarMaterialProps) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [savedValues, setSavedValues] = useState<SupplementaryMaterialItem[]>(
    []
  );

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    close: createModalClose
  } = useModalForm({
    resource: 'supplementary_materials',
    action: 'create',
    warnWhenUnsavedChanges: false
  });
  const { modalProps: previewModalProps, show: previewModalShow } = useModal(
    {}
  );

  const getSavedItems = useCallback(() => {
    setLoading(true);
    listSupplementaryMaterials({
      filter: { parent_reference: classroomId },
      items: 100
    })
      .then(res => {
        const values = res.map(
          ({ id, url, title, created_at, preview_url }) => ({
            id,
            name: title,
            url,
            created_at,
            preview_url
          })
        );
        setSavedValues(values);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [classroomId]);

  useEffect(() => {
    getSavedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroomId]);

  const handlePreview = useCallback(
    (fileUrl: string) => {
      setPreviewUrl(fileUrl);
      previewModalShow();
    },
    [setPreviewUrl, previewModalShow]
  );

  if (loading) {
    return (
      <Space
        direction="vertical"
        className="w-full flex items-center justify-center"
      >
        <Spin />
      </Space>
    );
  }

  return (
    <>
      <Space className="flex w-full justify-between mb-4">
        <Space size="middle">
          <Typography.Title level={5} className="!m-0">
            Lista de Materiais
          </Typography.Title>
          <Tag>{savedValues.length}</Tag>
        </Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => createModalShow()}
        >
          Adicionar Material
        </Button>
      </Space>

      {savedValues?.map((file, index) => (
        <TableRowItem
          key={index.toString()}
          file={file}
          getSavedItems={getSavedItems}
          previewModalShow={handlePreview}
        />
      ))}

      <Pagination
        className="justify-self-end"
        hideOnSinglePage
        total={savedValues.length}
        pageSize={6}
      />

      <SupplementarMaterialUploadModal
        key="UploadFile"
        formProps={createFormProps}
        modalProps={createModalProps}
        closeModal={createModalClose}
        getSavedItems={getSavedItems}
        classroomId={classroomId}
      />

      <ContentPreviewModal
        modalProps={previewModalProps}
        previewUrl={previewUrl}
      />
    </>
  );
};
