import { google } from 'requests/auth';
import { setSession } from 'services/auth';
import { useEffect } from 'react';

export const GoogleLogin: React.FC = () => {
  const { href, search } = window.location;

  const qs = new URLSearchParams(search);
  const code = qs.get('code');

  useEffect(() => {
    if (code) {
      google({ code, signupPage: href })
        .then(async session => {
          await setSession(session);
          window.location.replace('/home');
        })
        .catch(() => {
          window.location.replace('/login');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <div />;
};
