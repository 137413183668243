import { Col, Form, FormItemProps, Row } from 'antd';
import type { ReactNode } from 'react';

interface FormItemColProps extends FormItemProps {
  label: ReactNode;
  children: ReactNode;
  itemsCenter?: boolean;
  cols?: number[];
  labelClassNames?: string;
}

export const FormItemCol = ({
  children,
  label,
  itemsCenter = true,
  cols,
  labelClassNames,
  ...props
}: FormItemColProps) => {
  return (
    <Form.Item hidden={props.hidden} className={props.className}>
      <Row gutter={[16, 16]} className={itemsCenter ? 'items-center' : ''}>
        <Col
          xs={24}
          md={cols?.[0] ?? 4}
          className="ant-form-item-label !text-left"
        >
          <label
            className={`flex items-center gap-1 after:hidden ${labelClassNames}`}
            htmlFor={props.name}
          >
            {label}
          </label>
        </Col>
        <Col xs={24} md={cols?.[1] ?? 20}>
          <Form.Item {...props} noStyle>
            {children}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};
