import * as Icons from '@ant-design/icons';
import { Button, Card, Flex, Space, Typography } from 'antd';
import { IProfessorClassroom } from 'interfaces/professor_area/classrooms';
import { TagField } from '@refinedev/antd';
import { useShowButton } from '@refinedev/core';

export interface ClassroomCardProps {
  classroom: IProfessorClassroom;
}

export const ClassroomCard = ({ classroom }: ClassroomCardProps) => {
  const { to, disabled, LinkComponent } = useShowButton({
    id: classroom.id
  });

  return (
    <Card className="flex" styles={{ body: { width: '100%' } }}>
      <Flex vertical gap={16} className="w-full">
        <Flex justify="space-between" align="start">
          <Typography.Title level={5} className="!mb-0 mr-4 line-clamp-2">
            {classroom.discipline_name}
          </Typography.Title>
          <LinkComponent
            to={to}
            replace={false}
            onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
              if (disabled) {
                e.preventDefault();
              }
            }}
          >
            <Button
              icon={<Icons.ArrowRightOutlined className="text-white" />}
              type="primary"
            />
          </LinkComponent>
        </Flex>

        <Flex vertical gap={16}>
          <Flex>
            <Space>
              <Icons.TeamOutlined />
              <Typography.Paragraph className="!mb-0">
                {`${classroom.active_enrollments_count} aluno(s)`}
              </Typography.Paragraph>
            </Space>
          </Flex>

          <Flex>
            <TagField value={classroom.code} color="default" />
            <TagField
              value={`${classroom.academic_year}.${classroom.academic_period}`}
              color="default"
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
