import Tree from 'components/tree/Tree';
import { Alert, Divider, Form, Input, InputNumber, Select, Tabs } from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import {
  IGraduateDisciplineModules,
  IGraduateDisciplineShow,
  IGraduateDisciplineTree
} from 'interfaces/graduate_disciplines';
import { TabClassrooms } from './components/TabClassrooms';
import { Typography } from 'antd/lib';
import { getSelectOptionWithDefault } from 'utils/get-select-options-with-default';
import { getTenant } from 'services/tenants';
import { removeDuplicateObjectsById } from 'utils/remove-duplicate-objects';
import { useEffect, useMemo, useState } from 'react';
import { useGraduateDisciplineStore } from './graduate_disciplines.store';

export const GraduateDisciplineEdit = () => {
  const { name } = getTenant();
  const [activeTab, setActiveTab] = useState('info');
  const { form, formProps, saveButtonProps, queryResult } =
    useForm<IGraduateDisciplineShow>({
      redirect: 'edit'
    });
  const [contentTree, setContentTree] = useState<IGraduateDisciplineTree>();

  const moduleIds = queryResult?.data?.data.modules.map(e => e.id);

  const {
    selectProps: modulesSelectProps,
    queryResult: modulesQueryResult,
    defaultValueQueryResult
  } = useSelect<IGraduateDisciplineModules>({
    resource: 'graduate_modules',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: moduleIds,

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const discipline = queryResult?.data?.data;

  const setDiscipline = useGraduateDisciplineStore(s => s.setDiscipline);

  useEffect(() => {
    if (discipline) {
      setDiscipline(discipline);
    }
  }, [discipline, setDiscipline]);

  const modules = useMemo(() => {
    const modulesWithDefault = getSelectOptionWithDefault(
      defaultValueQueryResult,
      modulesQueryResult
    );

    if (modulesWithDefault) {
      const mods = removeDuplicateObjectsById(modulesWithDefault);

      return mods?.map(module => {
        const {
          external_reference,
          name,
          course_external_reference,
          course_name,
          id
        } = module;

        const codModuleExternal = external_reference
          ? `${external_reference} - `
          : '';

        const codCourseExternal = course_external_reference
          ? `${course_external_reference} - `
          : '';

        const label = `Módulo: ${codModuleExternal} ${name} | Curso: ${codCourseExternal} ${course_name}`;

        return {
          label,
          value: id
        };
      });
    }
  }, [defaultValueQueryResult, modulesQueryResult]);

  useEffect(() => {
    if (discipline) {
      setContentTree(discipline.content_tree);
    }
  }, [discipline]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (data: any) => {
    formProps.onFinish!({
      ...data,
      content_tree: contentTree,
      code: name?.slice(0, 3).toUpperCase() + form.getFieldValue('code')
    });
  };

  const onUpdateTree = (data: IGraduateDisciplineTree) => {
    setContentTree(data);
  };

  return (
    <Edit
      title="Editar disciplina"
      footerButtonProps={{
        style: {
          justifyContent: 'flex-end',
          display: activeTab === 'info' ? 'flex' : 'none'
        }
      }}
      saveButtonProps={saveButtonProps}
    >
      <Tabs
        defaultActiveKey={activeTab}
        onChange={activeKey => setActiveTab(activeKey)}
      >
        <Tabs.TabPane tab="Informações gerais" key="info">
          <Form
            {...formProps}
            initialValues={{
              ...queryResult?.data?.data,
              code: queryResult?.data?.data?.code?.slice(3)
            }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Código da disciplina"
              name="code"
              rules={[
                {
                  required: true
                },
                {
                  pattern: new RegExp(/^\d{4}$/),
                  message: 'Insira um numero válido de 4 digitos'
                }
              ]}
            >
              <Input
                className="max-w-[110px]"
                maxLength={4}
                addonBefore={name?.slice(0, 3)?.toUpperCase()}
              />
            </Form.Item>
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Duração (minutos)"
              name="duration_in_minutes"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item label="Modulos" name="module_ids">
              <Select
                {...modulesSelectProps}
                options={modules}
                mode="multiple"
              />
            </Form.Item>
          </Form>

          {discipline && (
            <>
              <Divider />
              <Typography.Title level={5}>
                Estrutura da disciplina
              </Typography.Title>
              <Alert
                style={{ marginBottom: 16 }}
                message="Atenção: Turmas já existentes não serão afetadas. As alterações realizadas serão aplicadas apenas às novas turmas"
                type="warning"
                showIcon
              />
              <Tree tree={discipline.content_tree} onUpdate={onUpdateTree} />
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Turmas" key="classrooms">
          <TabClassrooms />
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
