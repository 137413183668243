import React, { useEffect, useMemo, useState } from 'react';
import { DocumentList } from './components/DocumentList';
import {
  DocumentationProvider,
  useDocumentationStore
} from './documentation-store';
import { Edit, useForm } from '@refinedev/antd';
import {
  IDocumentTemplate,
  IDocumentTemplateCategory,
  IDocumentTemplateCourse
} from 'interfaces/documentation/document-template';
import { IDocumentation } from 'interfaces/documentation/documentations';
import { ModalApproveDocument } from './components/ModalApproveDocument';
import { ModalRejectDocument } from './components/ModalRejectDocument';
import { ModalRejectDocumentation } from './components/ModalRejectDocumentation';
import { ModalSetKind } from './components/ModalSetKind';
import { ModalShowDocument } from './components/ModalShowDocument';
import { Space } from 'antd';
import { StudentInformation } from './components/StudentInformation';
import { SupplementaryDocuments } from './components/SupplementaryDocuments';
import { useList, useModal } from '@refinedev/core';

const CATEGORY_MAP: Record<IDocumentTemplateCategory, string> = {
  academic: 'Documentação acadêmica',
  identification: 'Documentação de identificação',
  supplementary: 'Documentação complementar'
};

type DocumentationData = Record<IDocumentTemplateCategory, DocumentationMap>;

interface DocumentationMap {
  title: string;
  templates: IDocumentTemplate[];
}

export const DocumentationEdit = () => {
  return (
    <DocumentationProvider>
      <DocumentationEditContent />
    </DocumentationProvider>
  );
};

export const DocumentationEditContent = () => {
  const [documentTemplates, setDocumentTemplates] = useState<
    IDocumentTemplate[]
  >([]);
  const setQueryHandler = useDocumentationStore(s => s.setQueryHandler);
  const { saveButtonProps, queryResult } = useForm<IDocumentation>({
    redirect: 'edit'
  });

  const formParent = useMemo(
    () => queryResult?.data?.data.parent,
    [queryResult]
  );

  const { data: supplementary } = useList<IDocumentTemplate>({
    resource: 'documents/templates',

    filters: [
      {
        field: 'filter[supplementary_by_course_id]',
        operator: 'eq',
        value: formParent?.course.id
      }
    ],

    pagination: {
      pageSize: 99
    }
  });

  const { data: templateCourses } = useList<IDocumentTemplateCourse>({
    resource: 'documents/template_courses',

    filters: [
      {
        field: 'filter[by_course_id]',
        operator: 'eq',
        value: formParent?.course.id
      }
    ],

    pagination: {
      pageSize: 99
    }
  });

  const { data: statics } = useList<IDocumentTemplate>({
    resource: 'documents/templates',

    filters: [
      {
        field: 'filter[by_category][]',
        operator: 'eq',
        value: ['academic', 'identification']
      },
      {
        field: 'filter[by_course_id]',
        operator: 'eq',
        value: formParent?.course.id
      }
    ],

    pagination: {
      pageSize: 99
    }
  });

  useEffect(() => {
    const handleQueryRefetch = async () => {
      await queryResult?.refetch();
    };

    setQueryHandler(handleQueryRefetch);
  }, [setQueryHandler, queryResult]);

  useEffect(() => {
    setDocumentTemplates([
      ...(statics?.data || []),
      ...(supplementary?.data || [])
    ]);
  }, [statics, supplementary]);

  const documents = useMemo(
    () => queryResult?.data?.data?.documents || [],
    [queryResult]
  );

  const documentationByCategory = useMemo(() => {
    const categorizedDocuments = {} as DocumentationData;

    for (const template of documentTemplates) {
      const { category } = template;

      if (!categorizedDocuments[category]) {
        categorizedDocuments[category] = {
          title: CATEGORY_MAP[category],
          templates: documentTemplates.filter(
            temp => temp.category === category
          )
        };
      }
    }
    return categorizedDocuments;
  }, [documentTemplates]);

  const rejectDocumentationModal = useModal();

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title="Validação de documentação"
      footerButtons={<></>}
    >
      <StudentInformation
        user={formParent?.user}
        course={formParent?.course}
        enrollment={formParent}
        status={queryResult?.data?.data.status || ''}
      />
      <Space direction="vertical" size="large" className="w-full">
        {Object.entries(documentationByCategory).map(([_, data], index) => (
          <DocumentList
            key={data.title + index}
            title={data.title}
            templates={data.templates}
            templateCourses={templateCourses?.data ?? []}
            documents={documents}
          />
        ))}

        <SupplementaryDocuments
          title="Documentação não categorizada"
          documents={documents.filter(
            ({ template_id, status }) =>
              !template_id && !['draft', 'deleted'].includes(status)
          )}
        />
      </Space>
      <ModalSetKind templates={documentTemplates} />
      <ModalApproveDocument />
      <ModalRejectDocument />
      <ModalShowDocument />
      {rejectDocumentationModal.visible && (
        <ModalRejectDocumentation
          documentationId={queryResult?.data?.data.id}
          modal={rejectDocumentationModal}
        />
      )}
    </Edit>
  );
};
