import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography
} from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { DateRangeField } from 'components/forms/fields/DateRangeField';
import { FieldsetTitle } from 'components/professor_area/FieldsetTitle';
import { FormItemCol } from 'components/professor_area/FormItemCol';
import { getYearRangeData } from 'utils/create-year-selector-data';
import { toSaoPauloTimeZone } from 'services/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelect } from '@refinedev/antd';
import type { IClassroom } from 'interfaces/classrooms';
import type { IGraduateDiscipline } from 'interfaces/graduate_disciplines';

export interface IClassroomPost extends IClassroom {
  dateRange: Dayjs[];
  dateRangeContent: Dayjs[];
  dateRangeDiscussion: Dayjs[];
}

interface ClassroomFormProps {
  discipline?: {
    id: string;
    name: string;
  };
  formProps: FormProps<IClassroomPost>;
  edit?: boolean;
  // onFinish?: (data: IClassroomPost) => void;
}

const yearArrayData = getYearRangeData(1999, 10);

const convertToTimeZoneRange = (startDate?: string, endDate?: string) => {
  return [
    startDate ? toSaoPauloTimeZone(startDate) : null,
    endDate ? toSaoPauloTimeZone(endDate) : null
  ];
};

export const EditClassroomForm = ({
  formProps,
  discipline,
  edit
}: ClassroomFormProps) => {
  const [minimunAttendance, setMinimunAttendance] = useState(false);

  const isClassroomClosed = useMemo(() => {
    const endsAt = formProps.form?.getFieldValue('ends_at');
    return dayjs(endsAt).isBefore(dayjs());
  }, [formProps.form]);

  const onChangeFrequency = useCallback(
    (event: RadioChangeEvent) => {
      const required = event.target.value;
      setMinimunAttendance(required);

      if (required) {
        formProps.form?.setFieldValue('minimum_attendance', null);
      }
    },
    [formProps.form]
  );

  const onCopyDurationDate = useCallback(
    (formField: string) => {
      const durationDate = formProps.form?.getFieldValue('dateRange');

      formProps.form?.setFieldValue(formField, durationDate);
    },
    [formProps.form]
  );

  const initialValues = useMemo(() => {
    const initialValues = { ...formProps.initialValues };

    initialValues.dateRange = convertToTimeZoneRange(
      initialValues?.starts_at,
      initialValues?.ends_at
    );

    initialValues.dateRangeContent = convertToTimeZoneRange(
      initialValues?.content_access_starts_at,
      initialValues?.content_access_ends_at
    );

    initialValues.dateRangeDiscussion = convertToTimeZoneRange(
      initialValues?.discussion_starts_at,
      initialValues?.discussion_ends_at
    );

    return {
      ...initialValues,
      discipline_id: discipline?.id || null,
      discipline_name: discipline?.name || '',
      academic_year: initialValues.academic_year || dayjs().year(),
      frequency_required: !!initialValues.minimum_attendance || false
    } as Partial<IClassroomPost>;
  }, [formProps, discipline]);

  useEffect(() => {
    if (initialValues.minimum_attendance) {
      setMinimunAttendance(true);
    }
  }, [initialValues.minimum_attendance]);

  return (
    <Form {...formProps} layout="vertical" initialValues={initialValues}>
      <fieldset>
        <FieldsetTitle title="Informações Gerais" icon={ProfileOutlined} />

        <div className="pl-6">
          {!discipline?.id ? (
            <FormWithoutDiscipline />
          ) : (
            <>
              <Form.Item hidden noStyle name="discipline_id">
                <Input />
              </Form.Item>

              <FormItemCol name="discipline_name" label="Disciplina">
                <Input
                  disabled={!!discipline}
                  placeholder="Escreva o nome da disciplina"
                />
              </FormItemCol>
            </>
          )}

          <FormItemCol
            name="code"
            label="Código da turma"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input placeholder="Ex.: F12908" disabled={edit} />
          </FormItemCol>

          {edit && (
            <FormItemCol name="number" label="ID da turma">
              <Input disabled />
            </FormItemCol>
          )}

          <FormItemCol
            name="description"
            label="Descrição (opcional)"
            itemsCenter={false}
          >
            <Input.TextArea
              rows={3}
              placeholder="Escreva uma breve descrição da turma"
            />
          </FormItemCol>

          <FormItemCol
            name="seats_total"
            label="Número de vagas"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <InputNumber className="max-w-[80px]" />
          </FormItemCol>

          <FormItemCol
            name="passing_score"
            label="Nota mínima (0-100)"
            rules={[{ required: true, message: 'Campo obrigatorio' }]}
            className="mb-0"
          >
            <InputNumber
              className="max-w-[80px]"
              min={0}
              max={100}
              placeholder="0-100"
            />
          </FormItemCol>
        </div>
      </fieldset>

      <Divider />

      <fieldset>
        <FieldsetTitle title="Período acadêmico" icon={ClockCircleOutlined} />

        <Flex gap={16} className="pl-6">
          <Form.Item
            label="Ano"
            layout="vertical"
            name="academic_year"
            className="mb-0 lg:w-[160px]"
          >
            <Select style={{ minWidth: 100 }}>
              {yearArrayData.map(year => (
                <Select.Option key={year.value} value={year.value}>
                  {year.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Período"
            name="academic_period"
            layout="vertical"
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório'
              }
            ]}
          >
            <InputNumber className="lg:w-[160px]" />
          </Form.Item>
        </Flex>
      </fieldset>

      <Divider />

      <fieldset>
        <FieldsetTitle
          title="Inscrição automática"
          icon={CheckCircleOutlined}
          hint="Habilitar a inscrição dos alunos na turma automaticamente após a
        compra"
        />

        <div className="pl-6">
          <Form.Item noStyle name="auto_enroll" initialValue={true}>
            <Radio.Group
              options={[
                { label: 'Ativada', value: true },
                {
                  label: 'Desativada',
                  value: false
                }
              ]}
              optionType="button"
              buttonStyle="outline"
              size="middle"
            />
          </Form.Item>
        </div>
      </fieldset>

      <Divider />

      <fieldset>
        <FieldsetTitle title=" Duração de acessos" icon={CalendarOutlined} />

        <Flex vertical gap={12} className="pl-6">
          <Card className="bg-neutral-50" size="small">
            <Flex vertical>
              <label className="font-medium">Vigência</label>
              <Typography.Text className="text-neutral-500 mb-3">
                Período em que o curso estará ativo
              </Typography.Text>
              <Space>
                <DateRangeField
                  name="dateRange"
                  className="mb-0"
                  startsAtField="starts_at"
                  endsAtField="ends_at"
                  dateRangeProps={{
                    showTime: {
                      defaultOpenValue: [
                        dayjs('00:00', 'HH:mm'),
                        dayjs('23:59', 'HH:mm')
                      ]
                    }
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                />
                <Typography.Text className="text-neutral-500 text-sm">
                  Horário de Brasilia (GMT-3)
                </Typography.Text>
              </Space>
            </Flex>
          </Card>

          <Card className="bg-neutral-50" size="small">
            <Flex gap={16} align="baseline" justify="space-between">
              <Flex vertical>
                <label className="font-medium">Período de tira-dúvidas</label>
                <Typography.Text className="text-neutral-500 mb-3">
                  Até quando os alunos podem enviar dúvidas
                </Typography.Text>
                <Space>
                  <DateRangeField
                    name="dateRangeDiscussion"
                    className="mb-0"
                    startsAtField="discussion_starts_at"
                    endsAtField="discussion_ends_at"
                    dateRangeProps={{
                      showTime: {
                        defaultOpenValue: [
                          dayjs('00:00', 'HH:mm'),
                          dayjs('23:59', 'HH:mm')
                        ]
                      }
                    }}
                  />
                  <Typography.Text className="text-neutral-500 text-sm">
                    Horário de Brasilia (GMT-3)
                  </Typography.Text>
                </Space>
              </Flex>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => onCopyDurationDate('dateRangeDiscussion')}
              >
                Copiar data da vigência
              </Button>
            </Flex>
          </Card>

          <Card className="bg-neutral-50" size="small">
            <Flex gap={16} align="baseline" justify="space-between">
              <Flex vertical flex={1}>
                <label className="font-medium">Acesso ao conteúdo</label>
                <Typography.Text className="text-neutral-500 mb-3">
                  Até quando os alunos podem acessar o conteúdo do curso
                </Typography.Text>
                <Space>
                  <DateRangeField
                    name="dateRangeContent"
                    className="mb-0"
                    startsAtField="content_access_starts_at"
                    endsAtField="content_access_ends_at"
                    dateRangeProps={{
                      showTime: {
                        defaultOpenValue: [
                          dayjs('00:00', 'HH:mm'),
                          dayjs('23:59', 'HH:mm')
                        ]
                      }
                    }}
                  />
                  <Typography.Text className="text-neutral-500 text-sm">
                    Horário de Brasilia (GMT-3)
                  </Typography.Text>
                </Space>
              </Flex>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => onCopyDurationDate('dateRangeContent')}
              >
                Copiar data da vigência
              </Button>
            </Flex>
          </Card>
        </Flex>
      </fieldset>

      <Divider />

      <fieldset>
        <FieldsetTitle
          title="Frequência nas aulas ao vivo"
          icon={DesktopOutlined}
          hint="Defina se a presença dos alunos será exigida e contabilizada nas
        aulas"
        />

        <Flex vertical gap={20} className="pl-6">
          <Form.Item noStyle name="frequency_required">
            <Radio.Group
              options={[
                { label: 'Obrigatória', value: true },
                {
                  label: 'Não obrigatória',
                  value: false
                }
              ]}
              optionType="button"
              buttonStyle="outline"
              size="middle"
              disabled={isClassroomClosed}
              onChange={onChangeFrequency}
            />
          </Form.Item>

          {minimunAttendance && (
            <FormItemCol
              name="minimum_attendance"
              label="Frequência mínima"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <InputNumber
                type="number"
                addonAfter="%"
                className="max-w-[100px]"
                min={0}
                max={100}
                disabled={isClassroomClosed}
                placeholder="0-100"
              />
            </FormItemCol>
          )}
        </Flex>
      </fieldset>
    </Form>
  );
};

const FormWithoutDiscipline = () => {
  const {
    selectProps: disciplineSelectProps,
    queryResult: disciplineQueryResult
  } = useSelect<IGraduateDiscipline>({
    resource: 'graduate_disciplines',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const disciplines = useMemo(() => {
    return disciplineQueryResult?.data?.data.map(discipline => {
      const { name, id, external_reference } = discipline;

      const codExternal = external_reference ? `${external_reference} - ` : '';

      const label = `${codExternal} ${name}`;

      return {
        label,
        value: id
      };
    });
  }, [disciplineQueryResult]);

  return (
    <FormItemCol
      label="Disciplina"
      name="discipline_id"
      rules={[
        {
          required: true
        }
      ]}
    >
      <Select
        {...disciplineSelectProps}
        options={disciplines}
        style={{ minWidth: 200 }}
      />
    </FormItemCol>
  );
};
