/* eslint-disable sonarjs/cognitive-complexity */
import { AudioContent } from './Components/AudioContent';
import { ContentDownload } from './Components/ContentDownload';
import { IDataContent } from 'interfaces/courses';
import { IframeContent } from './Components/IframeContent';
import { LtiContent } from './Components/LtiContent';
import { Modal, ModalProps, Space, Spin } from 'antd';
import { getAPI } from 'requests/api';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface ContentPreviewModalProps {
  modalProps: ModalProps;
  previewUrl?: string;
  node?: IDataContent;
}

export const ContentPreviewModal = ({
  modalProps,
  previewUrl,
  node
}: ContentPreviewModalProps) => {
  const { id } = useParams<{ id: string }>();
  const [content, setContent] = useState<IDataContent | null>(null);
  const [loading, setLoading] = useState(false);

  const getContent = useCallback(async () => {
    if (node) {
      setLoading(true);
      const content = await getAPI()
        .get(`admin/professor_area/classrooms/${id}/contents/${node.key}`)
        .finally(() => setLoading(false));

      setContent(content.data);
    }
  }, [node, id]);

  useEffect(() => {
    if (node) {
      getContent();
    }
  }, [node, getContent]);

  const renderContent = useCallback(() => {
    if (node && content) {
      if (node?.core_kind === 'lti') {
        return <LtiContent />;
      }

      if (node?.core_kind === 'audio') {
        return <AudioContent content={content} />;
      }

      if (node?.core_kind === 'video') {
        return (
          <IframeContent
            content={content}
            title={node.title?.toString() ?? 'Video'}
          />
        );
      }

      if (node?.core_kind === 'content') {
        return <ContentDownload content={content} />;
      }

      if (node?.core_kind === 'pdf') {
        return (
          <IframeContent
            content={content}
            title={node.title?.toString() ?? 'Pdf'}
          />
        );
      }

      if (node?.core_kind === 'hyper_text') {
        return (
          <IframeContent
            content={content}
            title={node.title?.toString() ?? 'Hyper texto'}
          />
        );
      }
    }

    return <iframe src={previewUrl} className="w-full !h-[60vh]" />;
  }, [node, content, previewUrl]);

  return (
    <Modal
      {...modalProps}
      className="!w-[60vw]"
      title={node ? node.title?.toString() : 'Material complementar'}
      footer={null}
    >
      <Space
        direction="vertical"
        className="!w-full flex items-center"
        styles={{ item: { width: '100%' } }}
      >
        {loading && <Spin tip="Loading" size="large" />}
        {renderContent()}
      </Space>
    </Modal>
  );
};
