import Icon, { EllipsisOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  MenuProps,
  Space,
  Typography
} from 'antd';
import { ReactNode, useState } from 'react';

interface CardItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  avatar?: ReactNode;
  title: ReactNode;
  meta?: ReactNode;
  extraContent?: ReactNode;
  menuProps: MenuProps;
}

export const CardItem = ({
  icon,
  avatar,
  title,
  meta,
  extraContent,
  menuProps
}: CardItemProps) => {
  const [hovered, setHover] = useState(false);

  return (
    <Flex
      justify="space-between"
      className={clsx(
        'group w-full items-center py-4 px-2 justify-between border-t border-t-neutral-100 hover:bg-neutral-100 transition-all',
        hovered && 'bg-neutral-100'
      )}
    >
      <Space size="middle" className="flex-1">
        {icon && (
          <Space
            direction="vertical"
            className="flex rounded-md w-[56px] h-[56px] p-2 bg-primary-500/10 justify-center items-center !gap-0"
          >
            <Icon component={icon} className="text-xl text-primary-500" />
          </Space>
        )}
        {avatar && (
          <Avatar size={40} className="bg-primary-500/10 text-primary-500">
            {avatar}
          </Avatar>
        )}
        <Flex vertical gap={4} flex={1}>
          <Typography.Text strong className="text-sm font-semibold !m-0">
            {title}
          </Typography.Text>
          <Flex
            gap={16}
            align="center"
            className="[&_.ant-typography]:text-neutral-600 [&_.anticon]:text-neutral-500"
          >
            {meta}
          </Flex>
        </Flex>
      </Space>

      <Space>
        {extraContent}

        <Dropdown
          menu={menuProps}
          trigger={['click']}
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            hovered && 'opacity-100'
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    </Flex>
  );
};
