import FilterForm from '../../components/forms/FilterForm';
import { Col, Form, Input, Row, Table } from 'antd';
import { CreateGraduateCourseModal } from 'components/modal/graduate_courses/CreateGraduateCourseModal';
import { EditButton, List, TextField, useTable } from '@refinedev/antd';
import { EyeOutlined } from '@ant-design/icons';
import { HttpError, useCan } from '@refinedev/core';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { useState } from 'react';

interface IFilter {
  name: string;
  externalRef: string;
  code: string;
}

export const GraduateCourseList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const canCreate = useCan({
    action: 'create',
    resource: 'graduate_courses'
  });

  const { tableProps, searchFormProps } = useTable<
    IGraduateCourse,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const { name, externalRef, code } = params;

      return [
        {
          field: 'filter[name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_code]',
          operator: 'eq',
          value: code
        },
        {
          field: 'filter[search_external_reference]',
          operator: 'eq',
          value: externalRef
        }
      ];
    }
  });

  return (
    <List
      title="Cursos Regulados"
      createButtonProps={{
        hidden: !canCreate?.data?.can,
        onClick: () => setIsModalOpen(true)
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome" name="name">
              <Input placeholder="Nome do curso..." />
            </Form.Item>
            <Form.Item label="Código" name="code">
              <Input placeholder="Digite..." />
            </Form.Item>
            <Form.Item label="Código externo" name="externalRef">
              <Input placeholder="Digite..." />
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome do curso"
              sorter={(a, b) => a.name.localeCompare(b.name)}
              fixed="left"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="kind"
              key="kind"
              title="Tipo"
              render={value => (
                <TextField
                  value={value === 'graduate' ? 'Pós Graduação' : 'Graduação'}
                />
              )}
            />
            <Table.Column
              dataIndex="duration_in_hours"
              key="duration_in_hours"
              title="Duração (h)"
              sorter={(a, b) => a.duration_in_hours - b.duration_in_hours}
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="code"
              key="code"
              title="Código do curso"
              sorter={(a, b) => a.code?.localeCompare(b.code)}
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="emec"
              key="emec"
              title="Código e-MEC"
              sorter={(a, b) => a.emec?.localeCompare(b.emec)}
              render={value => <TextField value={value ?? '-'} />}
            />
            <Table.Column
              dataIndex="external_reference"
              key="external_reference"
              title="Código externo"
              render={value => <TextField value={value ?? '-'} />}
            />
            <Table.Column<IGraduateCourse>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <EditButton
                    hideText
                    size="small"
                    icon={<EyeOutlined />}
                    recordItemId={record.id}
                  />
                );
              }}
            />
          </Table>
        </Col>
      </Row>

      <CreateGraduateCourseModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </List>
  );
};
