import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import type { IGraduateDiscipline } from 'interfaces/graduate_disciplines';

interface GraduateDisciplineStoreState {
  discipline: IGraduateDiscipline | null;
}

interface GraduateDisciplineStoreActions {
  setDiscipline: (discipline: IGraduateDiscipline) => void;
}

export const useGraduateDisciplineStore = create<
  GraduateDisciplineStoreState & GraduateDisciplineStoreActions
>()(
  persist(
    set => ({
      discipline: null,
      setDiscipline: discipline => set({ discipline })
    }),
    {
      name: 'graduate-disciplines-storage',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
