import Brand from 'assets/Brand';
import PartnerLogo from '../../../assets/PartnerLogo';
import React from 'react';
import { TitleProps, useLink } from '@refinedev/core';
import { styled } from '@qcx/ui';

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const Link = useLink();

  return (
    <StyledContainer>
      <Link to="/home" className="flex justify-center">
        {collapsed ? (
          <StyledLogoContainer className="flex flex-col items-center">
            <Brand style={{ color: 'white', width: '80px' }} />
          </StyledLogoContainer>
        ) : (
          <StyledLogoContainer className="flex flex-col items-center">
            <PartnerLogo style={{ maxWidth: 140, minWidth: 130 }} />
          </StyledLogoContainer>
        )}
      </Link>
    </StyledContainer>
  );
};

const StyledContainer = styled('div', {
  p: '$4'
});

const StyledLogoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});
