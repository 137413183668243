import { EvaluationShow } from 'pages/teacher_space/classrooms/components/TabEvaluations/Evaluation/EvaluationShow';
import { IResource } from 'interfaces/resources';
import { generateResources } from 'utils/GenerateResouces';

const resource: IResource = {
  name: 'professor_area/classroom_assessments',
  label: 'Avaliações',
  show: EvaluationShow,
  actions: ['show', 'create', 'edit', 'delete'],
  parentName: 'professor_area',
  route: 'professor_area/classrooms/:classroomId/assessments'
};

export default generateResources(resource);
