import { TabAttemptsFilter, TabAttemptsFilters } from './TabAttemptsFilter';
import { TabAttemptsTable } from './TabAttemptsTable';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';
import type { CrudFilters, HttpError } from '@refinedev/core';
import type { IProfessorAreaEvaluationAttemptList } from 'interfaces/professor_area/evaluations';

export const TabAttempts = () => {
  const params = useParams();
  const classroomId = params.classroomId;
  const evaluationId = params.id;

  const { tableProps, searchFormProps } = useTable<
    IProfessorAreaEvaluationAttemptList,
    HttpError,
    TabAttemptsFilters
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts`,
    onSearch: params => {
      const filters: CrudFilters = [];
      const { by_status, by_title } = params;

      filters.push(
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: by_title
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: by_status
        }
      );

      return filters;
    }
  });

  return (
    <>
      <TabAttemptsFilter searchFormProps={searchFormProps} />
      <TabAttemptsTable tableProps={tableProps} />
    </>
  );
};
