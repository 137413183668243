import { Col, Row, Space } from 'antd';
import { Counters } from './components/Counters';
import { ForumsList } from './components/ForumsList';
import { GeneralInfo } from './components/GeneralInfo';
import { InteractiveClassList } from './components/InteractiveClassList';
import type { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';

interface TabGeneralProps {
  data: IProfessorAreaClassroom;
}

export const TabGeneral = ({ data }: TabGeneralProps) => {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24} xl={8}>
        <GeneralInfo data={data} />
      </Col>

      <Col xs={24} sm={24} xl={16}>
        <Counters
          assessmentsCount={data.pending_evaluation_assessment_attempts_count}
          assignmentsCount={data.pending_evaluation_assignment_attempts_count}
          threadsCount={data.pending_faq_professors_count}
        />

        <Space direction="vertical" size="large" className="w-full flex-1">
          <InteractiveClassList />
          <ForumsList />
        </Space>
      </Col>
    </Row>
  );
};
