import React from 'react';
import { Avatar, Card, Skeleton, Space, Typography } from 'antd';
import { IAgent } from 'interfaces/agents';
import { UserOutlined } from '@ant-design/icons';
import { useGetIdentity } from '@refinedev/core';

const { Title, Text, Paragraph } = Typography;

export const Homepage: React.FC = () => {
  const { data: user, isLoading } = useGetIdentity<IAgent>();

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        rootClassName="!w-full"
        className="!w-full !h-[170px]"
      />
    );
  }
  return (
    <>
      <div className="ignorePadding">
        <div className="relative bg-gradient-to-r from-[#53389E] to-[#7F56D9] h-[170px] p-6 !z-0 overflow-hidden">
          <div className="absolute top-[-38px] right-0 w-[259px] h-[255px] bg-gradient-to-r from-[#53389E] to-[#7F56D9] rounded-full !z-[1]" />
          <Space className="relative p-6 w-full justify-between !z-[2]">
            <Space className="gap-4">
              <Avatar
                className="bg-white"
                size={64}
                icon={<UserOutlined className="text-[#722ED1]" />}
              />
              <Space direction="vertical">
                <Title
                  level={1}
                  className="!text-white !m-0"
                >{`Olá, ${user?.name}!`}</Title>
                <Text className="!text-white text-base">
                  Tudo o que você precisa para uma gestão acadêmica eficaz está
                  aqui!{' '}
                </Text>
              </Space>
            </Space>
          </Space>
        </div>
      </div>
      <div className="p-6">
        <Card>
          <Title level={4} className="!mb-4">
            Boas-vindas ao Atlas!
          </Title>
          <Paragraph type="secondary" className="text-base">
            Simplifique a administração acadêmica com o sistema{' '}
            <Text type="secondary" className="font-bold text-base">
              Atlas
            </Text>{' '}
            para{' '}
            <Text type="secondary" className="font-bold text-base">
              pós-graduação
            </Text>
            ,{' '}
            <Text type="secondary" className="font-bold text-base">
              graduação
            </Text>{' '}
            e{' '}
            <Text type="secondary" className="font-bold text-base">
              cursos livres
            </Text>
            . Desfrute de uma plataforma completa para administrar cursos, aulas
            síncronas e gravadas, avaliações, atividades interativas e
            ferramentas de comunicação entre alunos, professores e a
            coordenação, com uma experiência de aprendizado integrada e
            eficiente.
          </Paragraph>
        </Card>
      </div>
    </>
  );
};
