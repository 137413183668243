import * as Icons from '@ant-design/icons';
import ConfirmationModal from 'components/modal/manua-orders/ConfirmationModal';
import ProductTableList from 'components/manual-orders/ProductTableList';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { Create, useSelect } from '@refinedev/antd';
import { IManualOfferRequestData } from 'interfaces/manual_offer';
import { IOffer, TOfferKind } from 'interfaces/offers';
import { formatMoney } from '../../../services/money';
import { getRanges } from 'requests/range-ceps';
import { getUserInfo } from 'requests/manual-orders';
import {
  parseManualOrderRequest,
  parseUserInformation
} from 'services/manualOffers/parser';
import { pluralize } from '../../../utils/pluralize';
import { useCreate, useModal } from '@refinedev/core';
import { useState } from 'react';
import { IOrder } from 'interfaces/orders';

const { Search } = Input;
const { Title } = Typography;
export interface TOffer {
  key: string;
  product_name: string;
  kind: TOfferKind | null;
  item_name: string;
  product_price: number;
  time_in_days: number;
  service_duration_in_months: number | null;
}

export const ManualOrderCreate = () => {
  const [newOrderData, setNewOrderData] = useState<
    Partial<IManualOfferRequestData>
  >({});
  const [selectedRange, setSelectedRange] = useState<string | undefined>();
  const [offerItems, setOfferItems] = useState<TOffer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'warning' | 'success'>('warning');
  const [orderId, setOrderId] = useState<string | null>(null);
  const [includeOnline, setIncludeOnline] = useState<boolean>(false);
  const [kind, setKind] = useState<TOfferKind>('management');
  const { mutate } = useCreate<IOrder>();
  const createModal = useModal();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const state = Form.useWatch(['address_attributes', 'city', 'state_id'], form);
  const selectedItem = Form.useWatch('offerItem', form);
  const { selectProps: OfferSelectProps, queryResult } = useSelect<IOffer>({
    resource: 'offers',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 20,

    filters: [
      {
        field: 'filter[by_kind]',
        operator: 'eq',
        value: !includeOnline ? 'management' : 'online'
      },
      {
        field: 'filter[status]',
        operator: 'eq',
        value: 'active'
      }
    ],

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });
  const { selectProps: stateSelectProps } = useSelect({
    resource: 'states',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 30,

    pagination: {
      mode: 'server'
    }
  });
  const { selectProps: citiesSelectProps, queryResult: citiesQueryResult } =
    useSelect({
      resource: 'cities',
      optionLabel: 'name',
      optionValue: 'id',
      fetchSize: 999,

      filters: [
        {
          field: 'filter[by_state_id]',
          operator: 'eq',
          value: stateSelectProps?.options?.find(el => el.value === state)
            ?.value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  const handleClearData = () => {
    setNewOrderData({});
    setOfferItems([]);
    form.resetFields();
    searchForm.resetFields();
  };

  const handleSearch = ({ email }: { email: string }) => {
    setIsLoading(true);
    getUserInfo(email)
      .then(response => {
        if (response?.data) {
          const userParsedData = parseUserInformation(response.data);
          setNewOrderData(userParsedData);
          form.setFieldsValue(userParsedData);
        }
        return setNewOrderData({ email });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddItem = () => {
    const offer = queryResult.data?.data.find(
      offer => offer.id === form.getFieldValue('offerItem')
    );

    if (offer && offerItems.length === 0) {
      return setOfferItems([
        ...offerItems,
        {
          key: offer.id,
          product_name: offer.name,
          item_name: offer.items[0].name,
          product_price: offer.price.cents,
          time_in_days: offer.items[0].time_in_days,
          service_duration_in_months: offer.service_duration_in_months,
          kind: offer.kind
        }
      ]);
    }
    return null;
  };

  const customLabel = (offer: IOffer) => {
    return (
      <div className="flex flex-col justify-center">
        <Typography.Text strong>{offer.name}</Typography.Text>
        <div className="flex gap-2 grow-0 my-2">
          <Typography className="text-xs">
            {`💵 ${formatMoney(offer.price)}`}{' '}
          </Typography>
          <Typography className="text-xs">
            {`⏱️ ${offer.items[0].time_in_days} dias de acesso`}{' '}
          </Typography>

          {offer.service_duration_in_months && (
            <Typography className="text-xs">
              {`🗓️ ${pluralize(
                offer.service_duration_in_months,
                'mês',
                'meses'
              )} de duração`}{' '}
            </Typography>
          )}
        </div>
      </div>
    );
  };

  const offersOptions = queryResult.data?.data.map(offer => ({
    label: customLabel(offer),
    value: offer.id
  }));

  const changeRangeCep = async (value: string) => {
    const cep = value.replace(/\D/g, '').slice(0, 8);
    const notFoundLabel = 'Não encontrado!';

    form.setFieldsValue({ billing_postal_code: cep });

    if (cep.length < 8) {
      return;
    }

    const ranges = await getRanges({ zipCode: cep });
    const rangeNames = new Set(ranges.map(range => range.name));

    if (rangeNames.size === 0) {
      setSelectedRange(notFoundLabel);
    } else {
      setSelectedRange([...rangeNames].join(', '));
    }
  };

  const handleRemoveItem = (itemIndex: number) => {
    return setOfferItems([
      ...offerItems.filter((_, index) => index !== itemIndex)
    ]);
  };

  const sumTotal = (): number => {
    return offerItems.reduce((acc, current) => {
      return acc + current.product_price;
    }, 0);
  };

  const handleCreateDialog = () => {
    setModalType('warning');
    return createModal.show();
  };

  const handleCreateOrder = () => {
    setIsLoading(true);
    createModal.close();
    const sanitizedRequest = parseManualOrderRequest(
      { ...form.getFieldsValue(), email: newOrderData.email },
      offerItems
    );
    mutate(
      {
        resource: 'orders',
        values: sanitizedRequest
      },
      {
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: (response) => {
          handleClearData();
          setIsLoading(false);
          setModalType('success');
          setOrderId(response.data.id);
          return createModal.show();
        }
      }
    );
  };

  const handleModalClose = () => {
    createModal.close();
    if (modalType === 'success') {
      window.open(`/sales/orders/show/${orderId}`, '_blank');
    }
  };

  const handleKindSelect = (e: RadioChangeEvent) => {
    setKind(e.target.value);
    setIncludeOnline(e.target.value === 'online');
    setOfferItems([]);
  };

  return (
    <Create
      title="Criar pedido manual (gerencial)"
      footerButtons={() => (
        <>
          <Button type="text" onClick={handleClearData}>
            Cancelar
          </Button>
          <Button
            disabled={!(newOrderData.email && offerItems.length > 0)}
            type="primary"
            onClick={form.submit}
          >
            Criar Pedido
          </Button>
        </>
      )}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert
          message="É necessário que os dados reais do aluno sejam cadastrados para efetuar a cobrança e emissão de notas fiscais"
          type="warning"
          showIcon
          style={{ marginBottom: '12px' }}
        />
        <Spin tip="Carregando..." spinning={isLoading}>
          <Form form={searchForm} layout="vertical" onFinish={handleSearch}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                { required: true, message: 'Você deve digitar um e-mail.' },
                { type: 'email', message: 'O e-mail digitado não é válido.' }
              ]}
            >
              <Search
                placeholder="Digite o e-mail..."
                style={{ width: '30%' }}
                color="default"
                enterButton={
                  <Button type="primary" htmlType="submit">
                    Buscar
                  </Button>
                }
                loading={isLoading}
              />
            </Form.Item>
          </Form>

          <Form
            form={form}
            disabled={!newOrderData.email}
            onFinish={handleCreateDialog}
            layout="vertical"
            scrollToFirstError
          >
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item
                  label="Nome Completo"
                  name={['personal_info_attributes', 'full_name']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="Nome do aluno" />
                </Form.Item>
              </Col>

              <Col md={8}>
                <Form.Item
                  label="CPF"
                  name={['personal_info_attributes', 'document_info']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="CPF do aluno (somente números)" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="CEP"
                  normalize={value => value.replace(/\D/g, '').slice(0, 8)}
                  name={['address_attributes', 'postal_code']}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    },
                    { len: 8, message: 'CEP deve ter 8 dígitos' }
                  ]}
                >
                  <Input placeholder="CEP do endereço (somente números)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item
                  label="Endereço"
                  name={['address_attributes', 'street']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="Endereço do aluno" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="Complemento"
                  name={['address_attributes', 'address_complement']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="Complemento do endereço" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="Número"
                  name={['address_attributes', 'address_number']}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input type="number" placeholder="Número da casa" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item
                  label="Bairro"
                  name={['address_attributes', 'neighborhood']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="Bairro do endereço" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="Estado"
                  name={['address_attributes', 'city', 'state_id']}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Select {...stateSelectProps} placeholder="Estado do aluno" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="Cidade"
                  name={['address_attributes', 'city', 'id']}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Select
                    {...citiesSelectProps}
                    options={state ? citiesSelectProps.options : []}
                    loading={citiesQueryResult.fetchStatus === 'fetching'}
                    placeholder="Cidade do aluno"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item
                  label="Telefone"
                  name={['personal_info_attributes', 'phone_number']}
                  normalize={(value: string) => value.trimStart()}
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório'
                    }
                  ]}
                >
                  <Input placeholder="Telefone do aluno" />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <Row gutter={[16, 16]}>
              <Col md={8}>
                <Form.Item
                  label="Cupom"
                  name="coupon_code"
                  tooltip="Verifique se o cupom é válido antes de aplicar"
                >
                  <Input placeholder="Informe o código do cupom" />
                </Form.Item>
              </Col>
            </Row>
            <Tooltip title="Verifique se o cupom é válido antes de aplicar">
              <Alert
                message="O valor será descontado durante a criação do pedido. Caso inserido, o cupom precisa ser válido para concluir a criação"
                type="info"
                showIcon
              />
            </Tooltip>
            <Divider />

            <Title level={4}>Selecione o tipo do pedido</Title>

            <Row gutter={[16, 16]} className="mb-6 mt-4">
              <Col md={24}>
                <Radio.Group onChange={handleKindSelect} value={kind}>
                  <Radio value="management">
                    Ofertas da Franquia (Gerenciais){' '}
                  </Radio>
                  <Radio value="online">Ofertas da Vitrine (Online)</Radio>
                </Radio.Group>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={24}>
                {kind === 'management' ? (
                  <>
                    <Form.Item
                      label="Código da Unidade (CEP)"
                      name="billing_postal_code"
                      rules={[
                        {
                          required: true,
                          message: 'Campo obrigatório'
                        },
                        { len: 8, message: 'CEP deve ter 8 dígitos' }
                      ]}
                    >
                      <Input
                        onChange={value => changeRangeCep(value.target.value)}
                      />
                    </Form.Item>
                    {selectedRange && (
                      <Typography.Paragraph
                        style={{
                          fontSize: 18,
                          marginTop: 10,
                          verticalAlign: 'middle'
                        }}
                      >
                        <Icons.ShopOutlined
                          style={{ verticalAlign: 'middle' }}
                        />{' '}
                        {selectedRange}
                      </Typography.Paragraph>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

            <Divider />
            <Title level={4}>Produtos do pedido</Title>
            <Row gutter={[16, 16]}>
              <Col md={22}>
                <Form.Item
                  label={
                    kind === 'online'
                      ? 'Ofertas da Vitrine (Online)'
                      : 'Ofertas da Franquia (Gerenciais)'
                  }
                  name="offerItem"
                >
                  <Select
                    allowClear
                    {...OfferSelectProps}
                    options={offersOptions}
                    disabled={offerItems.length > 0}
                    placeholder="Selecione uma oferta"
                  />
                </Form.Item>
              </Col>
              <Col md={2} className="my-auto">
                <Button
                  disabled={!selectedItem}
                  className="mt-1"
                  type="primary"
                  ghost
                  onClick={handleAddItem}
                >
                  Inserir
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={24}>
                <ProductTableList
                  data={offerItems}
                  removeFn={handleRemoveItem}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="mt-4" span={6} offset={18}>
                <Title level={5}>
                  Total sem cupom:{' '}
                  {formatMoney({ cents: sumTotal(), currency_iso: 'BRL' })}
                </Title>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Space>
      <ConfirmationModal
        modal={createModal}
        onCreate={handleCreateOrder}
        onClose={handleModalClose}
        type={modalType}
      />
    </Create>
  );
};
