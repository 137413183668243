import {
  DiscussionThreadList,
  DiscussionThreadShow
} from 'pages/academic/discussion_threads';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'discussion_threads',
  label: 'Tira-dúvidas',
  list: DiscussionThreadList,
  show: DiscussionThreadShow,
  actions: ['list'],
  parentName: 'communication'
};

export default generateResources(resource);
