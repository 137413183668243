import { ITenant } from 'interfaces/tenants';

type TEnv = 'development' | 'staging' | 'production';

interface ITenantList {
  development: ITenant[];
  staging: ITenant[];
  production: ITenant[];
}

const env: TEnv = (process.env.REACT_APP_TENANT_ENV as TEnv) || 'development';

/**
 * Configure essa variavel no seu (.env.local) para usar o atlas local
 * REACT_APP_ATLAS_API_URL=http://localhost:4002
 */
const devApiUrl = process.env.REACT_APP_ATLAS_API_URL || '';
const stgApiUrl = 'https://atlas.opx-hmg.grupoq.io';

const stgLmsUrl = 'https://stg-app-demo-main.opx-hmg.grupoq.io';

export const tenantList: ITenantList = {
  development: [
    {
      project: 'development',
      name: 'development',
      domains: ['localhost'],
      apiUrl: devApiUrl || stgApiUrl,
      lmsUrl: 'http://app.themis.local:3006',
      disabledFeatures: ['contents_modal_v1']
    }
  ],
  staging: [
    {
      project: 'preview',
      name: 'qconcursos',
      domains: [/.*\.atlas-admin\.pages\.dev/],
      apiUrl: stgApiUrl,
      lmsUrl: stgLmsUrl,
      googleId:
        '410228309266-ss6jgc4538h9prvh2f7u1v53unfs0vdf.apps.googleusercontent.com',
      disabledFeatures: ['contents_modal_v1']
    },
    {
      project: 'themis',
      name: 'damasio',
      domains: ['admin-damasio.opx-hmg.grupoq.io'],
      apiUrl: 'https://atlas.opx-hmg.grupoq.io',
      logoUrl: 'https://assets.olimpo.grupoq.io/assets/themis/dark-logo.svg',
      disabledFeatures: ['faq_coordinators']
    }
  ],
  production: [
    {
      project: 'staging',
      name: 'qconcursos',
      domains: ['admin.atlas.qcarreiras-hmg.com'],
      apiUrl: stgApiUrl,
      lmsUrl: stgLmsUrl,
      googleId:
        '410228309266-ss6jgc4538h9prvh2f7u1v53unfs0vdf.apps.googleusercontent.com',
      disabledFeatures: ['contents_modal_v1']
    },
    {
      project: 'morpheus',
      name: 'estacio',
      domains: ['admin.online.estacio.br', 'admin.cursoslivres.estacio.br'],
      apiUrl: 'https://api.online.estacio.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      lmsUrl: 'https://online.estacio.br',
      logoUrl: 'https://assets.olimpo.grupoq.io/morpheus/logo-dark.svg',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'graduate_courses',
        'action_logs',
        'companies',

        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'demeter',
      name: 'ibmec',
      domains: ['admin.cursos.ibmec.br'],
      apiUrl: 'https://api.cursos.ibmec.br',
      lmsUrl: 'https://cursos.ibmec.br',
      googleId:
        '511931388600-mhua7vq2son61dgn6ide5sjm2v1aee4d.apps.googleusercontent.com',
      logoUrl: 'https://assets.olimpo.grupoq.io/demeter/logotype-h-dark.svg',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'graduate_courses',
        'action_logs',
        'companies',

        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'hephaestus',
      name: 'percursa',
      domains: ['admin.percursa.com'],
      apiUrl: 'https://apiv2.percursa.com',
      lmsUrl: 'https://percursa.com',
      logoUrl: 'https://assets.olimpo.grupoq.io/hephaestus/logo-light.svg',
      googleId:
        '266674794398-qqhoctvk6jnullddikrova9pehpks0ke.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'graduate_courses',
        'action_logs',
        'companies',

        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'grupoq',
      name: 'qconcursos',
      domains: ['admin.pos.qconcursos.com'],
      apiUrl: 'https://api.pos.qconcursos.com',
      lmsUrl: 'https://app.pos.qconcursos.com',
      logoUrl:
        'https://assets.olimpo.grupoq.io/assets/persephone/dark-logo.svg',
      googleId:
        '529641470454-3rpkk7i7667vaj353sotocuhf5sbkbdc.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'action_logs',
        'companies',

        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'courses_v2',
        'undergraduate_courses',
        'franchises'
      ]
    },
    {
      project: 'antiope',
      name: 'estrelas-da-revolucao',
      domains: ['admin.estrelasdarevolucao.estacio.br'],
      apiUrl: 'https://api.estrelasdarevolucao.estacio.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',

        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'leone',
      name: 'leone-maltz',
      domains: ['admin-leonemaltz.grupoq.io', 'admin.leonemaltz.com.br'],
      apiUrl: 'https://api.leonemaltz.com.br',
      googleId:
        '484927615520-k100spkqii647makmdboonq5n091bfsc.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',

        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'themis',
      name: 'damasio',
      domains: ['admin-damasio.atlas.grupoq.io', 'admin.damasio.com.br'],
      lmsUrl: 'https://ensino.damasio.com.br',
      apiUrl: 'https://api-damasio.atlas.grupoq.io',
      logoUrl: 'https://assets.olimpo.grupoq.io/assets/themis/dark-logo.svg',
      googleId:
        '612147605254-kldiotvc9gp4kuhept424nttfnaelu7c.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'action_logs',
        'companies',
        'interactive_classes'
      ]
    },
    {
      project: 'polos',
      name: 'polos-yduqs',
      domains: ['admin.capacitapolos.yduqs.com.br'],
      apiUrl: 'https://api.capacitapolos.yduqs.com.br',
      googleId:
        '489413631036-evl0i2eibgtvc5048ufbpfqii0te1npu.apps.googleusercontent.com',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',

        'prometheus',
        'serviceDates',
        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ]
    },
    {
      project: 'demo',
      name: 'demo',
      domains: ['admin-demo.opx.grupoq.io'],
      apiUrl: 'https://api-demo.opx.grupoq.io',
      googleId:
        '458277232320-ojmn61rorm5dc48egmdsp0l8lc3od3jn.apps.googleusercontent.com',
      disabledFeatures: []
    },
    {
      project: 'poseidon',
      name: 'usp',
      domains: ['admin-usp.opx.grupoq.io'],
      apiUrl: 'https://api-usp.opx.grupoq.io',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'interactive_classes',
        'faq_coordinators',
        'undergraduate_courses',
        'libraries',
        'forums',
        'forums/resource_reports',
        'franchises'
      ],
      googleId:
        '961007583174-kg8l7s30gj5cgulkmt4a7e2g11npsufa.apps.googleusercontent.com'
    },
    {
      project: 'demeter-sc',
      name: 'ibmec-solucoes-corporativas',
      domains: ['admin-ibmecsolcorp.opx.grupoq.io', 'admin.solcorp.ibmec.br'],
      apiUrl: 'https://api-ibmecsolcorp.opx.grupoq.io',
      lmsUrl: 'https://app.solcorp.ibmec.br',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'action_logs',

        'manual_orders',
        'undergraduate_courses',
        'franchises'
      ],
      googleId:
        '842505787047-77sv5srsghtgif367mci59hh6hkq1bhg.apps.googleusercontent.com'
    },
    {
      project: 'estaciosolcorp',
      name: 'estacio-solucoes-corporativas',
      domains: ['admin.solcorp.estacio.br'],
      apiUrl: 'https://api.solcorp.estacio.br',
      lmsUrl: 'https://solcorp.estacio.br',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'action_logs',

        'manual_orders',
        'undergraduate_courses',
        'franchises'
      ],
      googleId:
        '340043161412-ae8j3s1cm918gttg49r2464f6v507ftq.apps.googleusercontent.com'
    },
    {
      project: 'demeter-pp',
      name: 'ibmec-pos-parcerias',
      domains: [
        'admin-ibmecposparcerias.opx.grupoq.io',
        'admin.parcerias.ibmec.br'
      ],
      apiUrl: 'https://api-ibmecposparcerias.opx.grupoq.io',
      lmsUrl: 'https://app.parcerias.ibmec.br',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'undergraduate_courses',
        'franchises'
      ],
      googleId:
        '1064361393394-72ur8dgvca8kk6f63ibevmem2mp0ljcc.apps.googleusercontent.com'
    },
    {
      project: 'dionysus',
      name: 'howuniversity',
      domains: ['admin.howuniversity.com.br'],
      apiUrl: 'https://api.howuniversity.com.br',
      lmsUrl: 'https://app.howuniversity.com.br',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',

        'manual_orders',
        'interactive_classes',
        'faq_coordinators',
        'courses_v2',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ],
      googleId:
        '345706624231-rcat6lncuo2kmiunobcanvg2v49be5eb.apps.googleusercontent.com'
    },
    {
      project: 'hygieia',
      name: 'idomed',
      domains: ['admin.idomed.opx.grupoq.io', 'admin.cursos.idomed.com.br'],
      apiUrl: 'https://api.cursos.idomed.com.br',
      lmsUrl: 'https://app.cursos.idomed.com.br',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'solicitations/equivalencies',
        'prometheus/lives',
        'action_logs',
        'graduate_courses',
        'action_logs',
        'companies',
        'undergraduate_courses',
        'libraries',
        'franchises'
      ],
      googleId:
        '1055274702036-9156aag8in3jr69u9g669mquf11qq1d7.apps.googleusercontent.com'
    },
    {
      project: 'grupoq',
      name: 'grupoq',
      domains: ['admin.lxs.grupoq.io'],
      apiUrl: 'https://apilxs.grupoq.io',
      lmsUrl: 'https://lxs.grupoq.io',
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'manual_orders',
        'undergraduate_courses',
        'franchises'
      ],
      googleId:
        '21475016232-95mkmmfu2d1qtokt4ni7q65kcn6849mt.apps.googleusercontent.com'
    },
    {
      project: 'estacioprofissionalizantes',
      name: 'estacioprofissionalizantes',
      apiUrl: 'https://api.app.profissionalizantes.estacio.br',
      lmsUrl: 'https://app.profissionalizantes.estacio.br',
      domains: ['admin.app.profissionalizantes.estacio.br'],
      disabledFeatures: [
        'comments',
        'comments/resource_reports',
        'manual_orders',
        'undergraduate_courses',
        'franchises'
      ],
      googleId:
        '120895578649-gqeter5c2tml94p4ifv29fe553768uiq.apps.googleusercontent.com'
    },
    {
      project: 'poseidon-academy',
      name: 'feausp',
      domains: ['admin.feauspacademy.com', 'admin.feauspacademy.com.br'],
      apiUrl: 'https://api.feauspacademy.com.br',
      lmsUrl: 'https://app.feauspacademy.com.br',
      disabledFeatures: [
        'manual_orders',
        'undergraduate_courses',
        'contents_modal_v1',
        'franchises'
      ],
      googleId:
        '809881522964-msjbhb78eess67jktnlsnlk7ihjvo3b2.apps.googleusercontent.com'
    }
  ]
};

export const getTenant = (): ITenant => {
  const tenant: ITenant | undefined = (tenantList[env] || []).find(tenant =>
    tenant.domains.some(domain =>
      new RegExp(domain).test(window.location.hostname)
    )
  );

  if (!tenant) {
    throw 'TenantError';
  }

  return tenant;
};
