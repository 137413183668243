import AssetUpload from 'components/AssetUpload';
import { Form, FormProps, Input, InputNumber, Select } from 'antd';
import type { IGraduateCourseShow } from 'interfaces/graduate_courses';

interface TabGeneralInfoProps {
  formProps: FormProps<IGraduateCourseShow>;
  record?: IGraduateCourseShow;
}

export const TabGeneralInfo = ({ formProps, record }: TabGeneralInfoProps) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Nome"
        name="name"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="ID do curso" name="id">
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Tipo"
        name="kind"
        rules={[
          {
            required: true
          }
        ]}
      >
        <Select disabled>
          <Select.Option value="graduate">Pós Graduação</Select.Option>
          <Select.Option value="undergraduate">Graduação</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Código"
        name="code"
        className="max-w-xs"
        rules={[
          {
            required: Boolean(formProps.initialValues?.code),
            message: 'O código é obrigatório.'
          },
          {
            max: 4,
            message: 'O código deve ter apenas 4 digitos'
          }
        ]}
      >
        <Input maxLength={4} />
      </Form.Item>

      <Form.Item label="Código e-MEC" name="emec" className="max-w-xs">
        <Input />
      </Form.Item>

      <Form.Item
        label="Duração (horas)"
        name="duration_in_hours"
        rules={[
          {
            required: true
          }
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        label="Nota mínima (0 - 100)"
        name="min_evaluation_score"
        rules={[
          {
            required: true
          }
        ]}
      >
        <InputNumber min={0} max={100} />
      </Form.Item>

      <Form.Item
        label="Card"
        name={['media', 'card_image']}
        rules={[
          {
            required: false
          }
        ]}
      >
        <AssetUpload
          accept="image/png, image/jpeg"
          type="course"
          src={record?.media?.card_image}
        />
      </Form.Item>
    </Form>
  );
};
