import React from 'react';
import { EvaluationsListItem } from './EvaluationsListItem';
import { IProfessorAreaAssessment } from 'interfaces/professor_area/assessments';
import { Pagination } from 'antd';

export const EvaluationsList: React.FC<{
  data: IProfessorAreaAssessment[];
  total: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number, pageSize?: number) => void;
}> = ({ data, total, pageSize, currentPage, onPageChange }) => {
  return (
    <div>
      {data.map((assessment: IProfessorAreaAssessment, index) => (
        <EvaluationsListItem key={index} assessment={assessment} />
      ))}
      <Pagination
        className="justify-self-end mt-4 text-center"
        hideOnSinglePage
        total={total}
        pageSize={pageSize}
        current={currentPage}
        onChange={onPageChange}
      />
    </div>
  );
};
