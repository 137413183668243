import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, InputNumber, Radio, Select } from 'antd';
import { ICertificateKey, ICourse } from 'interfaces/courses';
import { IInstructor } from 'interfaces/instructors';
import { getTenant } from 'services/tenants';

export const CourseCreate = () => {
  const { formProps, saveButtonProps } = useForm<ICourse>({
    redirect: 'edit'
  });
  const { selectProps: instructorSelectProps } = useSelect<IInstructor>({
    resource: 'instructors',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: certificateKeySelectProps } = useSelect<ICertificateKey>(
    {
      resource: 'certificate_keys',
      optionLabel: 'key',
      optionValue: 'key',

      pagination: {
        mode: 'server'
      }
    }
  );

  const tenant = getTenant();
  const coursesV2Disabled = tenant.disabledFeatures?.includes('courses_v2');

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar curso avulso">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Versão"
          name="kind"
          rules={[
            {
              required: true
            }
          ]}
          initialValue={coursesV2Disabled ? 'v1' : 'v2'}
        >
          <Select>
            <Select.Option value="v1">V1 (Deprecada)</Select.Option>
            {!coursesV2Disabled && (
              <Select.Option value="v2">V2 (Com turmas)</Select.Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Nota mínima (0 - 100)"
          name="min_evaluation_score"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>

        <Form.Item
          label="Duração (horas)"
          name="duration_in_hours"
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item label="Instrutores" name="instructor_ids">
          <Select {...instructorSelectProps} mode="multiple" />
        </Form.Item>

        <Form.Item
          label="Template de certificado"
          name="certificate_template_key"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select {...certificateKeySelectProps} />
        </Form.Item>

        <Form.Item
          label="Tipo de emissão de certificado"
          name="certificate_automatic"
          initialValue={true}
          extra={
            <p style={{ marginTop: 8 }}>
              Automática: O aluno pode emitir o certificado diretamente pela
              plataforma.
              <br />
              Manual: A emissão do certificado é feita pela secretaria.
            </p>
          }
        >
          <Radio.Group>
            <Radio.Button value={true}>Automática</Radio.Button>
            <Radio.Button value={false}>Manual</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Create>
  );
};
