import './styles.less';
import { CaretDownFilled } from '@ant-design/icons';
import { ContentTreeNode } from './ContentTreeNode';
import { Empty, Tree } from 'antd';
import { convertContentNodes, convertNodes } from 'services/courses/tree';
import { useCustom } from '@refinedev/core';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import type { ITree } from 'interfaces/tree';

export const TabContent = () => {
  const params = useParams();
  const classroomId = params.id;

  const { data, isLoading } = useCustom<ITree>({
    url: `admin/professor_area/classrooms/${classroomId}/contents`,
    method: 'get'
  });

  const treeData = useMemo(() => {
    if (data?.data) {
      return [
        ...convertNodes(data?.data.nodes),
        ...convertContentNodes(data?.data.contents)
      ];
    }
  }, [data]);

  if (!isLoading && !treeData?.length) {
    return <Empty description="Nenhum conteúdo encontrado" />;
  }

  return (
    <Tree
      treeData={treeData}
      titleRender={node => <ContentTreeNode node={node} />}
      expandAction="click"
      blockNode
      selectable={false}
      switcherIcon={<CaretDownFilled className="!text-sm text-neutral-500" />}
      prefixCls="teacher-tabContent"
    />
  );
};
