import React, { useCallback } from 'react';
import {
  CalendarOutlined,
  CommentOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { CardItem } from 'components/professor_area/CardItem';
import { IForum } from 'interfaces/forum';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuProps, Modal, Space, Typography } from 'antd';
import { TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { getForumTagData } from 'services/professor_area/forum';
import { useDelete, useNavigation } from '@refinedev/core';

const { Text } = Typography;
const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar tópico',
    icon: <EyeOutlined />
  },
  {
    key: 'close',
    label: 'Fechar tópico',
    icon: <DeleteOutlined />
  }
];

export const ForumListItem: React.FC<{
  forum: IForum;
  refetch: () => void;
}> = ({ forum, refetch }) => {
  const { push } = useNavigation();
  const { mutateAsync } = useDelete();
  const tagData = getForumTagData(forum);

  const handleCloseForum = useCallback(() => {
    confirm({
      title: 'Deseja fechar esse tópico?',
      content: 'Essa ação não pode ser revertida',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okButtonProps: { type: 'primary', danger: true },
      okText: 'Fechar tópico',
      cancelText: 'Voltar',
      onOk: () => {
        mutateAsync({
          resource: 'professor_area/forums',
          id: forum.id,
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Forum fechado com sucesso'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message: 'Não foi possível fechar este forum. Tente novamente.'
          })
        })
          .then(() => {
            refetch();
          })
          .catch();
      }
    });
  }, [forum, mutateAsync, refetch]);

  const handleDropdown = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'show') {
        push(`/professor_area/forums/show/${forum.id}`);
      } else {
        handleCloseForum();
      }
    },
    [forum, push, handleCloseForum]
  );

  return (
    <CardItem
      icon={CommentOutlined}
      title={forum.title}
      meta={
        <Space className="gap-4">
          <Text type="secondary">{forum.author.name}</Text>
          <Space className="gap-1">
            <MessageOutlined />
            <Text type="secondary">{forum.posts_count ?? 0}</Text>
          </Space>
          <Space className="gap-1">
            <CalendarOutlined />
            <Text type="secondary">
              {formatDate(forum.created_at, 'DD/MM/YYYY')}
            </Text>
          </Space>
        </Space>
      }
      extraContent={<TagField color={tagData.color} value={tagData.label} />}
      menuProps={{ items, onClick: handleDropdown } as MenuProps}
    />
  );
};
