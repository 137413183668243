import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { DatePicker, Typography } from 'antd';
import { Rule } from 'antd/lib/form';
import { Stack } from '@qcx/ui';

export const DateRangeFormField: React.FC<{
  title?: string;
  subtitle?: string;
  startRangeDatafield: string;
  endRangeDatafield: string;
  rules: Rule[] | undefined;
}> = ({ title, subtitle, startRangeDatafield, endRangeDatafield, rules }) => {
  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Text>{subtitle}</Typography.Text>
      <Stack className="gap-2 items-center mt-4">
        <FormItem name={startRangeDatafield} rules={rules} noStyle>
          <DatePicker
            showTime
            format={'DD/MM/YYYY HH:mm'}
            placeholder="dd/mm/aaaa hh:mm"
          />
        </FormItem>
        {'>'}
        <FormItem name={endRangeDatafield} rules={rules} noStyle>
          <DatePicker
            showTime
            format={'DD/MM/YYYY HH:mm'}
            placeholder="dd/mm/aaaa hh:mm"
          />
        </FormItem>
      </Stack>
    </>
  );
};
