import React, { useState } from 'react';
import { Button, Empty, Row, Space, Spin, Typography } from 'antd';
import { CrudFilters, HttpError, useModal } from '@refinedev/core';
import { DownloadOutlined } from '@ant-design/icons';
import { ENROLLMENT_STATUS } from 'constants/admin/enrollments';
import { EditFrequencyModal } from 'components/modal/professor_area/students/EditFrequencyModal';
import { IStudent, IStudentShow } from 'interfaces/professor_area/students';
import { ImportAttendanceModal } from 'components/modal/professor_area/students/ImportAttendanceModal';
import { SearchFilters } from './components/SearchFilters';
import { StudentDetailsModal } from 'components/modal/professor_area/students/StudentDetailsModal';
import { StudentList } from './components/StudentList';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

export interface IFilter {
  name: string;
  enrollment: string;
}

export const TabStudents: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [studentContext, setStudentContext] = useState<IStudentShow>();
  const editFrequencyModalProps = useModal();
  const studentDetailsModalProps = useModal();
  const attendanceModalProps = useModal();
  const {
    tableQueryResult: { data, isLoading, refetch },
    current,
    setCurrent,
    pageSize,
    searchFormProps
  } = useTable<IStudent, HttpError, IFilter>({
    resource: `professor_area/classrooms/${id}/enrollments`,
    initialPageSize: 999,
    filters: {
      permanent: [
        {
          field: 'filter[by_enrollment_status]',
          operator: 'eq',
          value: ENROLLMENT_STATUS.ACTIVE
        }
      ]
    },
    onSearch: ({ name, enrollment }) => {
      const filters: CrudFilters = [];

      filters.push(
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_enrollment_registration]',
          operator: 'eq',
          value: enrollment
        }
      );
      return filters;
    }
  });
  const studentsData = data?.data as IStudent[];

  const handleEditFrequency = (student: IStudentShow) => {
    setStudentContext(student);
    editFrequencyModalProps.show();
  };

  const handleShowDetails = (student: IStudentShow) => {
    setStudentContext(student);
    studentDetailsModalProps.show();
  };

  return (
    <div>
      <SearchFilters searchFormProps={searchFormProps} />
      <Space className="w-full justify-between items-center mb-4">
        <Typography.Title level={5} className="!m-0">
          Listagem de alunos
        </Typography.Title>
        <Button
          type="default"
          icon={<DownloadOutlined />}
          onClick={attendanceModalProps.show}
        >
          Importar frequência
        </Button>
      </Space>

      {isLoading && (
        <Row className="w-full flex justify-center">
          <Spin className="self-center mt-8" size="large" />
        </Row>
      )}

      {!isLoading && !studentsData?.length && (
        <Row className="w-full flex justify-center">
          <Empty description="A pesquisa não retornou alunos." />
        </Row>
      )}

      {!isLoading && studentsData?.length > 0 && (
        <StudentList
          data={studentsData.slice(
            (current - 1) * pageSize,
            current * pageSize
          )}
          total={studentsData.length}
          pageSize={pageSize}
          currentPage={current}
          onPageChange={setCurrent}
          handleEditFrequency={handleEditFrequency}
          handleShowDetails={handleShowDetails}
        />
      )}

      <EditFrequencyModal
        modalProps={editFrequencyModalProps}
        student={studentContext}
        refetch={refetch}
        onUpdateStudent={setStudentContext}
      />

      <StudentDetailsModal
        handleEditFrequency={handleEditFrequency}
        modalProps={studentDetailsModalProps}
        student={studentContext}
      />

      <ImportAttendanceModal
        modalProps={attendanceModalProps}
        refetch={refetch}
        classroomId={id!}
      />
    </div>
  );
};
