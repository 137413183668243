import { AuditOutlined, LeftOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import { EVALUATION_KIND_MAP } from 'services/graduate-enrollment/evaluations';
import { EvaluationsTabList } from './components/EvaluationsTabList';
import { IProfessorAreaAssessmentShow } from 'interfaces/professor_area/assessments';
import { Show, TagField } from '@refinedev/antd';
import { Stack } from '@qcx/ui';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Text, Title } = Typography;

const isLoading = false;

export const EvaluationShow = () => {
  const { id, classroomId } = useParams();
  const { queryResult } = useShow({
    resource: `professor_area/classrooms/${classroomId}/evaluations`,
    id
  });

  const evaluation = queryResult.data?.data as IProfessorAreaAssessmentShow;

  if (!evaluation) {
    return null;
  }

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          body: {
            padding: 0,
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      <>
        <Card className="!mb-4">
          <Stack className="items-center justify-between">
            <Stack className="items-center gap-4">
              <Space
                direction="vertical"
                className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#a855f7]/10 justify-center items-center !gap-0"
              >
                <AuditOutlined className="text-[#9333ea] text-2xl" />
              </Space>
              <Stack flow="column">
                <Title level={3}>{evaluation.name}</Title>
                <Stack flow="row" className="!gap-1">
                  <TagField color="default" value={'Avaliação'} />
                  <TagField
                    color="default"
                    value={EVALUATION_KIND_MAP[evaluation.evaluation_kind]}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <EvaluationsTabList evaluation={evaluation} />
      </>
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">Visualizar avaliações</Text>
  </Space>
);
