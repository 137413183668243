import React from 'react';
import { Button, Space, Typography } from 'antd';
import { IDataContent } from 'interfaces/courses';

export const ContentDownload: React.FC<{
  content: IDataContent;
}> = ({ content }) => {
  return (
    <Space direction="vertical" className="w-full gap-2 items-center">
      <Typography.Text>
        Conteúdo em pdf disponível para download.
      </Typography.Text>
      <Button
        href={content.url}
        target="_blank"
        rel="noopener noreferrer"
        type="primary"
      >
        Baixar PDF
      </Button>
    </Space>
  );
};
