import * as Icons from '@ant-design/icons';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { CreateDisciplineModal } from 'components/modal/courses/CreateDisciplineModal';
import {
  DeleteButton,
  ShowButton,
  TagField,
  TextField,
  useModal,
  useModalForm,
  useTable
} from '@refinedev/antd';
import {
  IDisciplineModule,
  IGraduateDisciplineModule
} from 'interfaces/graduate_discipline_module';
import { IGraduateModule } from 'interfaces/graduate_courses';
import {
  IModuleWithDiscipline,
  modulesWithDisciplines
} from '../../../utils/modulesWithDisciplines';
import { ModuleDisciplineForm } from './ModuleDisciplineForm';
import { merge } from 'lodash-es';
import { pluralize } from '../../../utils/pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDelete } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const disciplineModulesKind = {
  mandatory: 'Obrigatória',
  optional: 'Optativa'
};

export const TabDisciplines = () => {
  const { id: courseId } = useParams();
  const confirmationModal = useModal();
  const { mutate: deleteModule } = useDelete();
  const [modules, setModules] = useState<IModuleWithDiscipline[]>([]);
  const [moduleId, setModuleId] = useState<IModuleWithDiscipline['id']>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [disciplineEdit, setDisciplineEdit] = useState<IDisciplineModule>();
  const resource = 'graduate/disciplines_modules';
  const resourceModules = 'graduate_modules';

  const {
    show: disciplineCreateModalShow,
    close: disciplineCreateModalClose,
    modalProps: disciplineCreateModalProps
  } = useModal();

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow
  } = useModalForm<IGraduateModule>({
    action: 'create',
    resource: resourceModules,
    redirect: false
  });

  const {
    modalProps: editModalProps,
    form: editForm,
    formProps: editFormProps,
    show: editModalShow
  } = useModalForm<IGraduateModule>({
    resource: resourceModules,
    action: 'edit',
    redirect: false,
    successNotification: () => {
      return {
        description: 'Sucesso!',
        message: 'Modulo alterado com sucesso',
        type: 'success'
      };
    }
  });

  const {
    modalProps: editDisciplineModalProps,
    form: editDisciplineForm,
    formProps: editDisciplineFormProps,
    show: editDisciplineModalShow
  } = useModalForm<IGraduateDisciplineModule>({
    resource,
    action: 'edit',
    redirect: false,
    successNotification: () => {
      return {
        description: 'Sucesso!',
        message: 'Disciplina alterada com sucesso',
        type: 'success'
      };
    }
  });

  const { tableQueryResult: modulesQuery } = useTable({
    resource: resourceModules,
    pagination: {
      pageSize: 100
    },
    filters: {
      initial: [
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: courseId
        }
      ]
    }
  });

  const courseModules = useMemo(() => {
    return modulesQuery?.data?.data.reduce((result, curr) => {
      result[String(curr.id)] = {
        id: curr.id,
        name: curr.name,
        disciplines: []
      };
      return result;
    }, {});
  }, [modulesQuery?.data?.data]);

  const { tableQueryResult } = useTable<IGraduateDisciplineModule>({
    resource,
    pagination: {
      pageSize: 200
    },
    filters: {
      initial: [
        {
          field: 'filter[by_course]',
          operator: 'eq',
          value: courseId
        }
      ]
    }
  });

  const handleDeleteModule = useCallback(
    async (moduleId: string) => {
      await deleteModule({
        resource: resourceModules,
        id: moduleId,
        successNotification: () => {
          return {
            description: 'Sucesso!',
            message: 'Módulo removido com sucesso!',
            type: 'success'
          };
        }
      });
      confirmationModal.close();
    },
    [deleteModule, confirmationModal]
  );

  useEffect(() => {
    const modules = courseModules;
    const disciplines = tableQueryResult?.data?.data;

    if (disciplines) {
      const withDisciplines = modulesWithDisciplines(disciplines, courseId!);
      // DO not change order as here order matters.
      const mergedObject = merge({}, withDisciplines, modules);

      setModules(
        Object.values(mergedObject).sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [tableQueryResult?.data?.data, courseId, courseModules]);

  const handleDeleteModuleButton = useCallback(
    (module: IModuleWithDiscipline) => () => {
      setDeleteId(module.id);
      confirmationModal.show();
    },
    [confirmationModal]
  );

  const handleModuleEdit = useCallback(
    (module: IModuleWithDiscipline) => () => {
      editModalShow(module.id);
    },
    [editModalShow]
  );

  const handleEditDiscipline = useCallback(
    (record: IDisciplineModule) => () => {
      setDisciplineEdit(record);
      editDisciplineModalShow(record.disciplineModuleId);
    },
    [editDisciplineModalShow]
  );

  if (!courseId) {
    return null;
  }

  return (
    <>
      <Space align="end" className="my-4 justify-end w-full">
        <Button icon={<Icons.PlusOutlined />} onClick={() => createModalShow()}>
          Novo módulo
        </Button>
      </Space>
      <Collapse>
        {modules?.map(module => (
          <Collapse.Panel
            className="relative"
            header={
              <Space direction="horizontal" className="w-full">
                <Typography>{module.name}</Typography>
                <TagField
                  value={pluralize(
                    module.disciplines.length,
                    'disciplina',
                    'disciplinas'
                  )}
                />{' '}
                <Button
                  icon={<Icons.EditOutlined />}
                  style={{
                    position: 'absolute',
                    right: 40,
                    bottom: 10
                  }}
                  size="small"
                  onClick={handleModuleEdit(module)}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: 10,
                    bottom: 10
                  }}
                >
                  <Tooltip
                    title="Modulo não pode ter disciplinas"
                    placement="topRight"
                    arrowPointAtCenter
                    overlayStyle={{
                      visibility:
                        module.disciplines.length > 0 ? 'visible' : 'hidden'
                    }}
                  >
                    <Button
                      disabled={module.disciplines.length > 0}
                      icon={<Icons.DeleteOutlined />}
                      size="small"
                      onClick={handleDeleteModuleButton(module)}
                    />
                  </Tooltip>
                </div>
              </Space>
            }
            key={module.id}
          >
            <>
              <ModuleDisciplineForm
                moduleId={module.id}
                disciplineCreateModalShow={() => {
                  setModuleId(module.id);
                  disciplineCreateModalShow();
                }}
              />

              <Table dataSource={module.disciplines}>
                <Table.Column
                  className="w-[60%]"
                  title="Nome"
                  dataIndex="name"
                  key="name"
                />
                <Table.Column
                  title="Código externo"
                  dataIndex="external_reference"
                  key="external_reference"
                />
                <Table.Column
                  title="Tipo"
                  dataIndex="disciplineModuleKind"
                  key="disciplineModuleKind"
                  render={value => (
                    <TextField
                      value={
                        disciplineModulesKind[
                          value as keyof typeof disciplineModulesKind
                        ]
                      }
                    />
                  )}
                />
                <Table.Column<IDisciplineModule>
                  align="right"
                  title="Ações"
                  dataIndex="actions"
                  key="actions"
                  render={(_, record) => {
                    return (
                      <Space>
                        <ShowButton
                          size="small"
                          hideText
                          resource="graduate_disciplines"
                          recordItemId={record.id}
                        />
                        <Button
                          icon={<Icons.EditOutlined />}
                          size="small"
                          onClick={handleEditDiscipline(record)}
                        />
                        <DeleteButton
                          danger={false}
                          hideText
                          icon={<Icons.DeleteOutlined />}
                          size="small"
                          resource={resource}
                          recordItemId={record.disciplineModuleId}
                          successNotification={() => {
                            return {
                              description: 'Sucesso!',
                              message:
                                'Disciplina removida do módulo com sucesso',
                              type: 'success'
                            };
                          }}
                        />
                      </Space>
                    );
                  }}
                />
              </Table>
            </>
          </Collapse.Panel>
        ))}
      </Collapse>
      <Modal {...createModalProps} title="Novo módulo">
        <Form
          {...createFormProps}
          initialValues={{ course_id: courseId }}
          layout="vertical"
        >
          <Form.Item
            label="Nome do módulo"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="course_id" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...editModalProps} title="Editar módulo">
        <Form form={editForm} {...editFormProps} layout="vertical">
          <Form.Item
            label="Nome do módulo"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        {...editDisciplineModalProps}
        title="Deseja alterar o tipo da disciplina?"
        style={{ maxWidth: 400 }}
      >
        <Title level={5}>Nome da disciplina</Title>
        <Text style={{ marginLeft: 8 }}>{disciplineEdit?.name}</Text>
        <Title level={5}>Código externo</Title>
        <Text style={{ marginLeft: 8 }}>
          {disciplineEdit?.external_reference ?? '-'}
        </Text>
        <Form
          form={editDisciplineForm}
          {...editDisciplineFormProps}
          layout="vertical"
          style={{ marginTop: 8 }}
        >
          <Form.Item
            label={<Title level={5}>Tipo da disciplina</Title>}
            name="kind"
          >
            <Select
              placeholder="Selecione o tipo ..."
              options={[
                { label: 'Obrigatória', value: 'mandatory' },
                { label: 'Optativa', value: 'optional' }
              ]}
              style={{ maxWidth: 200, marginLeft: 8 }}
              defaultValue={disciplineEdit?.disciplineModuleKind}
            />
          </Form.Item>
        </Form>
      </Modal>
      <ConfirmationModal
        okText="Confirmar"
        onOk={() => handleDeleteModule(deleteId)}
        bodyTitle="Excluir módulo"
        bodyText="Ao confirmar essa ação, ela não poderá ser desfeita. Deseja continuar?"
        width={500}
        {...confirmationModal.modalProps}
      />
      <CreateDisciplineModal
        modalClose={disciplineCreateModalClose}
        modalProps={disciplineCreateModalProps}
        refetch={tableQueryResult.refetch}
        courseId={moduleId}
        type="graduate_course"
      />
    </>
  );
};
