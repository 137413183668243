import './styles.less';
import React from 'react';
import { Collapse, CollapseProps, Radio, Space, Typography } from 'antd';
import {
  IProfessorAreaAssessmentAttemptsEditQuestion,
  IProfessorAreaAssessmentAtttemptsAnswer
} from 'interfaces/professor_area/assessments';
import { TagField } from '@refinedev/antd';

const { Text } = Typography;

export const ObjectiveQuestion: React.FC<{
  answer: IProfessorAreaAssessmentAtttemptsAnswer;
  questions: IProfessorAreaAssessmentAttemptsEditQuestion[];
  index: string;
}> = ({ answer, questions, index }) => {
  const question = questions.find(
    question => question.id === answer.question_id
  );

  const getAnswerTag = (header?: boolean) => {
    if (header && !answer.alternative_id) {
      return (
        <Space>
          <TagField color="default" value="Em branco" />
          <TagField color="error" value="Incorreto" />
        </Space>
      );
    }

    if (!answer.alternative_id) {
      return null;
    }

    if (answer.alternative_id === question?.correct_alternative_id) {
      return <TagField color="success" value="Correto" />;
    } else if (answer.answer !== answer.alternative_id) {
      return <TagField color="error" value="Incorreto" />;
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: index,
      label: (
        <Space className="w-full justify-between">
          <Text type="secondary" strong>
            {`Questão ${index}`}
          </Text>
          {getAnswerTag(true)}
        </Space>
      ),
      children: (
        <Space direction="vertical">
          <Text type="secondary" className="font-semibold">
            Enunciado
          </Text>
          <div
            style={{
              display: 'block',
              fontWeight: 600,
              marginBottom: 8
            }}
            className="dangerousText"
            dangerouslySetInnerHTML={{
              __html: answer.statement
            }}
          />
          <Space direction="vertical" className="w-full">
            <Text type="secondary" className="font-semibold">
              Resposta do aluno
            </Text>
            <Radio.Group
              className={`flex flex-col ${
                answer.alternative_id === question?.correct_alternative_id
                  ? 'success-radio-group'
                  : 'error-radio-group'
              }`}
              value={answer.alternative_id}
              options={answer.alternatives.map(alternative => ({
                value: alternative.id,
                label: (
                  <Space>
                    <div
                      className="dangerousText"
                      dangerouslySetInnerHTML={{
                        __html: alternative.statement
                      }}
                    />
                    {answer.alternative_id === alternative.id && getAnswerTag()}
                  </Space>
                )
              }))}
            />
          </Space>
          <Space direction="vertical" className="w-full mt-4">
            <Text type="secondary" className="font-semibold">
              Gabarito
            </Text>
            <Radio.Group
              className="flex flex-col"
              value={answer.alternative_id}
              options={answer.alternatives.reduce<
                { label: React.ReactNode; value: string }[]
              >((acc, alternative) => {
                if (alternative.id === answer.alternative_id) {
                  acc.push({
                    value: alternative.id,
                    label: (
                      <div
                        className="dangerousText"
                        dangerouslySetInnerHTML={{
                          __html: alternative.statement
                        }}
                      />
                    )
                  });
                }
                return acc;
              }, [])}
            />
          </Space>
        </Space>
      )
    }
  ];

  return (
    <Collapse
      items={items}
      defaultActiveKey={['1']}
      expandIconPosition="end"
      className="bg-white mb-[20px] last:mb-0"
    />
  );
};
