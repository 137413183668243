import React from 'react';
import { Button, Col, Form, FormProps, Input, Row, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const SearchFilters: React.FC<{
  searchFormProps: FormProps;
}> = ({ searchFormProps }) => {
  const handleClear = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  return (
    <Form {...searchFormProps} layout="inline" className="mb-8 w-full">
      <Row gutter={[0, 16]} className="w-full">
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="name">
            <Input
              placeholder="Buscar por nome do aluno..."
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="enrollment">
            <Input
              placeholder="Buscar por nº da Matrícula..."
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Form.Item>
            <Space className="w-full">
              <Button type="default" htmlType="submit">
                Filtrar
              </Button>
              <Button type="text" onClick={handleClear}>
                Limpar filtro
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
