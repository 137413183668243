import {
  IProfessorAreaAssessmentAttemptsEdit,
  IProfessorAreaAssessmentAttemptsShow
} from 'interfaces/professor_area/assessments';
import { StatusType } from 'constants/admin/evaluations';
import { TSubHeaderFieldsData } from 'components/professor_area/EvaluationsSubheader';
import { formatDate } from 'services/date';

export const splitSubheaderData = (
  arr: TSubHeaderFieldsData[]
): TSubHeaderFieldsData[][] => {
  const result: TSubHeaderFieldsData[][] = [];

  for (let i = 0; i < arr.length; i += 2) {
    result.push(arr.slice(i, i + 2));
  }
  return result;
};

export const parseAttemptShowSubheaderData = (
  assessmentAttempt: IProfessorAreaAssessmentAttemptsShow
) => {
  return [
    {
      label: 'Nota',
      value: assessmentAttempt.score?.toString() ?? '-'
    },
    {
      label: 'Tentativas',
      value: assessmentAttempt.user_attempts.length?.toString() ?? '-'
    },
    {
      label: 'Nota mínima',
      value: assessmentAttempt.assessment.passing_score?.toString() ?? '-'
    },
    {
      label: 'Data de último envio',
      value:
        formatDate(assessmentAttempt.completed_at, 'DD/MM/YYYY H[h]mm') ?? '-'
    }
  ];
};

export const parseAttemptEditSubheaderData = (
  assessmentAttempt: IProfessorAreaAssessmentAttemptsShow,
  userAttempt: IProfessorAreaAssessmentAttemptsEdit
) => {
  return [
    {
      label: 'Nota',
      value:
        (
          calcScore(userAttempt) / assessmentAttempt.questions.length
        )?.toString() ?? '-'
    },
    {
      label: 'Nota mínima',
      value: assessmentAttempt?.assessment?.passing_score?.toString() ?? '-'
    },
    {
      label: 'Em branco',
      value:
        userAttempt.assessment.kind === 'objective'
          ? userAttempt?.answers
              ?.filter(answer => !answer.alternative_id)
              .length?.toString() ?? '-'
          : userAttempt?.answers
              ?.filter(answer => !answer.answer)
              .length?.toString() ?? '-'
    },
    {
      label: 'Anuladas',
      value:
        userAttempt?.questions
          ?.filter(question => question.status === 'nullified')
          .length?.toString() ?? '-'
    }
  ];
};

export const parseAttemptEditSubheaderDateData = (
  assessmentAttempt: IProfessorAreaAssessmentAttemptsShow
) => {
  return [
    {
      label: 'Iniciado em',
      value:
        formatDate(assessmentAttempt?.created_at, 'DD/MM/YYYY H[h]mm') ?? '-'
    },
    {
      label: 'Finalizado em',
      value:
        formatDate(assessmentAttempt?.completed_at, 'DD/MM/YYYY H[h]mm') ?? '-'
    }
  ];
};

export const getStatusData = (
  status: string,
  statusData: StatusType[]
): StatusType | undefined => {
  return statusData.find(data => data.value === status);
};

export const calcScore = (
  userAttempt: IProfessorAreaAssessmentAttemptsEdit
) => {
  let score = 0;

  if (userAttempt?.assessment?.kind === 'discursive') {
    for (const answer of userAttempt.answers) {
      if (answer.evaluator_score) {
        score = score + answer.evaluator_score;
      }
    }
  } else {
    score = userAttempt.score;
  }

  return score;
};
