import useNotification from 'hooks/useNotification';
import { Divider, Form, Input, InputNumber, Modal, Radio, Select } from 'antd';
import { EditFilled, ProfileOutlined } from '@ant-design/icons';
import { FieldsetTitle } from 'components/professor_area/FieldsetTitle';
import { FormItemCol } from 'components/professor_area/FormItemCol';
import { HttpError } from '@refinedev/core';
import { IProfessor } from 'interfaces/professor';
import { getTenant } from 'services/tenants';
import { useModalForm, useSelect } from '@refinedev/antd';

interface CreateGraduateCourseModalProps {
  open: boolean;
  onClose: (close: boolean) => void;
}

interface IGraduateCoursePost {
  name: string;
  kind: 'graduate' | 'postgraduate';
  emec: string;
  duration_in_hours: number;
  minimum_grade: number;
  coordinator_id: string;
}

export const CreateGraduateCourseModal = ({
  open,
  onClose
}: CreateGraduateCourseModalProps) => {
  const { showError, showSuccess } = useNotification();

  const tenant = getTenant();

  const { modalProps, formProps } = useModalForm<
    IGraduateCoursePost,
    HttpError,
    IGraduateCoursePost
  >({
    action: 'create',
    resource: 'graduate_courses',
    redirect: false,
    successNotification: false,
    errorNotification: false,
    onMutationSuccess: () => {
      formProps.form?.resetFields();
      showSuccess('Curso cadastrado com sucesso');
      onClose(false);
    },
    onMutationError: _error => {
      const message = 'Não foi possível cadastrar o curso. Tente novamente.';
      showError(message);
    }
  });

  const { selectProps: coordinatorSelectProps } = useSelect<IProfessor>({
    resource: 'professors',
    optionLabel: 'name',
    optionValue: 'id',
    fetchSize: 9999,

    onSearch: value => [
      {
        field: 'filter[by_name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={() => onClose(false)}
      title="Novo curso regulado"
      okText="Criar curso"
      cancelText="Voltar"
      width={800}
    >
      <Form {...formProps} layout="vertical">
        <fieldset>
          <FieldsetTitle title="Informações Gerais" icon={ProfileOutlined} />

          <div className="pl-6">
            <FormItemCol
              cols={[6, 18]}
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'O nome é obrigatório' }]}
            >
              <Input placeholder="Ex.: Direito Civil" />
            </FormItemCol>

            <FormItemCol
              cols={[6, 18]}
              label="Tipo de curso"
              name="kind"
              rules={[{ required: true, message: 'O tipo é obrigatório' }]}
              initialValue="graduate"
            >
              <Radio.Group>
                <Radio.Button value="graduate">Pós-graduação</Radio.Button>
                {!tenant.disabledFeatures?.includes(
                  'undergraduate_courses'
                ) && (
                  <Radio.Button value="undergraduate">Graduação</Radio.Button>
                )}
              </Radio.Group>
            </FormItemCol>
          </div>
        </fieldset>

        <Divider />

        <fieldset>
          <FieldsetTitle title="Dados do curso" icon={EditFilled} />

          <div className="pl-6">
            <FormItemCol
              cols={[6, 18]}
              label="Código e-MEC"
              name="emec"
              rules={[
                { required: true, message: 'O código e-MEC é obrigatório' }
              ]}
            >
              <Input className="sm:w-[100px]" />
            </FormItemCol>

            <FormItemCol
              cols={[6, 18]}
              label="Duração (horas)"
              name="duration_in_hours"
              rules={[{ required: true, message: 'A duração é obrigatória' }]}
            >
              <InputNumber className="sm:w-[100px]" />
            </FormItemCol>

            <FormItemCol
              cols={[6, 18]}
              label="Nota mínima (0-100)"
              name="min_evaluation_score"
              rules={[
                { required: true, message: 'A nota mínima é obrigatória' }
              ]}
            >
              <InputNumber className="sm:w-[100px]" />
            </FormItemCol>

            <FormItemCol
              cols={[6, 18]}
              label="Coordenador"
              name="professor_id"
              rules={[
                { required: true, message: 'O coordenador é obrigatório' }
              ]}
            >
              <Select
                {...coordinatorSelectProps}
                placeholder="Escolha o coordenador"
                showSearch
              />
            </FormItemCol>
          </div>
        </fieldset>
      </Form>
    </Modal>
  );
};
