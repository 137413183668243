import { GraduateCourseEdit, GraduateCourseList } from 'pages/graduate_courses';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'graduate_courses',
  label: 'Cursos regulados',
  list: GraduateCourseList,
  edit: GraduateCourseEdit,
  actions: ['list', 'edit', 'create', 'delete'],
  parentName: 'course_creation'
};

export default generateResources(resource);
