import FilterForm from '../../../components/forms/FilterForm';
import {
  ATTEMPTS_STATUS_MAP,
  translateAssignmentAttemptStatus
} from '../../../services/assignments';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAssessmentAttempt } from 'interfaces/assessments';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useTable
} from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';

interface IFilter {
  status: string;
  assessment_name: string;
}

export const AssessmentAttemptsList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAssessmentAttempt,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { status, assessment_name } = params;

      filters.push(
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[search_name]',
          operator: 'eq',
          value: assessment_name
        }
      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome da avaliação" name="assessment_name">
              <Input
                placeholder="Digite o nome da avaliação..."
                style={{ minWidth: 200 }}
              />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                {[...ATTEMPTS_STATUS_MAP].map(([key, value]) => (
                  <Select.Option key={key}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              title="Nome da avaliação"
              dataIndex={['assessment', 'name']}
              key="name"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              title="Tipo do curso"
              dataIndex={['assessment', 'parent_type']}
              key="parent_type"
              render={value => (
                <TextField
                  value={value === 'Course' ? 'Curso' : 'Disciplina'}
                />
              )}
            />

            <Table.Column
              title="Nome do curso"
              dataIndex={['assessment', 'course_name']}
              key="course_name"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              title="Iniciada em"
              dataIndex="created_at"
              key="created_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              title="Finalizada em"
              dataIndex="completed_at"
              key="completed_at"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />

            <Table.Column
              dataIndex="score"
              key="score"
              title="Nota"
              align="center"
              render={value => <TextField value={value ?? '--'} />}
            />

            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={tagColorByStatus(value)}
                  value={translateAssignmentAttemptStatus(value)}
                />
              )}
            />

            <Table.Column<IAssessmentAttempt>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
