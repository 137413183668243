import { Card, Typography } from 'antd';
import { formatDate } from 'services/date';
import type { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';

interface GeneralInfoProps {
  data: IProfessorAreaClassroom;
}

export const GeneralInfo = ({ data }: GeneralInfoProps) => {
  return (
    <Card
      title="Informações gerais da turma"
      className="!text-neutral-600"
      styles={{
        title: {
          fontSize: 14
        }
      }}
    >
      {data.description && (
        <Typography.Paragraph className="!mb-4 !text-neutral-600">
          {data.description}
        </Typography.Paragraph>
      )}

      <table
        className="text-left mb-4 !border-separate"
        style={{ borderSpacing: '0 8px' }}
      >
        <tbody>
          <tr className="pb-4">
            <td className="pr-4">Turma</td>
            <td>{data.code}</td>
          </tr>
          <tr>
            <td className="pr-4 align-top">Discplina</td>
            <td>{data.discipline_name}</td>
          </tr>
          <tr>
            <td className="pr-4">Período</td>
            <td>
              {data.academic_year}.{data.academic_period}
            </td>
          </tr>
          <tr>
            <td className="pr-4">Nº de alunos</td>
            <td>{data.active_enrollments_count || 0}</td>
          </tr>
          <tr>
            <td className="pr-4">Nota mínima</td>
            <td>{data.passing_score}</td>
          </tr>
          <tr>
            <td className="pr-4">Frequência</td>
            <td>
              {data.minimum_attendance ? 'Obrigatória' : 'Não obrigatória'}
            </td>
          </tr>
        </tbody>
      </table>

      <strong>Acessos</strong>

      <table
        className="text-left !border-separate"
        style={{ borderSpacing: '0 8px' }}
      >
        <tbody>
          <tr className="pb-4">
            <td className="pr-4">Vigência</td>
            <td>
              {formatDate(data.starts_at, 'DD/MM/YYYY')} {'>'}{' '}
              {formatDate(data.ends_at, 'DD/MM/YYYY')}
            </td>
          </tr>

          {data.content_access_starts_at && data.content_access_ends_at && (
            <tr>
              <td className="pr-4">Conteúdo</td>
              <td>
                {formatDate(data.content_access_starts_at, 'DD/MM/YYYY')} {'>'}{' '}
                {formatDate(data.content_access_ends_at, 'DD/MM/YYYY')}
              </td>
            </tr>
          )}

          {data.discussion_starts_at && data.discussion_ends_at && (
            <tr>
              <td className="pr-4">Tira-dúvidas</td>
              <td>
                {formatDate(data.discussion_starts_at, 'DD/MM/YYYY')} {'>'}{' '}
                {formatDate(data.discussion_ends_at, 'DD/MM/YYYY')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
};
