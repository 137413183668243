import React, { useState } from 'react';
import useNotification from 'hooks/useNotification';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Space,
  Typography
} from 'antd';
import {
  IProfessorAreaAssessmentAttemptsShow,
  IProfessorAreaAssessmentAtttemptsAnswer
} from 'interfaces/professor_area/assessments';
import { useCustomMutation } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

export const DiscursiveQuestion: React.FC<{
  question: IProfessorAreaAssessmentAtttemptsAnswer;
  attemptStatus: IProfessorAreaAssessmentAttemptsShow['status'];
  index: string;
  refetchData: () => void;
}> = ({ question, attemptStatus, index, refetchData }) => {
  const { classroomId, evaluationId } = useParams();
  const { mutateAsync, isLoading } = useCustomMutation();
  const { showError } = useNotification();
  const [form] = Form.useForm();

  const handleUpdateScore = () => {
    form
      .validateFields()
      .then(() => {
        mutateAsync({
          url: `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/answers/${question.id}`,
          method: 'patch',
          values: { ...form.getFieldsValue() },
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Questão corrigida com sucesso!'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message: 'Não foi possível corrigir a questão.'
          })
        }).then(() => {
          refetchData();
        });
      })
      .catch(() => {
        showError('Erro ao corrigir questão.');
      });
  };

  const [showGabarito, setShowGabarito] = useState(false);
  return (
    <Space direction="vertical" className="pb-[20px]">
      <Text strong>{`Questão ${index}`}</Text>
      <div
        style={{
          display: 'block',
          fontWeight: 600,
          marginBottom: 8
        }}
        className="[&>p]:m-0"
        dangerouslySetInnerHTML={{
          __html: question.statement
        }}
      />
      <Space direction="vertical" className="w-full">
        <Text>Resposta do aluno</Text>
        <Card
          classNames={{ body: '!p-1 min-h-[100px]' }}
          size="default"
          className="border border-[#d1d5db] bg-[#0000000a] text-[#9ca3af] whitespace-break-spaces"
        >
          <div
            style={{
              display: 'block'
            }}
            dangerouslySetInnerHTML={{
              __html: question.answer
            }}
          />
        </Card>
      </Space>
      {showGabarito && (
        <Space direction="vertical" className="w-full mt-2">
          <Text>Gabarito</Text>
          <Card
            size="default"
            classNames={{ body: '!p-1 min-h-[100px]' }}
            className="border border-[#d1d5db] bg-[#0000000a] text-[#9ca3af] whitespace-break-spaces"
          >
            <div
              style={{
                display: 'block'
              }}
              dangerouslySetInnerHTML={{
                __html: question?.feedback
              }}
            />
          </Card>
        </Space>
      )}
      <Space direction="vertical" className="mt-4 w-full">
        <Form
          form={form}
          initialValues={{
            evaluator_score: question.evaluator_score,
            evaluator_feedback: question.evaluator_feedback
          }}
          disabled={
            attemptStatus === 'pending_evaluation' ||
            Boolean(question.evaluator_feedback)
          }
          layout="vertical"
          className="flex flex-row gap-2 w-full"
        >
          <Form.Item
            label="Nota"
            name="evaluator_score"
            required
            rules={[{ required: true, message: 'O campo nota é obrigatório' }]}
          >
            <InputNumber
              type="number"
              placeholder="0-100"
              min={0}
              max={100}
              className="w-[165px]"
            />
          </Form.Item>
          <Form.Item
            label="Comentário do professor"
            name="evaluator_feedback"
            className="flex-grow"
          >
            <Input placeholder="Inserir comentário..." />
          </Form.Item>
        </Form>

        <Space className="justify-end gap-2 w-full">
          <Button type="text" onClick={() => setShowGabarito(!showGabarito)}>
            {`${showGabarito ? 'Esconder' : 'Visualizar'} gabarito`}
          </Button>
          {!Boolean(question.evaluator_feedback) && (
            <Button
              type="default"
              onClick={handleUpdateScore}
              loading={isLoading}
            >
              Salvar correção
            </Button>
          )}
        </Space>
      </Space>
    </Space>
  );
};
