import {
  Alert,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  Tag
} from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { Show } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate } from 'services/date';
import { getAPI } from '../../../requests/api';
import { useDelete, useNotification, useShow } from '@refinedev/core';
import { useState } from 'react';
import { videoPublish, videoUnpublish } from 'requests/phometheus';

const translateStatusVideo = (status: string) => {
  switch (status) {
    case 'published': {
      return 'Publicado';
    }

    case 'unpublished': {
      return 'Não publicado';
    }

    default: {
      return status;
    }
  }
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'created': {
      return 'grey';
    }

    case 'started': {
      return 'green';
    }

    case 'finished': {
      return 'grey';
    }

    case 'cancelled': {
      return 'red';
    }

    default: {
      return 'default';
    }
  }
};

const translateStatus = (status: string) => {
  switch (status) {
    case 'created': {
      return 'Criado';
    }

    case 'started': {
      return 'Iniciado';
    }

    case 'finished': {
      return 'Finalizado';
    }

    case 'cancelled': {
      return 'Cancelado';
    }

    default: {
      return status;
    }
  }
};

const extractVideoIds = (checkboxes: NodeListOf<Element>) => {
  const selectedIds = [];

  for (const checkbox of checkboxes) {
    const row = checkbox.closest('tr');

    if (row) {
      const videoId = row.dataset.rowKey;

      if (videoId) {
        selectedIds.push(videoId);
      }
    }
  }

  return selectedIds;
};

export const InteractiveClassShow = () => {
  const { queryResult } = useShow<IInteractiveClass>();
  const { mutateAsync: deleteInteractiveClass, isLoading: isDeleting } =
    useDelete<IInteractiveClass>();
  const notification = useNotification();
  const [loading, setLoading] = useState(false);
  const [hasSelectedVideos, setHasSelectedVideos] = useState(false);

  const result = queryResult.data?.data;

  if (!result) {
    return null;
  }

  const downloadReportFile = async () => {
    setLoading(true);

    try {
      const response = await getAPI().get(
        `/admin/interactive_classes/${result.id}/participants_report`,
        {
          responseType: 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio_participantes.csv');
      document.body.append(link);
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 404) {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Não há relatório de participantes disponível.'
        });
      } else {
        notification.open?.({
          type: 'error',
          message: 'Download Error',
          description: 'Erro ao baixar o relatório de participantes.'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    const checkedBoxes = document.querySelectorAll(
      'tbody input[type="checkbox"]:checked'
    );
    setHasSelectedVideos(checkedBoxes.length > 0);
  };

  return (
    <Show title="Visualizar aula" isLoading={queryResult.isLoading}>
      {!queryResult.isLoading && (
        <Tabs defaultActiveKey="general">
          <Tabs.TabPane tab="Informações Gerais" key="general">
            <Space direction="vertical" size="large" className="w-full">
              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData label="ID da aula" value={result?.id} />
                </Col>
              </Row>

              {result?.start_url && (
                <Row gutter={[16, 24]}>
                  <Col xs={24} md={24}>
                    <ShowFieldData
                      label="Link do professor"
                      value={
                        <>
                          <Alert
                            message="Por segurança este link é alterado frequentemente. Para evitar problemas de autenticação com o Zoom evite copiá-lo para usar depois, use-o somente para iniciar a aula."
                            type="warning"
                            showIcon
                            closable
                          />

                          <a
                            href={result?.start_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Clique aqui para iniciar a aula
                          </a>
                        </>
                      }
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData label="Título da aula" value={result?.title} />
                </Col>
              </Row>
              {result?.description && (
                <Row gutter={[16, 24]}>
                  <Col xs={24} md={24}>
                    <ShowFieldData
                      label="Descrição"
                      value={result?.description}
                    />
                  </Col>
                </Row>
              )}
              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData
                    label="Data"
                    value={formatDate(
                      result?.start_time,
                      'DD/MM/YYYY [às] HH:mm[h] ([GMT]Z)'
                    )}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData
                    label="Professores"
                    value={result?.professors
                      .map(
                        professor => `${professor.name} (${professor.email})`
                      )
                      .join('\n')}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData
                    label="Turmas"
                    value={result?.classrooms
                      .map(
                        classroom =>
                          `${classroom.discipline.name} - ${classroom.code}`
                      )
                      .join(', ')}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData
                    label="Status"
                    value={
                      <Tag color={getStatusColor(result?.status)}>
                        {translateStatus(result?.status)}
                      </Tag>
                    }
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col xs={24} md={24}>
                  <ShowFieldData
                    label="Controle de Frequência"
                    value={
                      <Button
                        icon={
                          loading ? <LoadingOutlined /> : <DownloadOutlined />
                        }
                        onClick={downloadReportFile}
                        disabled={result.status !== 'finished' || loading}
                      >
                        Baixar dados da aula realizada
                      </Button>
                    }
                  />
                </Col>
              </Row>
              {result?.status !== 'cancelled' && (
                <>
                  <Divider />

                  <Button
                    danger
                    onClick={() =>
                      deleteInteractiveClass({
                        id: result?.id,
                        resource: 'interactive_classes'
                      })
                    }
                    loading={isDeleting}
                  >
                    Cancelar aula
                  </Button>
                </>
              )}
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`Videos (${result.meeting.table.videos.length})`}
            key="videos"
          >
            <Row gutter={[16, 24]} style={{ marginBottom: 16 }}>
              <Col>
                <Button hidden={!loading} loading={loading}></Button>
              </Col>
              {hasSelectedVideos && (
                <>
                  <Col>
                    <Button
                      type="primary"
                      hidden={loading}
                      onClick={async () => {
                        setLoading(true);

                        try {
                          const checkboxes = document.querySelectorAll(
                            'input[type="checkbox"]:checked'
                          );
                          const selectedIds = extractVideoIds(checkboxes);

                          for (const id of selectedIds) {
                            await videoPublish(id);
                          }
                          await queryResult.refetch();
                        } catch {
                          notification.open?.({
                            type: 'error',
                            message: 'Erro',
                            description: 'Erro ao publicar vídeos'
                          });
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      Publicar selecionados
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      danger
                      hidden={loading}
                      onClick={async () => {
                        setLoading(true);

                        try {
                          const checkboxes = document.querySelectorAll(
                            'input[type="checkbox"]:checked'
                          );
                          const selectedIds = extractVideoIds(checkboxes);

                          for (const id of selectedIds) {
                            await videoUnpublish(id);
                          }
                          await queryResult.refetch();
                        } catch {
                          notification.open?.({
                            type: 'error',
                            message: 'Erro',
                            description: 'Erro ao despublicar vídeos'
                          });
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      Despublicar selecionados
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <Table
              dataSource={result.meeting.table.videos}
              rowKey="id"
              pagination={false}
              columns={[
                {
                  title: (
                    <input
                      type="checkbox"
                      onChange={e => {
                        const checkboxes = document.querySelectorAll(
                          'input[type="checkbox"]'
                        );

                        for (const checkbox of checkboxes) {
                          (checkbox as HTMLInputElement).checked =
                            e.target.checked;
                        }
                        handleCheckboxChange();
                      }}
                    />
                  ),
                  key: 'checkbox',
                  render: () => (
                    <input type="checkbox" onChange={handleCheckboxChange} />
                  )
                },
                {
                  title: 'Título',
                  dataIndex: 'title',
                  key: 'title'
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: status => (
                    <Tag color={status === 'published' ? 'green' : 'red'}>
                      {translateStatusVideo(status)}
                    </Tag>
                  )
                },
                {
                  title: 'Data de Criação',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  render: date => formatDate(date)
                },
                {
                  title: 'Ações',
                  key: 'actions',
                  render: (_, record) => (
                    <Space size="middle">
                      <Button type="link" href={record.id} target="_blank">
                        {record.preview_url ? (
                          <a
                            onClick={e => {
                              e.preventDefault();
                              Modal.info({
                                title: record.title,
                                content: (
                                  <div
                                    style={{ height: '500px', width: '100%' }}
                                  >
                                    <iframe
                                      src={record.preview_url}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none'
                                      }}
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                    />
                                  </div>
                                ),
                                width: 800,
                                okText: 'Fechar'
                              });
                            }}
                          >
                            Visualizar
                          </a>
                        ) : (
                          'Visualizar'
                        )}
                      </Button>
                    </Space>
                  )
                }
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Show>
  );
};
