import { Flex } from 'antd';
import { TabAssignmentsList } from './components/TabAssignmentsList';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';
import type { IProfessorAreaEvaluationsList } from 'interfaces/professor_area/evaluations';

export const ITEMS_PER_PAGE = 5;

export const TabAssignments = () => {
  const params = useParams();
  const classroomId = params.id;

  const { tableProps, setCurrent } = useTable<IProfessorAreaEvaluationsList>({
    resource: `professor_area/classrooms/${classroomId}/evaluations`,
    filters: {
      initial: [
        {
          field: 'filter[by_evaluation_type]',
          operator: 'eq',
          value: 'Assignment'
        }
      ]
    },
    pagination: {
      pageSize: ITEMS_PER_PAGE
    }
  });

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrent(page);
    },
    [setCurrent]
  );

  return (
    <Flex vertical>
      <TabAssignmentsList
        tableProps={tableProps}
        onChangePage={handleChangePage}
        refetch={() => setCurrent(1)}
      />
    </Flex>
  );
};
