import { Col, Divider, Form, Input, Radio, Row, Typography } from 'antd';
import { DateRangeFormField } from 'components/professor_area/DateRangeFormField';
import { FormItemCol } from 'components/professor_area/FormItemCol';

export const ModalCreateAssigmentStep3 = () => {
  const kind = Form.useWatch('kind');

  return (
    <>
      <Typography.Title level={4} className="!mb-8">
        Parâmetros
      </Typography.Title>

      <div>
        <Typography.Title level={5}>Período de acesso</Typography.Title>
        <Typography.Paragraph className="text-neutral-500">
          O período de acesso da avaliação deve está entre o inicio e fim da
          turma (dd/mm/aaaa - dd/mm/aaaa) - Horário de brasília
        </Typography.Paragraph>
      </div>

      <DateRangeFormField
        startRangeDatafield="starts_at"
        endRangeDatafield="ends_at"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório'
          }
        ]}
      />

      <Divider />

      <Form.Item>
        <Row gutter={[40, 16]}>
          <Col md={7}>Tipo de avaliação</Col>
          <Col md={17}>
            <Form.Item
              noStyle
              name="kind"
              initialValue="regular"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório'
                }
              ]}
            >
              <Radio.Group
                options={[
                  { label: 'Avaliação padrão', value: 'regular' },
                  {
                    label: 'Avaliação de recuperação',
                    value: 'final'
                  }
                ]}
                optionType="button"
                buttonStyle="outline"
                size="middle"
              />
            </Form.Item>
            <Typography.Text className="block text-xs mt-2 text-neutral-500">
              Avaliação de recuperação deve acontecer após o termino de todas as
              outras avaliações
            </Typography.Text>
          </Col>
        </Row>
      </Form.Item>

      <Divider />

      <FormItemCol
        cols={[7, 17]}
        name="weight"
        label="Peso"
        hidden={kind === 'final'}
        rules={[{ required: kind !== 'final', message: 'Campo obrigatorio' }]}
      >
        <Input type="number" size="large" className="max-w-[80px]" />
      </FormItemCol>

      <FormItemCol
        cols={[7, 17]}
        name="passing_score"
        label="Nota mínima (0-100)"
        rules={[
          { required: true, message: 'Campo obrigatorio' },
          {
            type: 'number',
            min: 0,
            max: 100,
            message: 'A nota deve estar entre 0 e 100'
          }
        ]}
      >
        <Input
          type="number"
          size="large"
          className="max-w-[80px]"
          min={0}
          max={100}
        />
      </FormItemCol>

      <FormItemCol
        cols={[7, 17]}
        name="max_attempts"
        label="Tentativas"
        rules={[{ required: true, message: 'Campo obrigatorio' }]}
      >
        <Input type="number" size="large" className="max-w-[80px]" />
      </FormItemCol>
    </>
  );
};
