import EvaluationsHeader from './EvaluationsHeader';
import EvaluationsReplacements from './EvaluationsReplacements';
import { Card, Divider, Row } from 'antd';
import { DeleteButton, EditButton } from '@refinedev/antd';
import { IClassroomEvaluation } from 'interfaces/classrooms';
import { ResponseError } from '../../interfaces/api-error';

interface EvaluationProps {
  evaluationData: IClassroomEvaluation;
  resource: string;
  evaluationEditModal: (evaluationId: string) => void;
  replacementCreateModal: (id: string) => void;
  replacementEditModal: (parentId: string, replacementId: string) => void;
  replacementAssociationModalShow: (replacementId: string) => void;
}

export const kindMap = {
  final: 'Avaliação de recuperação',
  replacement: 'Avaliação substitutiva',
  regular: 'Avaliação padrão'
};

const Evaluation = ({
  evaluationData,
  resource,
  evaluationEditModal,
  replacementCreateModal,
  replacementEditModal,
  replacementAssociationModalShow
}: EvaluationProps) => {
  return (
    <Card type="inner" className="w-full p-0" bodyStyle={{ padding: 0 }}>
      <div className="w-full flex items-center gap-2 bg-gray-100 border-b border-gray-200 px-4 py-2 w-64">
        <div className="text-slate-700 text-base font-normal">
          {evaluationData.name}
        </div>
        <div className="flex items-center gap-1">
          <div className="text-gray-500 text-sm font-normal">
            {kindMap[evaluationData.kind]}
          </div>
        </div>
      </div>
      <Row
        gutter={16}
        style={{ margin: 0 }}
        className="flex items-center gap-4 relative p-4"
      >
        <EvaluationsHeader evaluationData={evaluationData} />
        <DeleteButton
          className="self-end"
          style={{
            position: 'absolute',
            right: 40,
            color: '#626262',
            borderColor: '#d9d9d9',
            alignSelf: 'flex-end'
          }}
          hideText
          size="small"
          resource={`${resource}`}
          recordItemId={evaluationData.id}
          errorNotification={error => {
            const err = error as unknown as ResponseError;

            const errorMessage = err?.message;

            if (errorMessage === 'Evaluation with Attempts cannot be deleted') {
              return {
                type: 'error',
                description: 'Não foi possível excluir a avaliação',
                message:
                  'Não é possível excluir avaliações com tentativas realizadas.'
              };
            }
            return {
              type: 'error',
              description: 'Ops!',
              message: `Erro ao excluir: ${errorMessage || 'Erro desconhecido'}`
            };
          }}
        />
        <EditButton
          style={{ position: 'absolute', right: 8, bottom: 16 }}
          hideText
          size="small"
          recordItemId={evaluationData.id}
          onClick={e => {
            e.stopPropagation();
            evaluationEditModal(evaluationData.id);
          }}
        />
      </Row>
      <Divider style={{ margin: 0 }} />
      <EvaluationsReplacements
        evaluationData={evaluationData}
        resource={resource}
        replacementCreateModal={replacementCreateModal}
        replacementEditModal={replacementEditModal}
        replacementAssociationModalShow={replacementAssociationModalShow}
      />
    </Card>
  );
};

export default Evaluation;
