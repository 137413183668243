import FilterForm from '../../../components/forms/FilterForm';
import {
  ATTEMPTS_STATUS_MAP,
  translateAssignmentAttemptStatus
} from '../../../services/assignments';
import { Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { CrudFilters, HttpError } from '@refinedev/core';
import { IAssignmentAttempt } from '../../../interfaces/assignments';
import { ICourse } from 'interfaces/courses';
import {
  List,
  ShowButton,
  TagField,
  TextField,
  useSelect,
  useTable
} from '@refinedev/antd';
import { formatDate } from '../../../services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';

interface IFilter {
  course_id: string;
  status: string;
  assignment_name: string;
}

export const AssignmentAttemptList = () => {
  const { tableProps, searchFormProps } = useTable<
    IAssignmentAttempt,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { course_id, status, assignment_name } = params;

      filters.push(
        {
          field: 'filter[course]',
          operator: 'eq',
          value: course_id
        },
        {
          field: 'filter[status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[search_name]',
          operator: 'eq',
          value: assignment_name
        }
      );

      return filters;
    }
  });

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <List>
          <FilterForm searchFormProps={searchFormProps}>
            <Form.Item label="Nome da atividade" name="assignment_name">
              <Input
                placeholder="Digite o nome da atividade..."
                style={{ minWidth: 200 }}
              />
            </Form.Item>
            <Form.Item label="Curso" name="course_id">
              <Select
                placeholder="Digite..."
                style={{ minWidth: 400 }}
                {...courseSelectProps}
              />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select placeholder="Selecione..." style={{ minWidth: 200 }}>
                {[...ATTEMPTS_STATUS_MAP].map(item => (
                  <Select.Option key={item[0]}>{item[1]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FilterForm>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="assignment_name"
              key="assignment_name"
              title="Nome da tarefa"
              render={value => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="course_name"
              key="course_name"
              title="Nome do curso"
              render={value => <TextField value={value} />}
            />

            <Table.Column
              dataIndex="created_at"
              key="created_at"
              title="Iniciada em"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />
            <Table.Column
              dataIndex="evaluated_at"
              key="evaluated_at"
              title="Avaliada em"
              render={value =>
                value ? (
                  <TextField value={formatDate(value, 'DD/MM/YYYY')} />
                ) : (
                  '--'
                )
              }
            />
            <Table.Column
              dataIndex="evaluator"
              key="evaluator"
              title="Avaliada por"
              render={value =>
                value ? <TextField value={value.name} /> : '--'
              }
            />
            <Table.Column
              dataIndex="score"
              key="score"
              title="Nota"
              render={value => <TextField value={value ?? '--'} />}
            />
            <Table.Column
              dataIndex="status"
              key="status"
              title="Status"
              render={value => (
                <TagField
                  color={tagColorByStatus(value)}
                  value={translateAssignmentAttemptStatus(value)}
                />
              )}
            />
            <Table.Column<IAssignmentAttempt>
              title="Ações"
              dataIndex="actions"
              align="center"
              render={(_, record) => {
                return (
                  <Space>
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
