import React from 'react';
import useNotification from 'hooks/useNotification';
import {
  EditClassroomForm,
  IClassroomPost
} from 'components/classroom/EditClassroomForm';
import { Modal } from 'antd';
import { useModalForm } from '@refinedev/antd';
import type { HttpError } from '@refinedev/core';
import type { ResponseError } from 'interfaces/api-error';

interface CreateClassroomModalProps {
  discipline?: {
    id: string;
    name: string;
  };
  open: boolean;
  onClose: (close: boolean) => void;
}

export const CreateClassroomModal = ({
  discipline,
  open,
  onClose
}: CreateClassroomModalProps) => {
  const { showError, showSuccess } = useNotification();
  const { modalProps: createModalProps, formProps: createFormProps } =
    useModalForm<IClassroomPost, HttpError, IClassroomPost>({
      action: 'create',
      resource: 'classrooms',
      redirect: false,
      successNotification: false,
      errorNotification: false,
      onMutationSuccess: () => {
        createFormProps.form?.resetFields();
        showSuccess('Turma cadastrada com sucesso');
        onClose(false);
      },
      onMutationError: error => {
        const err = error as unknown as ResponseError;

        let message = 'Não foi possivel cadastrar a turma. Tente novamente.';

        if (err?.code === 'record_invalid') {
          message = 'Código da turma duplicado. Favor alterar';
        }

        showError(message);
      }
    });

  return (
    <Modal
      {...createModalProps}
      open={open}
      onClose={() => onClose(false)}
      onCancel={() => onClose(false)}
      title="Nova turma"
      okText="Criar nova turma"
      cancelText="Cancelar"
    >
      <EditClassroomForm formProps={createFormProps} discipline={discipline} />
    </Modal>
  );
};
