import { Button, Card, Col, Form, Input, Radio, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Fragment } from 'react';

interface FormItemAssignmentCriteriaProps {
  fieldName?: string | string[];
  disabled?: boolean;
}

export const FormItemAssignmentCriteria = ({
  fieldName,
  disabled
}: FormItemAssignmentCriteriaProps) => {
  return (
    <Form.List
      name={fieldName || 'criteria'}
      initialValue={[
        {
          name: '',
          answer_type: 'text'
        }
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Fragment key={'criteria_' + key}>
              <Row gutter={[16, 16]} className="mb-4 mt-4 first:mt-0">
                <Col md={22}>
                  <Card className="bg-neutral-100">
                    <Form.Item
                      {...restField}
                      label="Enunciado"
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Informe o enunciado'
                        }
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Escreva um enunciado para a questão ou tarefa"
                        rows={3}
                        disabled={disabled}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Tipo de resposta"
                      name={[name, 'answer_type']}
                      initialValue="text"
                    >
                      <Radio.Group
                        options={[
                          {
                            label: 'Texto',
                            value: 'text'
                          },
                          {
                            label: 'Arquivo PDF',
                            value: 'file'
                          }
                        ]}
                        optionType="button"
                        buttonStyle="outline"
                        size="middle"
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Card>
                </Col>

                {fields.length > 1 && !disabled && (
                  <Col md={2}>
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => remove(name)}
                    />
                  </Col>
                )}
              </Row>
            </Fragment>
          ))}

          <Form.ErrorList errors={errors} />

          {!disabled && (
            <Button
              type="dashed"
              color="primary"
              icon={<PlusOutlined />}
              onClick={() => add()}
              className="!block mx-auto mb-4"
            >
              Adicionar critério
            </Button>
          )}
        </>
      )}
    </Form.List>
  );
};
