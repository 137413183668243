import { Divider, Form, Input, Radio, Select, Typography } from 'antd';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelect } from '@refinedev/antd';

export const ModalCreateAssigmentStep1 = () => {
  const params = useParams();
  const classroomId = params.id;
  const form = Form.useFormInstance();

  const type = Form.useWatch('type');

  const { selectProps } = useSelect({
    resource: `professor_area/classrooms/${classroomId}/assignments`,
    optionLabel: 'name'
  });

  const onChangeType = useCallback(
    (type: 'list' | 'new') => {
      return type === 'new'
        ? form.setFieldValue('evaluation_id', null)
        : form.setFieldValue('description', '');
    },
    [form]
  );

  return (
    <>
      <Typography.Title level={4} className="!mb-8">
        Selecione a atividade
      </Typography.Title>

      <Form.Item
        label="Atividade"
        name="type"
        initialValue="list"
        rules={[
          {
            required: true,
            message: 'Por favor, selecione o tipo de atividade'
          }
        ]}
      >
        <Radio.Group
          options={[
            { label: 'Banco de atividades', value: 'list' },
            { label: 'Nova atividade', value: 'new' }
          ]}
          optionType="button"
          buttonStyle="outline"
          size="middle"
          onChange={e => onChangeType(e.target.value)}
        />
      </Form.Item>

      <Divider />

      {type === 'list' && (
        <Form.Item
          label="Modelo de atividade"
          name="evaluation_id"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione o modelo de atividade'
            }
          ]}
        >
          <Select
            {...selectProps}
            size="large"
            placeholder="Selecione o modelo"
            allowClear
          />
        </Form.Item>
      )}

      <Form.Item
        label="Nome da atividade"
        name="name"
        rules={[
          {
            required: true,
            message: 'Por favor, insira o nome da atividade'
          },
          { min: 3, message: 'O nome deve ter pelo menos 3 caracteres' }
        ]}
      >
        <Input size="large" placeholder="Digite o nome da atividade" />
      </Form.Item>

      {type === 'new' && (
        <Form.Item label="Descrição" name="description">
          <Input.TextArea
            size="large"
            rows={3}
            placeholder="Digite uma descrição"
          />
        </Form.Item>
      )}
    </>
  );
};
