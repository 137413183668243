import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';
import { IProfessorAreaAssessmentShow } from 'interfaces/professor_area/assessments';
import { TabAttempts } from './TabAttempts/TabAttempts';
import { TabGeneral } from './TabGeneral/TabGeneral';
import { TabQuestions } from './TabQuestions/TabQuestions';
import { useCustomSearchParams } from 'hooks/useSearchParams';

export const EvaluationsTabList = ({
  evaluation
}: {
  evaluation: IProfessorAreaAssessmentShow;
}) => {
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({
      searchParams: 'tab'
    });

  const [activeTabKey, setActiveTabKey] = useState<string>(
    currentTab || 'general'
  );

  const tabContent: Record<string, React.ReactNode> | null = useMemo(() => {
    if (!evaluation) {
      return null;
    }

    return {
      general: <TabGeneral evaluation={evaluation} />,
      questions: <TabQuestions evaluation={evaluation} />,
      attempts: <TabAttempts evaluation={evaluation} />
    };
  }, [evaluation]);

  useEffect(() => {
    if (currentTab) {
      setActiveTabKey(currentTab);
    }
  }, [currentTab]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTabKey(tab);
      setSearchParams(tab);
    },
    [setSearchParams]
  );

  return (
    <Card
      tabList={[
        {
          key: 'general',
          label: 'Visão geral'
        },
        {
          key: 'questions',
          label: 'Questões'
        },
        {
          key: 'attempts',
          label: 'Avaliações realizadas'
        }
      ]}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      prefixCls="custom-card"
    >
      {tabContent![activeTabKey]}
    </Card>
  );
};
