import { CardItem } from 'components/professor_area/CardItem';
import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilePdfOutlined
} from '@ant-design/icons';
import { ISupplementaryMaterial } from 'interfaces/supplementary_materials';
import { MenuProps } from 'antd/lib';
import { Modal, Typography } from 'antd';
import { SupplementaryMaterialItem } from 'components/forms/SupplementaryMaterialForm';
import { download_url as downloadSupplementaryMaterials } from 'requests/supplementary-materials';
import { formatDate } from 'services/date';
import { useCallback } from 'react';
import { useDelete } from '@refinedev/core';

const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar arquivo',
    icon: <EyeOutlined />
  },
  {
    key: 'download',
    label: 'Baixar arquivo',
    icon: <DownloadOutlined />
  },
  {
    key: 'delete',
    label: 'Excluir arquivo',
    icon: <DeleteOutlined />
  }
];

export interface TableRowItemProps {
  file: SupplementaryMaterialItem;
  getSavedItems: () => void;
  previewModalShow: (fileUrl: string) => void;
}

export const TableRowItem = ({
  file,
  getSavedItems,
  previewModalShow
}: TableRowItemProps) => {
  const { mutateAsync: deleteSupplementaryMaterial } =
    useDelete<ISupplementaryMaterial>();

  const handleDownload = useCallback((id: string) => {
    downloadSupplementaryMaterials(id).then(res => window.open(res.url));
  }, []);

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteSupplementaryMaterial({
        resource: 'supplementary_materials',
        id,
        successNotification: () => ({
          type: 'success',
          description: 'Tudo certo!',
          message: 'Material suplementar excluído com sucesso!'
        }),
        errorNotification: () => {
          return {
            description: 'Algo deu errado',
            message: 'Não foi possível excluído o material suplementar',
            type: 'error'
          };
        }
      });
      getSavedItems();
    },
    [deleteSupplementaryMaterial, getSavedItems]
  );

  const showConfirm = useCallback(() => {
    confirm({
      title: 'Tem certeza que deseja excluir este material?',
      icon: <ExclamationCircleOutlined className="!text-red-500" />,
      content: 'Esta ação não poderá ser desfeita!',
      okButtonProps: { type: 'primary' },
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okType: 'danger',
      onOk() {
        handleDelete(file.id!);
      }
    });
  }, [file.id, handleDelete]);

  const handleMenuClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      switch (event.key) {
        case 'show': {
          previewModalShow(file.preview_url);
          break;
        }

        case 'download': {
          handleDownload(file.id!);
          break;
        }

        case 'delete': {
          showConfirm();
          break;
        }

        default: {
          break;
        }
      }
    },
    [file.id, file.preview_url, handleDownload, previewModalShow, showConfirm]
  );

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <CardItem
      icon={FilePdfOutlined}
      title={file.name}
      meta={
        <Typography.Text className="!m-0">
          {formatDate(file.created_at, 'DD/MM/YYYY')}
        </Typography.Text>
      }
      menuProps={menuProps}
    />
  );
};
