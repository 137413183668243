import './styles.less';
import React from 'react';
import { Divider, Space, Typography } from 'antd';
import { IProfessorAreaQuestionShow } from 'interfaces/professor_area/assessments';
import { TagField } from '@refinedev/antd';
import { evaluationsQuestionsStatus } from 'constants/admin/evaluations';
import { getStatusData } from 'services/professor_area/evaluations';

const { Text } = Typography;

export const DiscursiveQuestion: React.FC<{
  questionData?: IProfessorAreaQuestionShow;
}> = ({ questionData }) => {
  if (!questionData) {
    return null;
  }

  return (
    <Space direction="vertical" className="w-full">
      <Space className="flex justify-between">
        <Text type="secondary" className="font-bold">
          Enunciado
        </Text>
        <Space className="gap-0">
          <TagField value="Discursiva" />
          <TagField
            color={
              getStatusData(questionData.status, evaluationsQuestionsStatus)
                ?.color
            }
            value={
              getStatusData(questionData.status, evaluationsQuestionsStatus)
                ?.label
            }
          />
        </Space>
      </Space>
      <div
        style={{
          display: 'block',
          fontWeight: 600,
          marginBottom: 8
        }}
        className="dangerousText"
        dangerouslySetInnerHTML={{
          __html: questionData.statement
        }}
      />
      <Divider />
      <Text type="secondary" className="font-bold">
        Gabarito
      </Text>
      <div
        className="dangerousText"
        dangerouslySetInnerHTML={{
          __html: questionData.feedback
        }}
      />
    </Space>
  );
};
