import { Col, Divider, Progress, Row, Space, Tooltip, Typography } from 'antd';
import { ICourseEnrollment } from 'interfaces/course_enrollments';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate } from 'services/date';

interface SummaryProps {
  enrollment: ICourseEnrollment;
}

export const TabSummary = ({ enrollment }: SummaryProps) => {
  return (
    <Space direction="vertical" size="small" className="w-full">
      <Typography.Title level={4}>Aluno</Typography.Title>
      <Row>
        <Col xs={24} md={8}>
          <ShowFieldData label="Nome" value={enrollment.user.name} />
        </Col>
        <Col xs={24} md={8}>
          <ShowFieldData label="Email" value={enrollment.user.email} />
        </Col>
        <Col xs={24} md={8}>
          <ShowFieldData
            label="Telefone"
            value={enrollment.user?.personal_info?.phone_number}
          />
        </Col>
      </Row>

      <Divider />

      <Typography.Title level={4}>Matrícula</Typography.Title>
      <Row>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Número de matrícula"
            value={enrollment.registration}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Data de início"
            value={formatDate(enrollment.created_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Data de expiração"
            value={formatDate(enrollment.expires_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={5}>
          <ShowFieldData
            label="Curso concluído em"
            value={formatDate(enrollment.expires_at, 'DD/MM/YYYY')}
          />
        </Col>
        <Col xs={24} md={4}>
          <ShowFieldData
            label="Progresso"
            value={
              <Tooltip title={`${enrollment.progress_percentage}%`}>
                <Progress
                  size="small"
                  showInfo={false}
                  percent={enrollment.progress_percentage}
                />
              </Tooltip>
            }
          />
        </Col>
      </Row>
    </Space>
  );
};
