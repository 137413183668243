import { Button, Col, Form, Input, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export interface FilterNoticeBoard {
  by_title: string;
  by_priority?: 'regular' | 'hight';
}
interface NoticeBoardFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSearch: (formData: FilterNoticeBoard) => void;
}

export const NoticeBoardFilter = ({
  isLoading,
  onClear,
  onSearch
}: NoticeBoardFilterProps) => {
  const [form] = Form.useForm();

  const handleClear = () => {
    form.resetFields();
    onClear();
  };

  return (
    <Form
      form={form}
      layout="inline"
      onFinish={onSearch}
      className="mb-8 w-full"
    >
      <Row gutter={[0, 16]} className="w-full">
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="by_title">
            <Input
              placeholder="Buscar pelo título do aviso..."
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="by_priority">
            <Select placeholder="Todos as prioridades" allowClear>
              <Select.Option key="regular">Padrão</Select.Option>
              <Select.Option key="high">Alta</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Button
            type="default"
            className="mr-2"
            htmlType="submit"
            loading={isLoading}
          >
            Filtrar
          </Button>

          <Button type="text" onClick={handleClear}>
            Limpar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
