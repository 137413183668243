import React from 'react';
import { DateRangeFormField } from 'components/professor_area/DateRangeFormField';
import { Divider, Form, Input, InputNumber, Radio } from 'antd';
import { FormItem } from 'components/modal/professor_area/classroom/FormItem';
import {
  IProfessorAreaAssessmentShow,
  IRequirementRule
} from 'interfaces/professor_area/assessments';
import { parseDate } from 'services/date';

export const TabGeneral: React.FC<{
  evaluation: IProfessorAreaAssessmentShow;
}> = ({ evaluation }) => {
  const injectDateRange = () => {
    const startDate = parseDate(
      evaluation.requirements?.[0]?.rules.find(
        (rules: IRequirementRule) => rules.operator === 'after'
      )?.value
    );
    const endDate = parseDate(
      evaluation.requirements?.[0]?.rules.find(
        (rules: IRequirementRule) => rules.operator === 'before'
      )?.value
    );

    return {
      startDate,
      endDate,
      ...evaluation
    };
  };

  return (
    <div className="p-6">
      <Form disabled initialValues={injectDateRange()}>
        <FormItem
          name="name"
          label="Nome"
          labelCol={{ className: 'w-[150px]' }}
        >
          <Input />
        </FormItem>
        <FormItem
          name="evaluation_name"
          label="Modelo de avaliação"
          labelCol={{ className: 'w-[150px]' }}
        >
          <Input />
        </FormItem>
        <FormItem
          name="evaluation_kind"
          label="Modelo de avaliação"
          labelCol={{ className: 'w-[150px]' }}
        >
          <Radio.Group
            options={[
              { value: 'objective', label: 'Objetiva' },
              { value: 'discursive', label: 'Discursiva' }
            ]}
            defaultValue="assignment"
            optionType="button"
          />
        </FormItem>
        <FormItem
          name={['evaluation', 'question_amount']}
          label="Qtde de questões"
          labelCol={{ className: 'w-[150px]' }}
        >
          <InputNumber />
        </FormItem>
        <FormItem
          name={['evaluation', 'time_in_seconds']}
          label="Duração"
          labelCol={{ className: 'w-[150px]' }}
          className="w-[320px]"
        >
          <Input addonAfter={'minutos'} />
        </FormItem>
        <Divider />
        <FormItem
          name="kind"
          label="Modalidade"
          labelCol={{ className: 'w-[150px]' }}
        >
          <Radio.Group
            options={[
              { value: 'regular', label: 'Avaliação padrão' },
              { value: 'replacement', label: 'Avaliação de recuperação' }
            ]}
            defaultValue="assignment"
            optionType="button"
          />
        </FormItem>
        <FormItem
          name="weight"
          label="Peso"
          labelCol={{ className: 'w-[150px]' }}
        >
          <InputNumber />
        </FormItem>
        <FormItem
          name="passing_score"
          label="Nota mínima (0-100)"
          labelCol={{ className: 'w-[150px]' }}
        >
          <InputNumber />
        </FormItem>
        <FormItem
          name="max_attempts"
          label="Tentativas"
          labelCol={{ className: 'w-[150px]' }}
        >
          <InputNumber />
        </FormItem>
        <Divider />
        <DateRangeFormField
          title="Período de acesso"
          subtitle="O período de acesso à avaliação deve estar dentro do intervalo de início
                e término da turma"
          startRangeDatafield="startDate"
          endRangeDatafield="endDate"
          rules={[{ required: true, message: 'A data é obrigatória' }]}
        />
      </Form>
    </div>
  );
};
