import ClassroomManagers from './components/ClassroomManagers';
import ClassroomProfessor from './components/ClassroomProfessor';
import ClassroomSupplementarMaterial from './components/ClassroomSupplementarMaterial';
import useNotification from 'hooks/useNotification';
import { Button, Form, Tabs } from 'antd';
import { ClassroomContent } from './components/ClassroomContent';
import { ClassroomEnrollments } from './components/ClassroomEnrollments';
import { ClassroomEvaluations } from './components/ClassroomEvaluations';
import { ClassroomForum } from './components/Forum/ClassroomForum';
import { ClassroomStudentsAvailableForEnrollment } from './components/ClassroomStudentsAvailableForEnrollment';
import { CreateTopicModal } from 'components/modal/forum/CreateTopicModal';
import { Edit, useForm, useModal } from '@refinedev/antd';
import {
  EditClassroomForm,
  IClassroomPost
} from 'components/classroom/EditClassroomForm';
import { ITree } from 'interfaces/tree';
import { PlusSquareOutlined } from '@ant-design/icons';
import { getTenant } from 'services/tenants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomSearchParams } from 'hooks/useSearchParams';
import type { HttpError } from '@refinedev/core';

export const ClassroomEdit = () => {
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({
      searchParams: 'tab'
    });
  const [activeTab, setActiveTab] = useState(currentTab || 'info');
  const [contentTree, setContentTree] = useState<ITree>();
  const { showError, showSuccess } = useNotification();
  const tenant = getTenant();

  const {
    show: topicCreateModalShow,
    close: topicCreateModalClose,
    modalProps: topicCreateModalProps
  } = useModal();

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setSearchParams(tab);
    },
    [setSearchParams]
  );

  const { formProps, saveButtonProps, queryResult, onFinish } = useForm<
    IClassroomPost,
    HttpError,
    IClassroomPost
  >({
    redirect: 'edit',
    successNotification: false,
    errorNotification: false,
    onMutationSuccess: () => {
      showSuccess('Turma editada com sucesso');
    },
    onMutationError: () => {
      showError('Não foi possível editar a turma. Tente novamente!');
    }
  });

  const [contentForm] = Form.useForm<IClassroomPost>();

  const classroom = queryResult?.data?.data;

  const handleOnFinish = useCallback(
    (values: IClassroomPost) => {
      const parsedValues: IClassroomPost = {
        ...values,
        content_tree: contentTree,
        minimum_attendance: values.minimum_attendance ?? null
      };

      onFinish(parsedValues);
    },
    [contentTree, onFinish]
  );

  const handleContentChange = useCallback(
    (data: ITree) => {
      setContentTree(data);
      queryResult?.refetch();
    },
    [queryResult]
  );

  const footerButtonStyle = useMemo(
    () => ({
      marginRight: 8,
      justifyContent: 'flex-end',
      display: ['info', 'content'].includes(activeTab) ? 'flex' : 'none'
    }),
    [activeTab]
  );

  const renderTabContent = useCallback(
    (key: string) => {
      if (!classroom) {
        return null;
      }

      switch (key) {
        case 'info': {
          return (
            <EditClassroomForm
              edit
              formProps={{
                ...formProps,
                onFinish: handleOnFinish
              }}
              discipline={{
                id: classroom.discipline.id,
                name: classroom.discipline.name
              }}
            />
          );
        }

        case 'content': {
          return (
            <Form
              {...formProps}
              form={contentForm}
              onFinish={handleOnFinish}
              layout="vertical"
            >
              <ClassroomContent
                classroom={classroom}
                onChange={handleContentChange}
              />
            </Form>
          );
        }

        case 'evaluations': {
          return (
            <ClassroomEvaluations
              disciplineId={classroom.discipline.id}
              classroomId={classroom.id}
            />
          );
        }

        case 'enrollments': {
          return (
            <ClassroomEnrollments
              classroomId={classroom.id}
              classroom={classroom}
            />
          );
        }

        case 'available': {
          return (
            <ClassroomStudentsAvailableForEnrollment
              classroomId={classroom.id}
            />
          );
        }

        case 'professor': {
          return <ClassroomProfessor classroom={classroom} />;
        }

        case 'managers': {
          return <ClassroomManagers classroom={classroom} />;
        }

        case 'supplementar_material': {
          return <ClassroomSupplementarMaterial classroom={classroom} />;
        }

        case 'forum': {
          return !tenant.disabledFeatures?.includes('forums') ? (
            <>
              <Button
                icon={<PlusSquareOutlined />}
                type="default"
                onClick={topicCreateModalShow}
                style={{ marginBottom: 16 }}
              >
                Novo Tópico
              </Button>
              <ClassroomForum />
            </>
          ) : null;
        }

        default: {
          return null;
        }
      }
    },
    [
      classroom,
      formProps,
      contentForm,
      handleOnFinish,
      handleContentChange,
      tenant.disabledFeatures,
      topicCreateModalShow
    ]
  );

  return (
    <Edit
      title="Editar turma"
      footerButtonProps={{
        style: footerButtonStyle
      }}
      saveButtonProps={saveButtonProps}
    >
      <Tabs activeKey={activeTab} onChange={onTabChange}>
        <Tabs.TabPane tab="Informações gerais" key="info">
          {renderTabContent('info')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Conteúdo" key="content">
          {renderTabContent('content')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Avaliações" key="evaluations">
          {renderTabContent('evaluations')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Alunos matriculados" key="enrollments">
          {renderTabContent('enrollments')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Alunos disponíveis para matrícula" key="available">
          {renderTabContent('available')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Professor responsável" key="professor">
          {renderTabContent('professor')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Corpo docente" key="managers">
          {renderTabContent('managers')}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Material complementar" key="supplementar_material">
          {renderTabContent('supplementar_material')}
        </Tabs.TabPane>

        {!tenant.disabledFeatures?.includes('forums') && (
          <Tabs.TabPane tab="Fóruns" key="forum">
            {renderTabContent('forum')}
          </Tabs.TabPane>
        )}
      </Tabs>
      <CreateTopicModal
        kind="Classroom"
        parent={classroom}
        modalProps={topicCreateModalProps}
        modalClose={topicCreateModalClose}
      />
    </Edit>
  );
};
