import ContentUpload from 'components/ContentUpload';
import { Button, Card, Col, Flex, Form, Input, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Fragment, useCallback, useMemo } from 'react';
import type { IAssignment } from 'interfaces/assignments';

interface FormItemAssignmentInstructionProps {
  fieldName?: string | string[];
  disabled?: boolean;
}

export const FormItemAssignmentInstruction = ({
  fieldName = 'instructions',
  disabled
}: FormItemAssignmentInstructionProps) => {
  const form = Form.useFormInstance();
  const field = useMemo(
    () => (Array.isArray(fieldName) ? fieldName : [fieldName]),
    [fieldName]
  );

  const onUploadFile = useCallback(
    (key: number, url: string, download_url: string) => {
      const fields = form.getFieldsValue();
      const { instructions } = fields as IAssignment;

      const name = instructions[key].name;

      Object.assign(instructions[key], {
        name,
        url,
        download_url
      });

      form.setFieldsValue({ instructions });
    },
    [form]
  );

  return (
    <Form.List name={field}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => {
            return (
              <Fragment key={'instruction_' + key}>
                <Row gutter={[16, 16]} className="mb-4 mt-4 first:mt-0">
                  <Col md={22}>
                    <Card className="bg-neutral-100">
                      <Form.Item
                        label="Nome do documento"
                        className="w-full mb-0"
                      >
                        <Flex gap={8} className="w-full">
                          <Form.Item
                            {...restField}
                            noStyle
                            name={[name, 'name']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Informe o nome do documento'
                              }
                            ]}
                          >
                            <Input
                              className="flex-1 w-full"
                              disabled={disabled}
                            />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.instructions?.[name]?.name !==
                              curValues.instructions?.[name]?.name
                            }
                          >
                            {() => {
                              const inputName = form.getFieldValue([
                                ...field,
                                name,
                                'name'
                              ]);

                              const src = form.getFieldValue([
                                ...field,
                                name,
                                'download_url'
                              ]);

                              return (
                                <ContentUpload
                                  type="assignment_instruction"
                                  accept="application/pdf"
                                  src={src}
                                  hideUpload={disabled}
                                  disabled={
                                    !inputName || inputName.trim() === ''
                                  }
                                  onUpload={(url, downloadUrl) =>
                                    onUploadFile(key, url, downloadUrl!)
                                  }
                                />
                              );
                            }}
                          </Form.Item>
                        </Flex>
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'url']}
                        validateTrigger={['onChange', 'onBlur']}
                        className="min-h-0 [&_.ant-form-item-control-input]:min-h-0"
                        rules={[
                          {
                            required: true,
                            message: 'Informe ao menos um documento'
                          }
                        ]}
                      >
                        <Input className="hidden h-0 p-0 min-h-0" />
                      </Form.Item>
                    </Card>
                  </Col>

                  <Col md={2}>
                    {!disabled && (
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => remove(name)}
                      />
                    )}
                  </Col>
                </Row>
              </Fragment>
            );
          })}

          {!disabled && (
            <Button
              type="dashed"
              color="primary"
              icon={<PlusOutlined />}
              onClick={() => add()}
              className="!block mx-auto"
            >
              Adicionar instrução
            </Button>
          )}
        </>
      )}
    </Form.List>
  );
};
