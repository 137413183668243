import * as Icons from '@ant-design/icons';
import { Button, Flex, Space, Table, Typography } from 'antd';
import { ClassroomTable } from '../../../classrooms/components/ClassroomTable';
import { CreateClassroomModal } from 'components/modal/classroom/CreateClassroomModal';
import { EditButton, useTable } from '@refinedev/antd';
import { IClassroom } from '../../../../interfaces/classrooms';
import { useCan } from '@refinedev/core';
import { useGraduateDisciplineStore } from '../graduate_disciplines.store';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export const TabClassrooms = () => {
  const { id: disciplineId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const discipline = useGraduateDisciplineStore(s => s.discipline);
  const { data: canCreate } = useCan({
    resource: 'classrooms',
    action: 'create'
  });

  const { tableProps } = useTable<IClassroom>({
    resource: 'classrooms',
    filters: {
      initial: [
        {
          field: 'filter[by_discipline_id]',
          operator: 'eq',
          value: disciplineId
        }
      ]
    }
  });

  return (
    <>
      <Flex align="center" justify="space-between" className="mb-4">
        <Typography.Title level={5} style={{ margin: 0, color: 'GrayText' }}>
          Turmas da disciplina
        </Typography.Title>

        {canCreate && (
          <Button
            icon={<Icons.PlusOutlined />}
            onClick={() => setIsModalOpen(true)}
          >
            Nova turma
          </Button>
        )}
      </Flex>

      <ClassroomTable tableProps={tableProps}>
        <Table.Column<IClassroom>
          title="Ações"
          align="right"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  resource="classrooms"
                />
              </Space>
            );
          }}
        />
      </ClassroomTable>

      <CreateClassroomModal
        open={isModalOpen}
        onClose={setIsModalOpen}
        discipline={{
          id: disciplineId || '',
          name: discipline?.name || ''
        }}
      />
    </>
  );
};
