import { AttemptsCorrectionShow } from 'pages/teacher_space/classrooms/components/TabEvaluations/Evaluation/components/TabAttempts/Attempts/AttemptsCorrection/AttempsCorrectionShow';
import { AttemptsShow } from 'pages/teacher_space/classrooms/components/TabEvaluations/Evaluation/components/TabAttempts/Attempts/AttemptsShow';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'professor_area/classroom_assessments',
  label: 'Atividade do aluno',
  show: AttemptsShow,
  edit: AttemptsCorrectionShow,
  actions: ['show', 'edit'],
  parentName: 'professor_area/classroom_assessments',
  route:
    'professor_area/classrooms/:classroomId/assessments/:evaluationId/attempt'
};

export default generateResources(resource);
