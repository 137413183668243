import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
  notification
} from 'antd';
import { Edit, SaveButton, useForm } from '@refinedev/antd';
import { PaymentMethodsType } from '../../../interfaces/payment_methods-rules';
import {
  defaultEditConfig,
  defaultSwitchStateOnEdit,
  filterActiveConfig,
  getActiveMethods,
  renderSummary
} from '../../../services/payment-method-rules';
import { useEffect, useState } from 'react';

export const PaymentMethodRulesEdit = () => {
  const { formProps, onFinish, queryResult } = useForm({
    action: 'edit',
    onMutationError: () => null,
    errorNotification: () => {
      return {
        message: 'erro ao tentar editar regra de parcelamento',
        description: 'Algo deu errado',
        type: 'error'
      };
    },
    successNotification: () => {
      return {
        message: 'Regra de parcelamento editada com sucesso',
        description: 'Regra atualizada',
        type: 'success'
      };
    }
  });

  const currentConfig = queryResult?.data?.data?.config || {};

  useEffect(() => {
    const config = queryResult?.data?.data?.config;
    if (config) {
      setConfig(defaultEditConfig(config));
      setMethods(defaultSwitchStateOnEdit(config));
    }
  }, [queryResult?.data?.data?.config]);

  const onSubmit = (data: Record<string, unknown>) => {
    const activeMethods = getActiveMethods(methods);
    const values = {
      name: data?.name,
      config: filterActiveConfig(activeMethods, config)
    };

    if (Object.keys(values.config).length === 0) {
      return notification.open({
        message: 'Nenhuma regra configurada',
        description:
          'Para salvar um regra configure ao menos 1 método de pagamento',
        type: 'warning',
        placement: 'topRight'
      });
    }

    onFinish(values);
  };

  const [config, setConfig] = useState(defaultEditConfig(currentConfig));
  const [fixedPercentage, setFixedPercentage] = useState({
    credit_card: false,
    financing_credit_card: false,
    pix: false,
    boleto: false
  });

  const [methods, setMethods] = useState(defaultSwitchStateOnEdit(config));

  const toggleMethod = (method: PaymentMethodsType) => {
    const isMethodActive = !methods[method].active;
    setMethods(prev => ({
      ...prev,
      [method]: { ...prev[method], active: isMethodActive },
    }));

    if (isMethodActive) {
      setConfig(prevConfig => ({
        ...prevConfig,
        [method]: prevConfig[method].map((item, index) =>
          index === 0 ? { ...item, active: true } : item
        )
      }));
    }
  };

  const handleCheckboxChange = (
    paymentMethod: PaymentMethodsType,
    installment: number
  ) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [paymentMethod]: prevConfig[paymentMethod].map(item =>
        item.installment === installment
          ? { ...item, active: !item.active }
          : item
      )
    }));
  };

  const handleInputChange = (
    paymentMethod: PaymentMethodsType,
    installment: number,
    value = 100
  ) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [paymentMethod]: prevConfig[paymentMethod].map(item =>
        item.installment === installment
          ? { ...item, price_percentage: +value }
          : item
      )
    }));
  };

  const renderInstallments = (paymentMethod: PaymentMethodsType) => (
    <>
      {config[paymentMethod].map(
        ({ installment, active, price_percentage }) => (
          <Row
            key={installment}
            align="middle"
            justify="space-between"
            style={{ padding: '16px' }}
          >
            {/* REGRA: parcela 1 nunca pode ser desabilitada */}
            <Checkbox
              checked={installment === 1 ? true : active}
              disabled={installment === 1}
              onChange={() => handleCheckboxChange(paymentMethod, installment)}
            >
              Parcela {installment}
            </Checkbox>
            <InputNumber
              style={{ maxWidth: 115 }}
              addonAfter="%"
              value={price_percentage}
              onChange={val =>
                handleInputChange(paymentMethod, installment, val!)
              }
              disabled={fixedPercentage[paymentMethod] || !active}
              min={100}
            />
          </Row>
        )
      )}
    </>
  );

  const handleFixedPriceCheckbox = (paymentMethod: PaymentMethodsType) => {
    const isFixedPercentageEnabled = !fixedPercentage[paymentMethod];
    setFixedPercentage(prev => ({
      ...prev,
      [paymentMethod]: isFixedPercentageEnabled
    }));

    if (isFixedPercentageEnabled) {
      // Aplicar o valor 100 a todas as parcelas ao ativar o checkbox global
      setConfig(prevConfig => ({
        ...prevConfig,
        [paymentMethod]: prevConfig[paymentMethod].map(item => ({
          ...item,
          price_percentage: 100
        }))
      }));
    }
  };

  const handleFixedPriceChange = (
    paymentMethod: PaymentMethodsType,
    value: number
  ) => {
    if (fixedPercentage[paymentMethod]) {
      // Atualizar o valor de todas as parcelas ao alterar o input global quando o checkbox está marcado
      setConfig(prevConfig => ({
        ...prevConfig,
        [paymentMethod]: prevConfig[paymentMethod].map(item => ({
          ...item,
          price_percentage: +value
        }))
      }));
    }
  };

  const renderFixedPriceInput = (paymentMethod: PaymentMethodsType) => (
    <Row align="middle" justify="space-between" style={{ padding: '16px' }}>
      <Checkbox
        checked={!!fixedPercentage[paymentMethod]}
        onChange={() => handleFixedPriceCheckbox(paymentMethod)}
      >
        Aplicar para todas
      </Checkbox>
      <InputNumber
        key={`${paymentMethod}-fixed`}
        style={{ maxWidth: 115 }}
        addonAfter="%"
        disabled={!fixedPercentage[paymentMethod]}
        onChange={val => handleFixedPriceChange(paymentMethod, val!)}
        min={100}
      />
    </Row>
  );

  const renderMethodComponent = (method: PaymentMethodsType) => (
    <div key={method} className="rounded-lg border">
      <Row
        align="middle"
        justify="space-between"
        style={{ padding: '24px 16px' }}
      >
        <span className="font-medium">{methods[method].label}</span>
        <Switch
          checked={methods[method].active}
          onChange={() => toggleMethod(method)}
        />
      </Row>

      {methods[method].active && (
        <Collapse
          bordered={false}
          expandIconPosition="end"
          style={{ padding: '0 !important' }}
        >
          <Collapse.Panel
            key={`${1}-installments`}
            collapsible={methods[method].active ? 'header' : 'disabled'}
            header="Ver parcelas"
            className="p-0"
            style={{ width: '100%', padding: 0 }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{ fontSize: 12 }}
              className="border-t p-4 font-medium text-xs -mx-4 divide-x"
            >
              <span>Parcelas</span>
              <div className="min-w-[115px] text-center">Preço</div>
            </Row>
            <div className="border-t border-b -mx-4 bg-white">
              {renderFixedPriceInput(method)}
            </div>
            <div className="divide-y -mx-4 bg-white">
              {renderInstallments(method)}
            </div>
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );

  return (
    <Edit
      saveButtonProps={{ hidden: true }}
      title="Editar regra"
      isLoading={queryResult?.isFetching}
    >
      <Row gutter={16}>
        <Col span={14}>
          <div className="font-medium text-md mb-4">Formas de pagamento</div>
          <div className="flex flex-col gap-6">
            {(
              [
                'credit_card',
                'financing_credit_card',
                'pix',
                'boleto'
              ] as PaymentMethodsType[]
            ).map(method => renderMethodComponent(method))}
          </div>
        </Col>
        <Col span={10}>
          <div style={{ position: 'sticky', top: '20px' }}>
            <Form {...formProps} onFinish={onSubmit}>
              <div className="font-medium text-md mb-4">Resumo</div>
              <div className="rounded-lg bg-gray-100 p-6">
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Adicione um nome para a regra' }
                  ]}
                >
                  <Input placeholder="Digite o nome da regra" />
                </Form.Item>
                <Divider style={{ margin: '12px 0' }} />
                <div className="flex flex-col gap-3">
                  {renderSummary(methods, config)}
                </div>
                <SaveButton
                  htmlType="submit"
                  type="primary"
                  block
                  style={{ marginTop: 16 }}
                >
                  Salvar Regra
                </SaveButton>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Edit>
  );
};
