/* eslint-disable @typescript-eslint/no-explicit-any */
import Highlighter from 'react-highlight-words';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  AudioOutlined,
  FileOutlined,
  FolderOutlined,
  HomeOutlined,
  Html5Outlined,
  PictureOutlined,
  SearchOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Input,
  InputRef,
  Radio,
  RadioChangeEvent,
  Space,
  Table,
  TableColumnType
} from 'antd';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { CrudFilter, useList } from '@refinedev/core';
import { FilterDropdownProps } from 'antd/es/table/interface';

const ContentSelector = ({
  value,
  onChange
}: {
  value?: string;
  onChange?: (
    value: string,
    content:
      | {
          id: string;
          libraryId: string;
          kind: string;
        }
      | undefined
  ) => void;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentLibraryId, setCurrentLibraryId] = useState<string | null>(null);
  const [currentTagGroupId, setCurrentTagGroupId] = useState<string | null>(
    null
  );
  const [currentTagId, setCurrentTagId] = useState<string | null>(null);
  const [parentHistory, setParentHistory] = useState<PrometheusContent[]>([]);

  const shouldShowUntaggedContents = useMemo(() => {
    return (
      Boolean(currentLibraryId && !(currentTagGroupId && !currentTagId)) &&
      !currentTagId
    );
  }, [currentLibraryId, currentTagGroupId, currentTagId]);

  const shouldShowContents = useMemo(() => {
    return Boolean(shouldShowUntaggedContents || currentTagId);
  }, [currentTagId, shouldShowUntaggedContents]);

  const resource = useMemo(() => {
    if (currentTagGroupId || currentTagId) {
      return `prometheus/v2/libraries/${currentLibraryId}/tags`;
    }

    if (currentLibraryId) {
      return `prometheus/v2/libraries/${currentLibraryId}/tag_groups`;
    }

    return `prometheus/v2/libraries`;
  }, [currentLibraryId, currentTagGroupId, currentTagId]);

  const filters = useMemo<CrudFilter[]>(() => {
    if (currentLibraryId === null) {
      return [];
    }

    if (currentTagGroupId && !currentTagId) {
      return [{ field: 'group_id', operator: 'eq', value: currentTagGroupId }];
    }

    if (currentTagGroupId && currentTagId) {
      return [
        { field: 'group_id', operator: 'eq', value: currentTagGroupId },
        { field: 'ancestor_id', operator: 'eq', value: currentTagId }
      ];
    }

    return [];
  }, [currentLibraryId, currentTagGroupId, currentTagId]);

  const pagination = useMemo(() => {
    return {
      current: currentPage,
      pageSize: currentPageSize
    };
  }, [currentPage, currentPageSize]);

  const { data, isLoading } = useList({
    resource,
    filters,
    pagination
  });

  const { data: contents } = useList({
    resource: `prometheus/v2/libraries/${currentLibraryId}/contents?items=1000`,
    filters: [
      currentTagId && { field: 'tag_id', operator: 'eq', value: currentTagId },
      shouldShowUntaggedContents && {
        field: 'tagged',
        operator: 'eq',
        value: false
      }
    ].filter(Boolean) as CrudFilter[],
    queryOptions: {
      enabled: shouldShowUntaggedContents || !!currentTagId
    }
  });

  interface DataType {
    name: string;
    type: string;
    createdAt: string;
    originalType: string;
  }

  const serializedData = useMemo<PrometheusContent[]>(() => {
    return (
      [
        ...(data
          ? (data?.data.map(
              item =>
                ({
                  id: item.id,
                  name: item.name,
                  createdAt: item.created_at,
                  originalType:
                    resource === 'prometheus/v2/libraries'
                      ? 'library'
                      : resource.includes('tag_groups')
                      ? 'tag_group'
                      : 'tag',
                  type: 'folder'
                } as PrometheusContent)
            ) as PrometheusContent[])
          : []),
        ...(shouldShowContents && contents
          ? (contents?.data.map(
              item =>
                ({
                  id: item.id,
                  name: item.name,
                  createdAt: item.created_at,
                  originalType: 'content',
                  type: item.kind
                } as PrometheusContent)
            ) as PrometheusContent[])
          : [])
      ] ?? []
    );
  }, [data, shouldShowContents, contents, resource]);

  const handleBreadcrumbClick = useCallback(
    (index: number) => {
      if (index === 0) {
        // Clicked home
        setCurrentLibraryId(null);
        setCurrentTagGroupId(null);
        setCurrentTagId(null);
        setParentHistory([]);
      } else {
        const newHistory = parentHistory.slice(0, index);

        setCurrentLibraryId(null);
        setCurrentTagGroupId(null);
        setCurrentTagId(null);

        for (const parent of newHistory) {
          switch (parent.originalType) {
            case 'library': {
              setCurrentLibraryId(parent.id);
              break;
            }

            case 'tag_group': {
              setCurrentTagGroupId(parent.id);
              break;
            }

            case 'tag': {
              setCurrentTagId(parent.id);
              break;
            }
          }
        }

        setParentHistory(newHistory);
      }
      setCurrentPage(1);
    },
    [parentHistory]
  );

  const handleRadioGroupChange = useCallback(
    (e: RadioChangeEvent) => {
      if (onChange) {
        const id = e.target.value;
        const content = serializedData.find(item => item.id === id);
        onChange(id, {
          id,
          libraryId: currentLibraryId ?? '',
          kind: content?.type ?? ''
        });
      }
    },
    [onChange, serializedData]
  );

  type DataIndex = keyof DataType;
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close
    }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Busca por nome"
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            fechar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: open => {
      if (open) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  return (
    <div>
      <Breadcrumb
        className="min-h-[32px] flex items-center my-2"
        items={
          [
            {
              title: (
                <Button
                  type="text"
                  size="small"
                  icon={<HomeOutlined />}
                  onClick={() => handleBreadcrumbClick(0)}
                />
              )
            },
            ...parentHistory.map((parent, index) => ({
              title: (
                <Button
                  size="small"
                  onClick={() => handleBreadcrumbClick(index + 1)}
                  type="text"
                  disabled={index === parentHistory.length - 1}
                >
                  {parent.name}
                </Button>
              )
            }))
          ].filter(Boolean) as BreadcrumbItemType[]
        }
      />
      <Radio.Group
        value={value}
        onChange={handleRadioGroupChange}
        className="w-full"
      >
        <Table
          dataSource={serializedData}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            }
          }}
          onRow={record => {
            return {
              className: 'cursor-pointer user-select-none',
              onClick: () => {
                switch (record.originalType) {
                  case 'tag': {
                    setCurrentTagId(record.id);
                    break;
                  }

                  case 'tag_group': {
                    setCurrentTagGroupId(record.id);
                    break;
                  }

                  case 'library': {
                    setCurrentLibraryId(record.id);
                    break;
                  }

                  default: {
                    return;
                  }
                }
                setCurrentPage(1);
                setParentHistory(prev => [...prev, record]);
              }
            };
          }}
          rowKey="id"
        >
          <Table.Column
            dataIndex="type"
            title=""
            render={(_, record) => (
              <div className="flex items-center gap-1">
                {(record as any).originalType === 'content' && (
                  <Radio value={(record as any).id} />
                )}
                {ICONS[(record as any).type as keyof typeof ICONS]}
              </div>
            )}
            width={32}
            className="!user-select-none"
          />
          <Table.Column
            dataIndex="name"
            title="Nome"
            className="!user-select-none"
            sorter={(a: { name: string }, b: { name: string }) =>
              a.name.localeCompare(b.name)
            }
            sortDirections={['descend', 'ascend']}
            {...getColumnSearchProps('name')}
          />
          <Table.Column
            dataIndex="type"
            title="Tipo"
            className="!user-select-none"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            render={(_, record) => TYPES[record.type as keyof typeof TYPES]}
          />
        </Table>
      </Radio.Group>
    </div>
  );
};

export default ContentSelector;

interface PrometheusContent {
  type:
    | 'folder'
    | 'video'
    | 'audio'
    | 'image'
    | 'hyper_doc'
    | 'html_doc'
    | 'document';
  originalType: 'library' | 'tag_group' | 'tag' | 'content';
  id: string;
  name: string;
  createdAt: string;
}

const ICONS: Record<PrometheusContent['type'], React.ReactNode> = {
  folder: <FolderOutlined style={{ fontSize: 18 }} />,
  video: <VideoCameraOutlined style={{ fontSize: 18 }} />,
  audio: <AudioOutlined style={{ fontSize: 18 }} />,
  image: <PictureOutlined style={{ fontSize: 18 }} />,
  hyper_doc: <FileOutlined style={{ fontSize: 18 }} />,
  html_doc: <Html5Outlined style={{ fontSize: 18 }} />,
  document: <FileOutlined style={{ fontSize: 18 }} />
};

const TYPES: Record<PrometheusContent['type'], string> = {
  folder: 'Pasta',
  video: 'Video',
  audio: 'Audio',
  image: 'Imagem',
  hyper_doc: 'Hyperdoc',
  html_doc: 'HTML',
  document: 'Documento'
};
