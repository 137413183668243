import Icon, { RightOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import { ComponentType, SyntheticEvent } from 'react';
import { useNavigation } from '@refinedev/core';

interface CounterItemProps {
  icon: ComponentType;
  title: string;
  value: number;

  link?: string;
}

export const CounterItem = ({ icon, title, value, link }: CounterItemProps) => {
  const { push } = useNavigation();

  const handleNavigate = (e: SyntheticEvent<HTMLDivElement>): void => {
    e?.preventDefault();

    link && push(link);
  };

  return (
    <Card
      className="w-full flex items-center group rounded-md bg-white hover:bg-neutral-100 cursor-pointer transition"
      styles={{
        body: {
          width: '100%'
        }
      }}
      onClick={handleNavigate}
    >
      <Space className="w-full justify-between items-center">
        <Space>
          <Icon component={icon} className="text-xl text-neutral-400" />
          <Typography.Text className="text-md font-semibold">
            {title}
          </Typography.Text>
        </Space>

        <div>
          <Typography.Text className="!text-xl font-semibold whitespace-nowrap flex group-hover:hidden absolute right-4 top-1/2 transform -translate-y-1/2 transition">
            {value}
          </Typography.Text>
          <RightOutlined className="text-xl hidden group-hover:flex absolute right-4 top-1/2 transform -translate-y-1/2 transition" />
        </div>
      </Space>
    </Card>
  );
};
