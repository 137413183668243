import React from 'react';
import { IDataContent } from 'interfaces/courses';

export const IframeContent: React.FC<{
  content: IDataContent;
  title: string;
}> = ({ content, title }) => {
  return (
    <iframe
      className="w-full !h-[60vh]"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      src={content.url}
      title={title}
      allowFullScreen
    />
  );
};
