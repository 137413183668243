import React from 'react';
import { IDataContent } from 'interfaces/courses';

export const AudioContent: React.FC<{
  content: IDataContent;
}> = ({ content }) => {
  return (
    <audio className="w-full" src={content.url} controls>
      <source src={content.url} type="audio/ogg" />
      <source src={content.url} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};
