import React from 'react';
import { Button, Col, Form, FormProps, Input, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { forumStatusOptions } from 'constants/admin/forum';

export const SearchFilters: React.FC<{
  searchFormProps: FormProps;
}> = ({ searchFormProps }) => {
  const handleClear = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  };

  return (
    <Form {...searchFormProps} layout="inline" className="mb-8 w-full">
      <Row gutter={[0, 16]} className="w-full">
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="topic">
            <Input
              placeholder="Buscar por tópico..."
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Form.Item name="status">
            <Select
              placeholder="Todos os status"
              options={forumStatusOptions}
              defaultValue={null}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Button type="default" htmlType="submit" className="mr-2">
            Filtrar
          </Button>
          <Button type="text" onClick={handleClear}>
            Limpar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
