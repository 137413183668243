import {} from 'antd/lib/date-picker/';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, FormItemProps, Input } from 'antd';
import { replaceTimeZone } from 'services/date';
import { useEffect } from 'react';
import type { RangePickerProps } from 'antd/es/date-picker';

interface DateRangeFieldProps extends FormItemProps {
  startsAtField: string;
  endsAtField: string;
  dateRangeProps: RangePickerProps;
}

export const DateRangeField = ({
  startsAtField,
  endsAtField,
  dateRangeProps,
  ...props
}: DateRangeFieldProps) => {
  const form = Form.useFormInstance();
  const watch = Form.useWatch<Dayjs[]>(props.name);

  useEffect(() => {
    if (watch) {
      const [start, end] = watch;

      form?.setFieldsValue({
        [startsAtField]: start ? replaceTimeZone(dayjs(start)) : '',
        [endsAtField]: end ? replaceTimeZone(dayjs(end)) : ''
      });
    }
  }, [endsAtField, form, startsAtField, watch]);

  return (
    <>
      <Form.Item name={startsAtField} noStyle hidden>
        <Input />
      </Form.Item>

      <Form.Item name={endsAtField} noStyle hidden>
        <Input />
      </Form.Item>

      <Form.Item {...props}>
        <DatePicker.RangePicker
          className="lg:w-[370px]"
          placeholder={['Data e hora de início', 'Data e hora de término']}
          format={dateRangeProps?.showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
          showTime
          showSecond={false}
          {...dateRangeProps}
          onChange={(_, dateStrings) => {
            form?.setFieldsValue({
              [startsAtField]: dateStrings[0],
              [endsAtField]: dateStrings[1]
            });
          }}
        />
      </Form.Item>
    </>
  );
};
