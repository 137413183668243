/* eslint-disable unicorn/filename-case */
import { NotificationContext } from 'components/layout/Context/ErrorContextProvider';
import { useContext } from 'react';

const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export default useNotification;
