import * as Icons from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch
} from 'antd';
import { Create, useForm, useSelect } from '@refinedev/antd';
import { ICoupon } from 'interfaces/coupons';
import { IProduct } from 'interfaces/products';
import { STATUSES, translateStatus } from 'services/coupons';
import { useEffect, useState } from 'react';

export const CouponCreate = () => {
  const { form, formProps, saveButtonProps } = useForm<ICoupon>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [discountItems, setDiscountItems] = useState<Record<string, any>[]>([]);
  const [ruleItems, setRuleItems] = useState<Record<string, unknown>[]>([]);

  const onFormChange = () => {
    setRuleItems(form.getFieldValue(['rule', 'propositions']));
    setDiscountItems(form.getFieldValue('discounts'));
  };

  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<IProduct[]>([]); // Estado para armazenar todos os itens carregados

  const { selectProps: productSelectProps, queryResult } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'filter[with_active_offers]',
        operator: 'eq',
        value: true
      }
    ],

    onSearch: value => {
      setOptions([]);
      setPage(1);
      return [
        {
          field: 'filter[name]',
          operator: 'eq',
          value
        },
        {
          field: 'filter[with_active_offers]',
          operator: 'eq',
          value: true
        }
      ];
    },

    pagination: {
      mode: 'server',
      current: page,
      pageSize: 10
    }
  });

  const handleNewResults = (newResults: IProduct[]) => {
    setOptions(prevOptions => [...prevOptions, ...newResults]);
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const bottom =
      target.scrollHeight === target.scrollTop + target.clientHeight;

    if (Number(queryResult?.data?.total) === options.length) {
      return;
    }

    if (bottom && !queryResult.isLoading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    if (queryResult.data && queryResult.data.data.length > 0) {
      handleNewResults(queryResult.data.data);
    }
  }, [queryResult.data]);

  const products = options.map(product => ({
    label: product.name,
    value: product.id
  }));

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar cupom">
      <Form {...formProps} layout="vertical" onValuesChange={onFormChange}>
        <Form.Item
          label="Código"
          tooltip="O código precisa ser único ou não será possível criar o cupom"
          name="code"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Aplicar Automaticamente?" name="is_auto_applied">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            {STATUSES.map(status => (
              <Select.Option key={status}>
                {translateStatus(status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Expiração"
          name="expires_at"
          getValueProps={value => {
            return {
              value: value ? dayjs(value) : ''
            };
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.List name={['rule', 'propositions']}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-4 w-full items-center">
                  <Form.Item
                    label="Regra"
                    name={[field.name, 'key']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="mb-4 w-full"
                      style={{ width: 300 }}
                      onChange={value => {
                        form.setFields([
                          { name: [field.name, 'key'], value: value }
                        ]);
                      }}
                    >
                      <Select.Option key="min_offers_count">
                        Mínimo de ofertas no carrinho
                      </Select.Option>
                      <Select.Option key="max_offers_count">
                        Máximo de ofertas no carrinho
                      </Select.Option>
                      <Select.Option key="max_coupon_usage_by_user_count">
                        Número máximo de uso por usuário
                      </Select.Option>
                      <Select.Option key="max_total_usage_count">
                        Número total de usos
                      </Select.Option>
                      <Select.Option key="selected_products">
                        Seleção de produtos
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {ruleItems[field.name]?.key === 'selected_products' && (
                    <Form.Item
                      label="Produtos"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <Select
                        {...productSelectProps}
                        mode="multiple"
                        options={products}
                        onPopupScroll={handleScroll}
                        loading={queryResult.isLoading}
                        style={{ width: 500 }}
                      />
                    </Form.Item>
                  )}

                  {ruleItems[field.name]?.key !== 'selected_products' && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber />
                    </Form.Item>
                  )}
                  <Icons.MinusCircleOutlined
                    className="mb-4"
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.ErrorList errors={errors} className="mb-4" />
              <Button type="dashed" onClick={() => add()} block>
                <Icons.PlusOutlined />
                Add Regra
              </Button>
            </>
          )}
        </Form.List>
        <Form.List name="discounts">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-4 w-full items-center">
                  <Form.Item
                    label="Tipo"
                    name={[field.name, 'kind']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: 500 }}
                      onChange={value => {
                        form.setFields([
                          { name: [field.name, 'kind'], value: value }
                        ]);
                      }}
                    >
                      <Select.Option key="percentage">
                        Porcentagem
                      </Select.Option>
                      <Select.Option key="amount">Valor</Select.Option>
                      <Select.Option key="percentage_on_most_expensive_item">
                        Porcentagem no item mais caro
                      </Select.Option>
                      <Select.Option key="percentage_on_less_expensive_item">
                        Porcentagem no item mais barato
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {[
                    'percentage_on_most_expensive_item',
                    'percentage_on_less_expensive_item',
                    'percentage'
                  ].includes(discountItems[field.name]?.kind) && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber addonAfter="%" precision={2} />
                    </Form.Item>
                  )}

                  {discountItems[field.name]?.kind === 'amount' && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber addonBefore="R$" precision={2} />
                    </Form.Item>
                  )}

                  <Icons.MinusCircleOutlined
                    className="mb-4"
                    onClick={() => remove(field.name)}
                  />
                </div>
              ))}
              <Form.ErrorList errors={errors} className="mb-4" />
              <Button
                type="dashed"
                className="!flex items-center justify-center mt-2"
                onClick={() => add()}
                block
              >
                <Icons.PlusOutlined />
                Add Desconto
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Create>
  );
};
