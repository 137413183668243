import * as Icons from '@ant-design/icons';
import { Button, Divider, Form, Select } from 'antd';
import { IGraduateDisciplineShow } from '../../../interfaces/graduate_disciplines';
import { Stack } from '@qcx/ui';
import { useCallback } from 'react';
import { useCreate, useNotification } from '@refinedev/core';
import { useForm, useSelect } from '@refinedev/antd';

interface ModuleDisciplineFormProps {
  moduleId: string;
  disciplineCreateModalShow: () => void;
}

export const ModuleDisciplineForm = ({
  moduleId,
  disciplineCreateModalShow
}: ModuleDisciplineFormProps) => {
  const { form } = useForm();
  const { open } = useNotification();
  const resource = 'graduate_disciplines';
  const createResource = 'graduate/disciplines_modules';

  const {
    selectProps: disciplineSelectProps,
    queryResult: disciplineQueryResult
  } = useSelect<IGraduateDisciplineShow>({
    resource,
    optionLabel: (value: IGraduateDisciplineShow) =>
      `${
        value.external_reference == null ? '' : value.external_reference + ' -'
      } ${value.name}`,
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  const { mutate: createDiscipline } = useCreate();
  const handleSubmit = useCallback(async () => {
    await form
      .validateFields()
      .then(() => {
        const disciplineId = form.getFieldValue('discipline_id');
        const kind = form.getFieldValue('kind');

        createDiscipline(
          {
            resource: createResource,
            values: {
              kind,
              discipline_id: disciplineId,
              module_id: moduleId
            },
            successNotification: () => {
              return {
                description: 'Sucesso!',
                message: 'Disciplina vinculada ao módulo com sucesso!',
                type: 'success'
              };
            }
          },
          {
            onSuccess: () => {
              form.resetFields();
            }
          }
        );
      })
      .catch(() => {
        open?.({
          type: 'error',
          message: `Favor conferir todos os campos obrigatórios.`,
          description: 'Error ao criar registro!'
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, disciplineQueryResult, moduleId]);

  return (
    <Form form={form} key={`form-${moduleId}`}>
      <Stack css={{ gap: '$2', justifyContent: 'space-between' }}>
        <Form.Item
          style={{ flex: 1 }}
          label="Disciplina"
          name="discipline_id"
          rules={[{ required: true, message: 'Disciplina é obrigatória' }]}
        >
          <Select
            placeholder="Selecione a disciplina ..."
            {...disciplineSelectProps}
            style={{ minWidth: 200 }}
            dropdownRender={menu => (
              <>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <Button
                  className="w-full !text-left"
                  type="primary"
                  ghost
                  onClick={disciplineCreateModalShow}
                  icon={<Icons.PlusOutlined />}
                >
                  Nova disciplina
                </Button>
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="kind"
          initialValue="mandatory"
          rules={[{ required: true, message: 'Tipo é obrigatório' }]}
        >
          <Select
            placeholder="Selecione o tipo ..."
            options={[
              { label: 'Obrigatória', value: 'mandatory' },
              { label: 'Optativa', value: 'optional' }
            ]}
            style={{ minWidth: 200 }}
            defaultValue={'mandatory'}
          />
        </Form.Item>
        <Button onClick={handleSubmit}>Inserir</Button>
      </Stack>
    </Form>
  );
};
