export interface StatusType {
  label: string;
  value: string | null;
  color: string;
}

export const criteriaWeights = [
  { label: '0%', weight: 0 },
  { label: '5%', weight: 0.05 },
  { label: '10%', weight: 0.1 },
  { label: '15%', weight: 0.15 },
  { label: '20%', weight: 0.2 },
  { label: '25%', weight: 0.25 },
  { label: '30%', weight: 0.3 },
  { label: '35%', weight: 0.35 },
  { label: '40%', weight: 0.4 },
  { label: '45%', weight: 0.45 },
  { label: '50%', weight: 0.5 },
  { label: '55%', weight: 0.55 },
  { label: '60%', weight: 0.6 },
  { label: '65%', weight: 0.65 },
  { label: '70%', weight: 0.7 },
  { label: '75%', weight: 0.75 },
  { label: '80%', weight: 0.8 },
  { label: '85%', weight: 0.85 },
  { label: '90%', weight: 0.9 },
  { label: '95%', weight: 0.95 },
  { label: '100%', weight: 1 }
];

export const criteriaTypes = [
  { label: 'Assessment', value: 'assessment' },
  { label: 'Assignment', value: 'assignment' }
];

export const evaluationsAttemptsStatus: StatusType[] = [
  {
    label: 'Desconhecida',
    value: 'unknown',
    color: 'default'
  },
  {
    label: 'Avaliação realizada',
    value: 'completed',
    color: 'purple'
  },
  {
    label: 'Aguardando correção',
    value: 'pending_evaluation',
    color: 'orange'
  },
  {
    label: 'Correção iniciada',
    value: 'evaluation_started',
    color: 'purple'
  },
  {
    label: 'Corrigido',
    value: 'evaluated',
    color: 'blue'
  },
  {
    label: 'Nota calculada',
    value: 'scored',
    color: 'default'
  },
  {
    label: 'Aprovado',
    value: 'passed',
    color: 'success'
  },
  {
    label: 'Reprovado',
    value: 'failed',
    color: 'error'
  }
];

export const evaluationsQuestionsStatus: StatusType[] = [
  {
    label: 'Inativa',
    value: 'inactive',
    color: 'default'
  },
  {
    label: 'Ativa',
    value: 'active',
    color: 'blue'
  },
  {
    label: 'Anulada',
    value: 'nullified',
    color: 'default'
  }
];
