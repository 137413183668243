import accessProvider from './access-provider';
import authProvider from './providers/auth-provider';
import dataProvider from './providers/data-provider';
import locale from 'antd/locale/pt_BR';
import routerProvider, {
  CatchAllNavigate,
  UnsavedChangesNotifier
} from '@refinedev/react-router-v6';
import { Authenticated, I18nProvider, Refine } from '@refinedev/core';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ErrorComponent, useNotificationProvider } from '@refinedev/antd';
import {
  Footer,
  Header,
  Layout,
  OffLayoutArea,
  Sider
} from 'components/layout';
import { GoogleLogin } from 'pages/login/google';
import { IAppResource } from 'interfaces/resources';
import { LoginPage } from 'pages/login';
import { NotificationContextProvider } from 'components/layout/Context/ErrorContextProvider';
import { getAPI } from 'requests/api';
import { getResourcesAndRoutes } from 'resources';
import { getTenant } from 'services/tenants';
import { setupTracker } from 'services/tracker';
import { themeConfig } from 'configs/antd';
import { trackingCurrentOptions } from 'lib/tracker';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  };

  useEffect(() => {
    setupTracker(trackingCurrentOptions);
  }, []);

  return (
    <BrowserRouter>
      <NotificationContextProvider>
        <ConfigProvider theme={themeConfig} locale={locale}>
          <Refine
            options={{
              liveMode: 'auto',
              disableTelemetry: true,
              warnWhenUnsavedChanges: true
            }}
            notificationProvider={useNotificationProvider}
            accessControlProvider={accessProvider}
            i18nProvider={i18nProvider}
            routerProvider={routerProvider}
            dataProvider={dataProvider(`${getTenant().apiUrl}/admin`, getAPI())}
            authProvider={authProvider}
            resources={getResourcesAndRoutes().resource as IAppResource[]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key="authenticated-layout"
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <Layout Sider={Sider} Header={Header} Footer={Footer}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                {getResourcesAndRoutes().routes}
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated key="authenticated-auth" fallback={<Outlet />}>
                    <Navigate to={'/home'} />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<LoginPage />} />
                <Route path="/oauth/google" element={<GoogleLogin />} />
              </Route>
            </Routes>
            <OffLayoutArea />
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </NotificationContextProvider>
    </BrowserRouter>
  );
}

export default App;
