import './styles.less';
import React from 'react';
import { Divider, Radio, Space, Typography } from 'antd';
import { IProfessorAreaQuestionShow } from 'interfaces/professor_area/assessments';
import { TagField } from '@refinedev/antd';
import { evaluationsQuestionsStatus } from 'constants/admin/evaluations';
import { getStatusData } from 'services/professor_area/evaluations';

const { Text } = Typography;

export const ObjectiveQuestion: React.FC<{
  questionData?: IProfessorAreaQuestionShow;
}> = ({ questionData }) => {
  if (!questionData) {
    return null;
  }

  return (
    <Space direction="vertical" className="w-full mb-2">
      <Space className="flex justify-between">
        <Text type="secondary" className="font-bold">
          Enunciado
        </Text>
        <Space className="gap-0">
          <TagField
            color="default"
            value={
              questionData?.kind === 'objective' ? 'Objetiva' : 'Discursiva'
            }
          />
          <TagField
            color={
              getStatusData(questionData.status, evaluationsQuestionsStatus)
                ?.color
            }
            value={
              getStatusData(questionData.status, evaluationsQuestionsStatus)
                ?.label
            }
          />
        </Space>
      </Space>
      <div
        style={{
          display: 'block',
          fontWeight: 600,
          marginBottom: 8
        }}
        className="[&>p]:m-0"
        dangerouslySetInnerHTML={{
          __html: questionData.statement
        }}
      />
      <Divider />
      <Text type="secondary" className="font-bold">
        Alternativas
      </Text>
      <Radio.Group
        className="flex flex-col gap-2"
        disabled
        options={questionData.alternatives?.map((alternative, index) => ({
          value: index,
          label: (
            <Space>
              <div
                className="[&>p]:m-0"
                dangerouslySetInnerHTML={{
                  __html: alternative.statement
                }}
              />
            </Space>
          )
        }))}
      />
      <Divider />
      <Text type="secondary" className="font-bold">
        Gabarito
      </Text>
      <Radio.Group
        value={questionData?.correct_alternative_id}
        options={questionData?.alternatives
          ?.filter(
            alternative =>
              alternative.id === questionData?.correct_alternative_id
          )
          .map(alternative => ({
            value: alternative.id,
            label: (
              <Space>
                <div
                  className="[&>p]:m-0"
                  dangerouslySetInnerHTML={{
                    __html: alternative.statement
                  }}
                />
              </Space>
            )
          }))}
      />
    </Space>
  );
};
