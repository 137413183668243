import { Form, Input, Modal, ModalProps } from 'antd';
import { FormItemCol } from 'components/professor_area/FormItemCol';
import { getTenant } from 'services/tenants';
import { useCreate } from '@refinedev/core';
import { useForm } from '@refinedev/antd';

export interface CreateDisciplineModal {
  modalClose: () => void;
  modalProps: ModalProps;
  refetch: () => void;
  courseId: string;
  type?: 'graduate_course' | undefined;
}

export const CreateDisciplineModal = ({
  modalClose,
  modalProps,
  refetch,
  courseId,
  type
}: CreateDisciplineModal) => {
  const { name } = getTenant();
  const { mutateAsync: createDiscipline } = useCreate();
  const { mutateAsync: insertDiscipline } = useCreate();
  const { form, formProps } = useForm();

  const handleCreate = async () => {
    const resource = `graduate_disciplines`;

    return form
      .validateFields()
      .then(async () => {
        return createDiscipline({
          resource,
          values: {
            ...form.getFieldsValue(),
            name: form.getFieldValue('disciplineName'),
            code: name?.slice(0, 3)?.toUpperCase() + form.getFieldValue('code')
          },
          successNotification: () => ({
            type: 'success',
            description: 'Tudo certo!',
            message: 'Disciplina criada com sucesso'
          }),
          errorNotification: error => {
            if (
              error?.message ===
              'Validation failed: Code has already been taken'
            ) {
              return {
                type: 'error',
                description: 'Ops!',
                message: 'Código da disciplina duplicado.'
              };
            }

            return {
              type: 'error',
              description: 'Ops!',
              message: 'Não foi possivel cadastrar a disciplina.'
            };
          }
        }).then(async response => {
          let disciplineResource = 'courses/disciplines';

          if (type === 'graduate_course') {
            disciplineResource = 'graduate/disciplines_modules';
          }
          insertDiscipline(
            {
              resource: disciplineResource,
              values: {
                discipline_id: response.data.id,
                course_id: type !== 'graduate_course' ? courseId : undefined,
                module_id: type === 'graduate_course' ? courseId : undefined,
                kind: type === 'graduate_course' ? 'mandatory' : undefined
              },
              successNotification: () => {
                return {
                  description: 'Sucesso!',
                  message: 'Disciplina vinculada ao módulo com sucesso!',
                  type: 'success'
                };
              }
            },
            {
              onSuccess: () => {
                refetch();
                form.resetFields();
                modalClose();
              }
            }
          );
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Modal
      {...modalProps}
      title="Criar disciplina"
      cancelText="Cancelar"
      okText="Criar disciplina e inserir"
      onOk={handleCreate}
      className="!w-[600px] !z-1000"
      destroyOnClose
      onCancel={() => {
        form.resetFields();
        modalClose();
      }}
    >
      <Form {...formProps} layout="vertical">
        <FormItemCol
          cols={[8, 16]}
          label="Nome"
          name="disciplineName"
          rules={[
            {
              required: true,
              message: 'Digite um nome.'
            }
          ]}
        >
          <Input placeholder="Ex.: Direito Civil" />
        </FormItemCol>
        <FormItemCol
          cols={[8, 16]}
          label="Código da disciplina"
          name="code"
          rules={[
            {
              required: true,
              message: 'Digite um código.'
            },
            {
              pattern: new RegExp(/^\d{4}$/),
              message: 'Insira um numero válido de 4 digitos'
            }
          ]}
        >
          <Input
            className="w-[150px]"
            maxLength={4}
            addonBefore={name.slice(0, 3).toUpperCase()}
          />
        </FormItemCol>
        <FormItemCol
          cols={[8, 16]}
          label="Duração (minutos)"
          name="duration_in_minutes"
          rules={[
            {
              required: true,
              message: 'Digite a duração.'
            }
          ]}
        >
          <Input type="number" className="w-[100px]" />
        </FormItemCol>
      </Form>
    </Modal>
  );
};
