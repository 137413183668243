import React, { useCallback } from 'react';
import { CardItem } from 'components/professor_area/CardItem';
import { Divider, MenuProps, Progress, Space, Typography } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { IStudent, IStudentShow } from 'interfaces/professor_area/students';
import { getAPI } from 'requests/api';
import { getInitialsName } from 'utils/get-initials-name';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

const items: MenuProps['items'] = [
  {
    key: 'edit',
    label: 'Editar frequência',
    icon: <EditOutlined />
  },
  {
    key: 'show',
    label: 'Ver dados do aluno',
    icon: <EyeOutlined />
  }
];

export const StudentListItem: React.FC<{
  student: IStudent;
  handleEditFrequency: (student: IStudentShow) => void;
  handleShowDetails: (student: IStudentShow) => void;
}> = ({ student, handleEditFrequency, handleShowDetails }) => {
  const { id } = useParams();

  const getStudentData = useCallback(
    async (callback: typeof handleEditFrequency | typeof handleShowDetails) => {
      const studentData = await getAPI()
        .get(`/admin/professor_area/classrooms/${id}/enrollments/${student.id}`)
        .catch(() => {
          throw new Error('Não foi possivel encontrar os dados do aluno');
        });
      callback(studentData?.data);
    },
    [id, student.id]
  );

  const handleMenuClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event.key === 'edit') {
        getStudentData(handleEditFrequency);
      } else {
        getStudentData(handleShowDetails);
      }
    },
    [getStudentData, handleEditFrequency, handleShowDetails]
  );

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <CardItem
      avatar={getInitialsName(student.student_name)}
      title={student.student_name}
      menuProps={menuProps}
      meta={
        <div>
          <Text type="secondary">
            Matrícula: {student.enrollment_registration}
          </Text>
          <Divider type="vertical" />
          <Text type="secondary">Curso: {student.course_name}</Text>
        </div>
      }
      extraContent={
        <Space className="w-[160px] text-left mr-4">
          <Progress
            type="circle"
            percent={student.attendance_rate}
            size={24}
            showInfo={false}
          />
          <Text type="secondary" className="ml-2">
            {student.attendance_rate ?? '0'}% de frequência
          </Text>
        </Space>
      }
    />
  );
};
