import { Divider, Typography } from 'antd';
import { FormItemAssignmentCriteria } from 'components/professor_area/FormItemAssignmentCriteria';
import { FormItemAssignmentInstruction } from 'components/professor_area/FormItemAssignmentIntruction';

export const ModalCreateAssigmentStep2 = () => {
  return (
    <>
      <Typography.Title level={4} className="!mb-8">
        Instruções e critérios
      </Typography.Title>

      <div className="mb-4">
        <Typography.Title level={5}>Instruções</Typography.Title>
        <Typography.Text>
          Adicionar as instruções para a atividade
        </Typography.Text>
      </div>

      <FormItemAssignmentInstruction />

      <Divider />

      <div className="mb-4">
        <Typography.Title level={5}>Critérios</Typography.Title>
        <Typography.Text>
          Escreva o enunciado e escolha o tipo de resposta que deseja que o
          aluno envie
        </Typography.Text>
      </div>

      <FormItemAssignmentCriteria />
    </>
  );
};
