import { ClassroomEdit, ClassroomList } from 'pages/classrooms';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'classrooms',
  label: 'Turmas',
  list: ClassroomList,
  edit: ClassroomEdit,
  actions: ['list', 'edit', 'create'],
  parentName: 'course_creation'
};

export default generateResources(resource);
