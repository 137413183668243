import React, { useCallback, useState } from 'react';
import useNotification from 'hooks/useNotification';
import { Button, Table } from 'antd';
import { CrudFilters, HttpError, useModal } from '@refinedev/core';
import { EyeOutlined } from '@ant-design/icons';
import {
  IProfessorAreaAssessmentShow,
  IProfessorAreaQuestion
} from 'interfaces/professor_area/assessments';
import { ModalShowQuestion } from 'components/modal/professor_area/assessments/ModalShowQuestion/ModalShowQuestion';
import { SearchFilters } from './components/SearchFilters';
import { TagField, TextField, useTable } from '@refinedev/antd';
import { evaluationsQuestionsStatus } from 'constants/admin/evaluations';
import { getAPI } from 'requests/api';
import { getStatusData } from 'services/professor_area/evaluations';
import { useParams } from 'react-router-dom';

interface IFilter {
  statement: string;
  status: string;
}

export const TabQuestions: React.FC<{
  evaluation: IProfessorAreaAssessmentShow;
}> = ({ evaluation }) => {
  const [question, setQuestion] = useState();
  const { classroomId } = useParams();
  const modalShowQuestion = useModal();
  const { showError } = useNotification();
  const { tableProps, searchFormProps } = useTable<
    IProfessorAreaQuestion,
    HttpError,
    IFilter
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations/${evaluation.id}/questions`,
    initialPageSize: 10,
    onSearch: ({ statement, status }) => {
      const filters: CrudFilters = [];

      filters.push(
        {
          field: 'filter[by_statement]',
          operator: 'eq',
          value: statement
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        }
      );
      return filters;
    }
  });

  const handleShowAction = useCallback(
    (record: IProfessorAreaQuestion) => async () => {
      const questionData = await getAPI()
        .get(
          `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluation.id}/questions/${record.id}`
        )
        .catch(() => {
          return showError('Erro ao carregar dados da questão.');
        });
      setQuestion(questionData?.data);
      return modalShowQuestion.show();
    },
    [modalShowQuestion, classroomId, evaluation, showError]
  );

  return (
    <div>
      <SearchFilters searchFormProps={searchFormProps} />
      <Table {...tableProps}>
        <Table.Column
          dataIndex="statement"
          key="statement"
          title="Enunciado"
          render={value => (
            <div
              className="[&>p]:m-0"
              dangerouslySetInnerHTML={{
                __html: value
              }}
            />
          )}
        />
        {evaluation.evaluation_kind === 'objective' && (
          <Table.Column
            dataIndex="alternatives_count"
            key="alternatives_count"
            title="Alternativas"
            render={value => <TextField value={value} />}
          />
        )}
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          render={value => (
            <TagField
              color={getStatusData(value, evaluationsQuestionsStatus)?.color}
              value={getStatusData(value, evaluationsQuestionsStatus)?.label}
            />
          )}
        />
        <Table.Column<IProfessorAreaQuestion>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Button
                size="small"
                icon={<EyeOutlined />}
                onClick={handleShowAction(record)}
              />
            );
          }}
        />
      </Table>
      <ModalShowQuestion
        modalProps={modalShowQuestion}
        questionData={question}
      />
    </div>
  );
};
