import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { Space, Table, TableProps } from 'antd';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssignmentAttemptStatus } from 'services/assignments';
import { useCallback } from 'react';
import { useNavigation } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { useProfessorAreaAssignmentsStore } from '../assignments.store';
import type { IProfessorAreaEvaluationAttemptList } from 'interfaces/professor_area/evaluations';

interface ProfessorAreaAssignmentsTableProps {
  tableProps: TableProps<IProfessorAreaEvaluationAttemptList>;
}

export const TabAttemptsTable = ({
  tableProps
}: ProfessorAreaAssignmentsTableProps) => {
  const params = useParams();
  const classroomId = params.classroomId;
  const evaluationId = params.id;
  const { show } = useNavigation();

  const selectedEvaluation = useProfessorAreaAssignmentsStore(
    s => s.selectedEvaluation
  );
  const setSelectedEvaluation = useProfessorAreaAssignmentsStore(
    s => s.setSelectedEvaluation
  );

  const onGoToShowAndUpdateStore = useCallback(
    (record: IProfessorAreaEvaluationAttemptList) => {
      if (selectedEvaluation) {
        setSelectedEvaluation({
          ...selectedEvaluation,
          user: {
            id: record.user_id,
            name: record.user_name
          }
        });

        show('professor_area/classroom_assignment/attempt', record.id, 'push', {
          classroomId,
          evaluationId
        });
      }
    },
    [classroomId, evaluationId, selectedEvaluation, setSelectedEvaluation, show]
  );

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true
      }}
    >
      <Table.Column
        dataIndex="user_name"
        key="user_name"
        title="Aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="registration"
        key="registration"
        title="Matrícula"
        render={value => <TextField value={value || '--'} />}
      />
      <Table.Column
        dataIndex="pending_attempts"
        key="pending_attempts"
        title="Aguardando correção"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="corrected_attempts"
        key="corrected_attempts"
        title="Corrigido"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="total_attempts"
        key="total_attempts"
        title="Tentativas"
        align="center"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={tagColorByStatus(value)}
            value={translateAssignmentAttemptStatus(value)}
          />
        )}
      />
      <Table.Column
        dataIndex="score"
        key="score"
        title="Nota"
        align="center"
        render={value => <TextField value={value || '--'} />}
      />
      <Table.Column<IProfessorAreaEvaluationAttemptList>
        title="Ações"
        dataIndex="actions"
        align="center"
        width={80}
        render={(_, record) => {
          return (
            <Space>
              <ShowButton
                onClick={() => onGoToShowAndUpdateStore(record)}
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
