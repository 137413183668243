import FilterForm from 'components/forms/FilterForm';
import dayjs from 'dayjs';
import { Col, DatePicker, Flex, Form, Input, Select } from 'antd';
import { ForumTable } from 'components/forum/ForumTable';
import { HttpError } from '@refinedev/core';
import { IForum } from 'interfaces/forum';
import { forumStatusOptions } from 'constants/admin/forum';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

interface IFilter {
  title: string;
  status: string;
  startsAt: dayjs.Dayjs;
  endsAt: dayjs.Dayjs;
}

export const TabForum = () => {
  const { id } = useParams();
  const { tableProps, searchFormProps } = useTable<IForum, HttpError, IFilter>({
    resource: `graduate/courses/${id}/forums`,
    onSearch: ({ title, status, startsAt, endsAt }) => {
      return [
        {
          field: 'filter[by_title]',
          operator: 'eq',
          value: title
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        },
        {
          field: 'filter[last_post_starts_at]',
          operator: 'eq',
          value: startsAt
        },
        {
          field: 'filter[last_post_ends_at]',
          operator: 'eq',
          value: endsAt
        }
      ];
    }
  });
  return (
    <>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Título do tópico"
          name="title"
        >
          <Input placeholder="Título do tópico" className="min-w-[250px]" />
        </Form.Item>

        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Status"
          name="status"
        >
          <Select
            options={forumStatusOptions}
            defaultValue={null}
            placeholder="Todos"
            className="min-w-[150px]"
          />
        </Form.Item>

        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Última atualização (a partir de)"
          name="startsAt"
        >
          <DatePicker
            className="w-full"
            placeholder="dd/mm/aaaa"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          colon
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          label="Última atualização (término em)"
          name="endsAt"
        >
          <DatePicker
            className="w-full"
            placeholder="dd/mm/aaaa"
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </FilterForm>

      <ForumTable tableProps={tableProps} />
    </>
  );
};
