import React from 'react';
import { AttempsTable } from './components/AttempsTable';
import { CrudFilters, HttpError } from '@refinedev/core';
import {
  IProfessorAreaAssessmentAttempts,
  IProfessorAreaAssessmentShow
} from 'interfaces/professor_area/assessments';
import { SearchFilters } from './components/SearchFilters';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

export interface IFilter {
  name: string;
  status: string;
}

export const TabAttempts: React.FC<{
  evaluation: IProfessorAreaAssessmentShow;
}> = () => {
  const { classroomId, id } = useParams();
  const { tableProps, searchFormProps } = useTable<
    IProfessorAreaAssessmentAttempts,
    HttpError,
    IFilter
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations/${id}/attempts`,
    initialPageSize: 10,
    onSearch: ({ name, status }) => {
      const filters: CrudFilters = [];

      filters.push(
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_status]',
          operator: 'eq',
          value: status
        }
      );
      return filters;
    }
  });

  return (
    <div>
      <SearchFilters searchFormProps={searchFormProps} />
      <AttempsTable tableProps={tableProps} />
    </div>
  );
};
