import Icon from '@ant-design/icons';
import { Card, Flex, Space, Typography } from 'antd';
import type { ReactNode } from 'react';

interface PageHeaderProps {
  title: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  meta?: ReactNode;
}

export const PageHeader = ({ title, icon, meta }: PageHeaderProps) => {
  return (
    <Card className="!mb-4">
      <Flex gap={16} align="center">
        {icon && (
          <div className="bg-primary-50 p-4 rounded-lg flex items-center justify-center">
            <Icon component={icon} className="text-2xl text-primary-500" />
          </div>
        )}

        <Flex vertical gap={16}>
          <Space size="middle" className="items-center">
            <Typography.Title level={3} className="flex-1 !mb-0">
              {title}
            </Typography.Title>
          </Space>
          {meta}
        </Flex>
      </Flex>
    </Card>
  );
};
