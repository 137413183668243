import { EditButton, TagField, TextField } from '@refinedev/antd';
import { EyeOutlined } from '@ant-design/icons';
import { Table, TableProps } from 'antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssignmentAttemptStatus } from 'services/assignments';
import type { IProfessorAreaEvaluationAttemptShow } from 'interfaces/professor_area/evaluations';

interface ProfessorAreaAssignmentsTableProps {
  dataSource: TableProps['dataSource'];
  correctionLoading: boolean;
  onAttemptClick: (record: IProfessorAreaEvaluationAttemptShow) => void;
}

export const UserAttemptsTable = ({
  dataSource,
  correctionLoading,
  onAttemptClick
}: ProfessorAreaAssignmentsTableProps) => {
  return (
    <Table
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true
      }}
    >
      <Table.Column
        title="Tentativa"
        render={(_, record, index) => (
          <TextField value={`${index + 1}ª tentativa`} />
        )}
      />
      <Table.Column
        dataIndex="created_at"
        key="created_at"
        title="Data de envio"
        render={value => (
          <TextField value={formatDate(value, 'DD/MM/YYYY hh:mm')} />
        )}
      />
      <Table.Column
        dataIndex="evaluated_at"
        key="evaluated_at"
        title="Data de correção"
        render={value => (
          <TextField
            value={value ? formatDate(value, 'DD/MM/YYYY hh:mm') : '--'}
          />
        )}
      />
      <Table.Column
        dataIndex={['evaluator', 'name']}
        key="evaluator"
        title="Corrigido por"
        render={value => <TextField value={value || '--'} />}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={tagColorByStatus(value)}
            value={translateAssignmentAttemptStatus(value)}
          />
        )}
      />
      <Table.Column
        dataIndex="score"
        key="score"
        title="Nota"
        align="center"
        render={value => <TextField value={value || '--'} />}
      />
      <Table.Column<IProfessorAreaEvaluationAttemptShow>
        title="Ações"
        align="center"
        width={77}
        render={(_, record) => {
          return (
            <EditButton
              hideText={record.status !== 'pending_evaluation'}
              onClick={() => onAttemptClick(record)}
              disabled={correctionLoading}
              loading={correctionLoading}
              size="small"
              icon={record.status !== 'pending_evaluation' && <EyeOutlined />}
            >
              Corrigir
            </EditButton>
          );
        }}
      />
    </Table>
  );
};
