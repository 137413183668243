import React from 'react';
import { Card, Progress, Typography } from 'antd';
import { Stack } from '@qcx/ui';
import { splitSubheaderData } from 'services/professor_area/evaluations';
import type { IProfessorAreaEvaluationAttemptStatus } from 'interfaces/professor_area/evaluations';

const { Title, Text } = Typography;

export interface TSubHeaderFieldsData {
  label: string;
  value: string;
}

export interface EvaluationsSubheaderProps {
  status: Partial<IProfessorAreaEvaluationAttemptStatus>;
  fieldsData: TSubHeaderFieldsData[];
  dateFields?: TSubHeaderFieldsData[];
  score?: number;
}

const statusMap: Partial<
  Record<IProfessorAreaEvaluationAttemptStatus, string>
> = {
  pending: 'AGUARDANDO CORREÇÃO',
  pending_evaluation: 'AGUARDANDO CORREÇÃO',
  scored: 'AVALIAÇÃO CORRIGIDA',
  evaluation_started: 'EM CORREÇÃO',
  passed: 'APROVADO',
  failed: 'REPROVADO',
  completed: 'AGUARDANDO CORREÇÃO'
};

export const EvaluationsSubheader: React.FC<EvaluationsSubheaderProps> = ({
  status,
  fieldsData,
  dateFields,
  score
}) => {
  const getProgressStatus = () => {
    if (status === 'passed') {
      return 'success';
    }

    if (status === 'failed') {
      return 'exception';
    }
    return 'normal';
  };

  const getPecentage = (score: number) => {
    if (status === 'passed') {
      return 100;
    }

    if (status === 'failed' || status === 'scored') {
      return score;
    }
    return 0;
  };

  return (
    <Card className="px-8 mb-4">
      <Stack className="gap-4 justify-evenly">
        <Stack
          flow="column"
          className={`items-center basis-1/${fieldsData.length}`}
        >
          <Progress
            size={80}
            type="circle"
            showInfo={['passed', 'failed', 'scored'].includes(status)}
            format={
              !['passed', 'failed'].includes(status)
                ? () => (
                    <Text type="secondary" className="text-lg">
                      {score ?? 0}
                    </Text>
                  )
                : undefined
            }
            percent={getPecentage(score ?? 0)}
            status={getProgressStatus()}
          />
          <Title level={5} className="!text-gray-600 mt-2">
            {statusMap[status]}
          </Title>
        </Stack>
        {splitSubheaderData(fieldsData).map((doubleField, indexContainer) => (
          <Stack
            key={indexContainer?.toString()}
            flow="column"
            className={`gap-2 basis-1/${fieldsData.length}`}
          >
            {doubleField.map((field, index) => (
              <Stack
                key={index?.toString()}
                flow="column"
                className="gap-2 border-l-2 border-gray-300 px-4"
              >
                <Text type="secondary">{field.label}</Text>
                <Title level={5} className="!text-gray-600 !m-0">
                  {field.value}
                </Title>
              </Stack>
            ))}
          </Stack>
        ))}
        {dateFields &&
          splitSubheaderData(dateFields).map((doubleField, indexContainer) => (
            <Stack
              key={indexContainer?.toString()}
              flow="column"
              className={`gap-2 basis-1/${fieldsData.length}`}
            >
              {doubleField.map((field, index) => (
                <Stack
                  key={index?.toString()}
                  flow="column"
                  className="gap-2 border-l-2 border-gray-300 px-4"
                >
                  <Text type="secondary">{field.label}</Text>
                  <Title level={5} className="!text-gray-600 !m-0">
                    {field.value}
                  </Title>
                </Stack>
              ))}
            </Stack>
          ))}
      </Stack>
    </Card>
  );
};
