import { LeftOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { Space, Typography } from 'antd';

interface GoBackProps {
  label?: ReactNode;
}

export const GoBack = ({ label }: GoBackProps) => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Typography.Text className="!text-neutral-500 font-normal">
      {label || 'Voltar'}
    </Typography.Text>
  </Space>
);
