import { Card, Flex, Skeleton } from 'antd';
import { EmptyState } from 'components/EmptyState';
import { GoBack } from '../GoBack';
import { ReactNode } from 'react';
import { Show, ShowProps } from '@refinedev/antd';

interface ShowLayoutProps<T> extends ShowProps {
  children: ReactNode;
  backLabel?: ReactNode;
  isLoading?: boolean;
  isFetched?: boolean;
  record?: T;
  EmptyStateComponent?: ReactNode;
}

const LoadingState = () => (
  <Flex vertical gap={12}>
    <Card className="w-full">
      <Flex gap={20} align="center">
        <Skeleton.Button
          className="!min-w-[56px] !w-[56px] !h-[56px] rounded-md"
          active
        />
        <Flex vertical gap={8} className="flex-1 !w-full">
          <Skeleton.Input active block size="large" />
          <Flex gap={8}>
            <Skeleton.Button className="!w-20 !h-3" active />
            <Skeleton.Button className="!w-20 !h-3" active />
            <Skeleton.Button className="!w-20 !h-3" active />
          </Flex>
        </Flex>
      </Flex>
    </Card>
    <Card loading>
      <Skeleton />
    </Card>
  </Flex>
);

export const ShowLayout = <T,>({
  children,
  backLabel,
  isLoading,
  isFetched,
  record,
  EmptyStateComponent = <EmptyState description="Nenhum dado encontrado" />,
  ...props
}: ShowLayoutProps<T>) => {
  const renderContent = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    if (isFetched && !record) {
      return EmptyStateComponent;
    }
    return children;
  };

  return (
    <Show
      {...props}
      breadcrumb={false}
      headerProps={{
        title: false
      }}
      goBack={<GoBack label={backLabel || 'Voltar'} />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          cover: {
            background: 'red'
          },
          body: {
            padding: 0,
            backgroundColor: 'transparent',
            height: '100%'
          }
        }
      }}
    >
      <Flex vertical gap={12}>
        {renderContent()}
      </Flex>
    </Show>
  );
};
