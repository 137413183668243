import { Button, Card, Empty, Flex, Modal, Pagination, Typography } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { HttpError, useDelete } from '@refinedev/core';
import { ModalCreateAssigment } from './ModalCreateAssignment/ModalCreateAssignment';
import { TabAssignmentListItem } from './TabAssignmentsListItem';
import { useCallback } from 'react';
import { useModalForm } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import type { IAssignment } from 'interfaces/assignments';
import type { IProfessorAreaEvaluationAssignmentCreate } from 'interfaces/professor_area/assignments';
import type { IProfessorAreaEvaluationsList } from 'interfaces/professor_area/evaluations';
import type { TableProps } from 'antd/lib';

interface AssignmentItemProps {
  tableProps: TableProps<IProfessorAreaEvaluationsList>;
  onChangePage: (page: number) => void;
  refetch: () => void;
}

export const TabAssignmentsList = ({
  tableProps,
  onChangePage,
  refetch
}: AssignmentItemProps) => {
  const params = useParams();
  const classroomId = params.id!;

  const { pagination, dataSource: items, loading } = tableProps;

  const { confirm } = Modal;

  const { mutateAsync: deleteEvaluation } = useDelete<IAssignment>();

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    close: createModalClose,
    formLoading
  } = useModalForm<
    IProfessorAreaEvaluationAssignmentCreate,
    HttpError,
    IProfessorAreaEvaluationAssignmentCreate
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations`,
    action: 'create',
    redirect: false,
    autoResetForm: true,
    onMutationSuccess: () => {
      onChangePage(1);
    },
    successNotification: () => ({
      type: 'success',
      message: 'Aviso cadastrado com sucesso',
      description: 'Sucesso!'
    }),
    errorNotification: () => ({
      type: 'error',
      message: 'Não foi possível cadastradar o aviso. Tente novamente!',
      description: 'Algo deu errado!'
    })
  });

  const handleRemoveEvaluation = useCallback(
    (evaluationId: string) => {
      confirm({
        title: 'Deseja excluir essa atividade?',
        icon: <ExclamationCircleOutlined className="!text-red-500" />,
        content: 'Essa ação não pode ser revertida',
        okText: 'Excluir',
        cancelText: 'Voltar',
        okButtonProps: {
          danger: true
        },
        onOk() {
          deleteEvaluation({
            id: evaluationId,
            resource: `professor_area/classrooms/${classroomId}/evaluations`,
            successNotification: () => ({
              type: 'success',
              description: 'Sucesso!',
              message: 'Atividade excluída com sucesso.'
            }),
            errorNotification: () => ({
              type: 'error',
              description: 'Ops!',
              message:
                'Não foi possível excluir esta atividade. Tente novamente.'
            })
          }).then(() => {
            refetch();
          });
        }
      });
    },
    [classroomId, confirm, deleteEvaluation, refetch]
  );

  if (loading) {
    return <Card loading={Boolean(loading)} />;
  }

  return (
    <>
      <Flex justify="space-between" align="center" className="mb-4">
        <Typography.Title level={5}>Atividades da turma</Typography.Title>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => createModalShow()}
        >
          Adicionar atividade
        </Button>
      </Flex>

      {items && items.length > 0 ? (
        <div>
          <Flex vertical>
            {items.map(evaluation => (
              <TabAssignmentListItem
                key={evaluation.id}
                item={evaluation}
                onRemove={handleRemoveEvaluation}
              />
            ))}
          </Flex>
        </div>
      ) : (
        <Empty />
      )}

      <Pagination
        className="justify-self-end mt-4"
        hideOnSinglePage
        {...pagination}
        onChange={onChangePage}
      />

      {createModalProps.open && (
        <ModalCreateAssigment
          modalProps={createModalProps}
          formProps={createFormProps}
          classroomId={classroomId}
          isLoading={formLoading}
          onCloseModal={createModalClose}
        />
      )}
    </>
  );
};
