import {
  ProfessorAreaAssignmentAttemptEdit,
  ProfessorAreaAssignmentAttemptShow
} from 'pages/teacher_space/classrooms/components/TabAssignments/assignments/assignment_attempts';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'professor_area/classroom_assignments',
  label: 'Atividade do aluno',
  show: ProfessorAreaAssignmentAttemptShow,
  edit: ProfessorAreaAssignmentAttemptEdit,
  actions: ['show', 'edit', 'list'],
  parentName: 'professor_area/classroom_assignments',
  route:
    'professor_area/classrooms/:classroomId/assignments/:evaluationId/attempt'
};

export default generateResources(resource);
