import { Alert, Button, Flex, Form, Input, InputNumber, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormItemCol } from './FormItemCol';
import { IAssignmentAttempt } from 'interfaces/assignments';
import { ResponseError } from 'interfaces/api-error';
import { useCallback, useState } from 'react';
import { useModalReturnType, useUpdate } from '@refinedev/core';

interface ModalEditEvaluationScoreProps<T> {
  attempt: T;
  modal: useModalReturnType;
  resource: string;
}

export const ModalEditEvaluationScore = <T,>({
  attempt,
  modal,
  resource
}: ModalEditEvaluationScoreProps<T>) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: assignmentMutateAsync } =
    useUpdate<IAssignmentAttempt>();

  const onMutateScore = useCallback(async () => {
    const values = {
      score: Number(form.getFieldValue('score')),
      justification: form.getFieldValue('justification')
    };

    await assignmentMutateAsync({
      resource,
      id: attempt.id,
      values,
      errorNotification: error => {
        const err = error as unknown as ResponseError;

        if ('code' in err && err.code === 'classrooms_not_available_error') {
          return {
            type: 'error',
            description: 'Turma não ativa',
            message:
              'Não é possível editar a nota pois a turma vinculada a essa avaliação não está mais ativa!'
          };
        }

        return {
          type: 'error',
          description: 'Ops! Algo de errado',
          message: 'Não foi possível editar a nota.'
        };
      },
      successNotification: () => ({
        type: 'success',
        description: 'Sucesso!',
        message: 'Nota alterada com sucesso'
      })
    });

    modal.close();
    form.resetFields();
  }, [assignmentMutateAsync, attempt.id, form, modal, resource]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await onMutateScore();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [form, onMutateScore]);

  return (
    <Modal
      title="Editar nota da atividade"
      open={modal.visible}
      onCancel={modal.close}
      footer={[
        <Button key="back" onClick={() => modal.close()} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Alert
          message={
            <Flex gap={8} align="self-start">
              <ExclamationCircleFilled className="mt-1 text-blue-500" />
              Só é permitido a edição de nota de uma atividade que pertença a
              uma turma ativa.
            </Flex>
          }
          className="my-5"
        />

        <FormItemCol
          cols={[6, 18]}
          name="score"
          label="Nota"
          initialValue={attempt.score}
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            },
            {
              validator(rule, value, callback) {
                if (value === attempt.score) {
                  callback('A nota deve ser diferente da atual');
                }

                callback();
              }
            }
          ]}
        >
          <InputNumber max={100} min={0} size="large" />
        </FormItemCol>

        <FormItemCol
          cols={[6, 18]}
          name="justification"
          label="Justificativa"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            }
          ]}
        >
          <Input.TextArea rows={4} size="large" />
        </FormItemCol>
      </Form>
    </Modal>
  );
};
