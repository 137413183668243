import {
  Button,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  Radio,
  Row,
  Typography
} from 'antd';
import { DateRangeFormField } from 'components/professor_area/DateRangeFormField';
import { FormItemAssignmentCriteria } from 'components/professor_area/FormItemAssignmentCriteria';
import { FormItemAssignmentInstruction } from 'components/professor_area/FormItemAssignmentIntruction';
import { FormItemCol } from 'components/professor_area/FormItemCol';
import { getEvaluationPeriod } from 'services/classroom/evaluations';
import { useEffect, useMemo } from 'react';
import type { Dayjs } from 'dayjs';
import type {
  IProfessorAreaEvaluationEdit,
  IProfessorAreaEvaluationShow
} from 'interfaces/professor_area/evaluations';

interface TabGeneralProps {
  formProps: FormProps<IProfessorAreaEvaluationEdit>;
  data: IProfessorAreaEvaluationShow;
  isLoading: boolean;
  onSubmit: () => void;
}

export const TabGeneral = ({
  formProps,
  data,
  isLoading,
  onSubmit
}: TabGeneralProps) => {
  const { dateRange: requiredDates } = getEvaluationPeriod(data.requirements);

  useEffect(() => {
    if (requiredDates) {
      formProps.form?.setFieldValue('starts_at', requiredDates[0]);
      formProps.form?.setFieldValue('ends_at', requiredDates[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateRange = useMemo(() => {
    if (!requiredDates) {
      return [];
    }

    return [requiredDates[0] as Dayjs, requiredDates[1] as Dayjs];
  }, [requiredDates]);

  const kind = useMemo(
    () => formProps.form?.getFieldValue('kind'),
    [formProps.form]
  );

  return (
    <Form
      {...formProps}
      form={formProps.form}
      layout="vertical"
      initialValues={{
        ...formProps.initialValues,
        starts_at: dateRange[0],
        ends_at: dateRange[1]
      }}
    >
      <Form.Item
        label="Nome da atividade"
        name="name"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Descrição" name={['evaluation', 'description']}>
        <Input.TextArea rows={2} disabled />
      </Form.Item>

      <Form.Item>
        <Row>
          <Col md={5} className="ant-form-item-label">
            <label htmlFor="kind">Modalidade</label>
          </Col>
          <Col md={19}>
            <Form.Item noStyle name={'kind'}>
              <Radio.Group
                options={[
                  { label: 'Avaliação padrão', value: 'regular' },
                  {
                    label: 'Avaliação de recuperação',
                    value: 'final'
                  }
                ]}
                optionType="button"
                buttonStyle="outline"
                disabled
              />
            </Form.Item>
            <Typography.Text className="block text-xs mt-2 text-neutral-500">
              Atividade de recuperação deve acontecer após o término de todas as
              outras atividades
            </Typography.Text>
          </Col>
        </Row>
      </Form.Item>

      {kind !== 'final' && (
        <FormItemCol
          label="Peso"
          name="weight"
          cols={[5, 19]}
          itemsCenter
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input type="number" className="max-w-[80px]" />
        </FormItemCol>
      )}

      <FormItemCol
        label="Nota mínima (0-100)"
        name="passing_score"
        itemsCenter
        cols={[5, 19]}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input type="number" className="max-w-[80px]" min={0} max={100} />
      </FormItemCol>

      <FormItemCol
        label="Tentativas"
        name="max_attempts"
        itemsCenter
        cols={[5, 19]}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input type="number" className="max-w-[80px]" />
      </FormItemCol>

      <Divider />

      <fieldset>
        <div>
          <Typography.Title level={5}>Período de acesso</Typography.Title>
          <Typography.Paragraph className="text-neutral-500">
            O período de acesso da avaliação deve está entre o inicio e fim da
            turma (dd/mm/aaaa - dd/mm/aaaa)
          </Typography.Paragraph>
        </div>

        <DateRangeFormField
          startRangeDatafield="starts_at"
          endRangeDatafield="ends_at"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório'
            }
          ]}
        />
      </fieldset>

      <Divider />

      <fieldset>
        <Typography.Title level={4} className="!mb-8">
          Instruções e critérios
        </Typography.Title>

        <Typography.Title level={5} className="mb-4">
          Intruções
        </Typography.Title>

        <FormItemAssignmentInstruction
          fieldName={['evaluation', 'instructions']}
          disabled
        />

        <Divider />

        <Typography.Title level={5} className="mb-4">
          Critérios
        </Typography.Title>

        <FormItemAssignmentCriteria
          fieldName={['evaluation', 'criteria']}
          disabled
        />
      </fieldset>

      <div className="flex justify-end border-t -mx-6 px-4 pt-4">
        <Button
          type="primary"
          size="large"
          onClick={onSubmit}
          loading={isLoading}
        >
          Salvar
        </Button>
      </div>
    </Form>
  );
};
