import { ATTEMPTS_STATUS_MAP } from 'services/assignments';
import { Button, Col, Form, FormProps, Input, Row, Select } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

interface TabAttemptsFilterProps {
  searchFormProps: FormProps;
}
export interface TabAttemptsFilters {
  by_grandparent_id?: string;
  by_title?: string;
  by_status?: string;
}

export const TabAttemptsFilter = ({
  searchFormProps
}: TabAttemptsFilterProps) => {
  const handleClear = useCallback(() => {
    searchFormProps.form?.resetFields();
  }, [searchFormProps]);

  return (
    <Form {...searchFormProps} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={9}>
          <Form.Item name="by_title">
            <Input
              placeholder="Buscar por nome do aluno"
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name="by_status">
            <Select
              placeholder="Todos os status"
              defaultValue={null}
              allowClear
            >
              {[...ATTEMPTS_STATUS_MAP].map(item => (
                <Select.Option key={item[0]}>{item[1]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={9}>
          <Button
            type="default"
            className="mr-2"
            htmlType="submit"
            icon={<FilterOutlined />}
          >
            Filtrar
          </Button>

          <Button type="text" onClick={handleClear}>
            Limpar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
