import { ASSIGNMENT_KIND_MAP } from 'services/assignments';
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { CardItem } from 'components/professor_area/CardItem';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Space, Tag, Typography } from 'antd';
import { formatDate } from 'services/date';
import { useCallback, useMemo } from 'react';
import { useNavigation } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import type { IProfessorAreaEvaluationsList } from 'interfaces/professor_area/evaluations';
import type { MenuProps } from 'antd/lib';

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar tentativas',
    icon: <EyeOutlined />
  },
  {
    key: 'edit',
    label: 'Editar atividade',
    icon: <EditOutlined />
  },
  {
    key: 'delete',
    label: 'Excluir atividade',
    icon: <DeleteOutlined />
  }
];

interface AssignmentItemProps {
  item: IProfessorAreaEvaluationsList;
  onRemove: (evaluationId: string) => void;
}

export const TabAssignmentListItem = ({
  item,
  onRemove
}: AssignmentItemProps) => {
  const navigate = useNavigation();
  const params = useParams();
  const classroomId = params.id;

  const { editUrl } = navigate;

  const urlEdit = useMemo(() => {
    return editUrl(`professor_area/classroom_assignments`, item.id, {
      classroomId
    });
  }, [classroomId, editUrl, item.id]);

  const handleMenuClick = useCallback(
    (event: MenuInfo) => {
      switch (event.key) {
        case 'show': {
          navigate.push(`${urlEdit}?tab=attempts`);
          break;
        }

        case 'edit': {
          navigate.edit(
            `professor_area/classroom_assignments`,
            item.id,
            'push',
            {
              classroomId
            }
          );

          break;
        }

        case 'delete': {
          onRemove(item.id);
          break;
        }

        default: {
          break;
        }
      }
    },
    [classroomId, item.id, navigate, onRemove, urlEdit]
  );

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick
  };

  const evaluationDate = useMemo(() => {
    const { rules } = item.requirements[0] || { rules: [] };

    const starts_at =
      rules.find(rule => rule.operator === 'after')?.value || '';
    const ends_at = rules.find(rule => rule.operator === 'before')?.value || '';

    return {
      starts_at,
      ends_at
    };
  }, [item.requirements]);

  return (
    <CardItem
      icon={FileTextOutlined}
      title={item.name}
      menuProps={menuProps}
      meta={
        <>
          <Typography.Text>{ASSIGNMENT_KIND_MAP[item.kind]}</Typography.Text>

          {item.kind !== 'final' && (
            <Typography.Text>Peso {item.weight}</Typography.Text>
          )}

          <Space size="small">
            <CalendarOutlined />
            <Typography.Text>
              {formatDate(evaluationDate.starts_at, 'DD/MM/YYYY')}
              {' > '}
              {formatDate(evaluationDate.ends_at, 'DD/MM/YYYY')}
            </Typography.Text>
          </Space>
        </>
      }
      extraContent={
        <Tag color="orange">
          {item.pending_evaluation_attempts_count ?? '0'} atividades a corrigir
        </Tag>
      }
    />
  );
};
