import { HttpError, useModalReturnType } from '@refinedev/core';
import { IAssignmentAttempt } from 'interfaces/assignments';
import { Modal, Table } from 'antd';
import { TagField, TextField, useTable } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssignmentAttemptStatus } from 'services/assignments';
import { translateEventStatus } from 'services/documentations';

interface ModalEvaluationHistoryProps {
  modal: useModalReturnType;
  resource: string;
  attemptId: string;
}

export const ModalEvaluationHistory = ({
  modal,
  resource,
  attemptId
}: ModalEvaluationHistoryProps) => {
  const { tableProps } = useTable<IAssignmentAttempt, HttpError>({
    resource
  });

  console.log(attemptId);

  return (
    <Modal
      title="Histórico de notas"
      width={1200}
      open={modal.visible}
      onCancel={modal.close}
      footer={null}
    >
      <Table
        size="small"
        {...tableProps}
        pagination={{ hideOnSinglePage: true }}
        columns={[
          {
            title: 'Evento',
            dataIndex: 'event',
            key: 'event',
            render: (event: string) => (
              <TextField value={translateEventStatus(event)} />
            )
          },
          {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            render: createdAt => (
              <TextField value={formatDate(createdAt, 'lll')} />
            )
          },
          {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: author => <TextField value={author.name} />
          },
          {
            title: 'Nota',
            dataIndex: 'score',
            key: 'score',
            render: score => <TextField value={score || '-'} />
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              <>
                {status ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De:{' '}
                      <TagField
                        color={tagColorByStatus(status[0])}
                        value={translateAssignmentAttemptStatus(status[0])}
                      />
                    </span>
                    <span>
                      Para:{' '}
                      <TagField
                        color={tagColorByStatus(status[1])}
                        value={translateAssignmentAttemptStatus(status[1])}
                      />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          },
          {
            title: 'Justificativa',
            dataIndex: 'justification',
            key: 'justification',
            render: justification => (
              <>
                {justification ? (
                  <div className="flex flex-col gap-1">
                    <span>
                      De: <TextField value={justification[0] || '-'} />
                    </span>
                    <span>
                      Para: <TextField value={justification[1] || '-'} />
                    </span>
                  </div>
                ) : (
                  '-'
                )}
              </>
            )
          }
        ]}
      />
    </Modal>
  );
};
