import axios from 'axios';
import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps
} from 'antd';
import { FormItem } from './FormItem';
import { ISupplementaryMaterial } from 'interfaces/supplementary_materials';
import { InboxOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useCreate } from '@refinedev/core';

const { Dragger } = Upload;

interface SupplementarMaterialUploadModalProps {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: () => void;
  classroomId: string;
  getSavedItems: () => void;
}

interface Asset {
  url: string;
  upload_url: string;
  download_url: string;
}

export const SupplementarMaterialUploadModal = ({
  modalProps,
  formProps,
  classroomId,
  getSavedItems,
  closeModal
}: SupplementarMaterialUploadModalProps) => {
  const { mutate } = useCreate<Asset>();
  const [file, setFile] = useState<UploadFile | null>();
  const [fileUrl, setFileUrl] = useState('');
  const { mutateAsync: createSupplementaryMaterial } =
    useCreate<ISupplementaryMaterial>();

  const createContent = () =>
    new Promise<Asset>((resolve, reject) => {
      mutate(
        {
          resource: 'content_files',
          values: {
            type: 'content_supplementary_material',
            extension: 'pdf'
          },
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Upload de arquivo realizado com sucesso'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message:
              'Não foi possível realizar o upload do arquivo. Tente novamente.'
          })
        },
        {
          onSuccess: ({ data }) => resolve(data),
          onError: error => reject(error)
        }
      );
    });

  const upload = async () => {
    const { url, upload_url } = await createContent();

    await axios.put(upload_url, file);
    setFileUrl(url);
    return 'done';
  };

  const props: UploadProps = {
    name: 'supplementary_material',
    accept: 'application/pdf',
    fileList: file ? [file] : [],
    onRemove: () => setFile(null),
    beforeUpload: newFile => {
      setFile(newFile);
      return false;
    },
    onChange: info => {
      if (info.file.status === 'removed') {
        return;
      }
      upload();
    }
  };

  const handleFinish = useCallback(async () => {
    const item = await formProps.form?.validateFields();

    const values = {
      title: item.name,
      url: fileUrl,
      parent_reference: classroomId,
      parent_kind: 'classroom'
    };

    await createSupplementaryMaterial({
      resource: 'supplementary_materials',
      values,
      successNotification: () => ({
        message: 'Material complementar enviado com sucesso!',
        type: 'success'
      }),
      errorNotification: () => {
        return {
          message:
            'Erro ao enviar o material. Verifique o formato e tente novamente.',
          description: 'Algo deu errado!',
          type: 'error'
        };
      }
    });

    getSavedItems();
    setFile(null);
    formProps.form?.resetFields();
    closeModal();
  }, [
    formProps,
    classroomId,
    fileUrl,
    getSavedItems,
    closeModal,
    createSupplementaryMaterial
  ]);

  return (
    <Modal
      {...modalProps}
      title="Upload de um Material"
      okText="Salvar"
      cancelText="Cancelar"
      className="max-w-[700px]"
    >
      <Space direction="vertical" className="w-full gap-4">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste para adicionar o arquivo
          </p>
          <p className="ant-upload-hint">Formato suportado: PDF</p>
        </Dragger>

        <Form {...formProps} onFinish={handleFinish}>
          <Typography.Text type="secondary">
            Dê um nome para este arquivo
          </Typography.Text>
          <FormItem
            name="name"
            rules={[
              { required: true, message: 'O nome do arquivo é obrigatório' }
            ]}
          >
            <Input placeholder="Ex.: material complementar da aula 01" />
          </FormItem>
        </Form>
      </Space>
    </Modal>
  );
};
