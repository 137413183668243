import clsx from 'clsx';
import { Avatar, Button, Dropdown, Modal, Space, Typography } from 'antd';
import { BaseKey, useDelete, useNotification } from '@refinedev/core';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  PlayCircleOutlined
} from '@ant-design/icons';
import { IDamVideo } from 'interfaces/dam_videos';
import { IInteractiveClass } from 'interfaces/interactive_classes';
import { MenuProps } from 'antd/lib';
import { TagField } from '@refinedev/antd';
import {
  downloadReportFile,
  getStatusColor,
  translateStatus
} from 'services/professor_area/interactive-classes';
import { formatDate } from 'services/date';
import { getAPI } from 'requests/api';
import { useCallback, useState } from 'react';

const { confirm } = Modal;
export interface TableRowItemProps {
  showModal: (isEdit: boolean, id?: BaseKey) => () => void;
  previewModal: (videoData: IDamVideo) => () => void;
  interactiveClass: IInteractiveClass;
  refetch: () => void;
}

export const TableRowItem = ({
  showModal,
  refetch,
  previewModal,
  interactiveClass
}: TableRowItemProps) => {
  const [hovered, setHover] = useState(false);
  const notification = useNotification();
  const { mutateAsync: deleteInteractiveClass } =
    useDelete<IInteractiveClass>();

  const handleLinkAction = useCallback(() => {
    window.open(interactiveClass?.join_url);
  }, [interactiveClass?.join_url]);

  const confirmCancel = () => {
    confirm({
      title: 'Cancelar aula interativa?',
      icon: <ExclamationCircleFilled className="!text-[red]" />,
      content: 'Tem certeza que deseja cancelar a aula ?',
      okText: 'Cancelar',
      cancelText: 'Voltar',
      okButtonProps: { type: 'primary' },
      onOk() {
        deleteInteractiveClass({
          id: interactiveClass?.id,
          resource: 'professor_area/interactive_classes',
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Aula interativa cancelada com sucesso.'
          }),
          errorNotification: () => ({
            type: 'error',
            description: 'Ops!',
            message:
              'Não foi possível cancelar a aula interativa. Tente novamente.'
          })
        }).then(() => {
          refetch();
        });
      }
    });
  };

  const handlePreviewData = useCallback(async () => {
    const videoData = await getAPI()
      .get(`admin/prometheus/videos/${interactiveClass?.video_id}`)
      .catch(() => {
        notification.open?.({
          type: 'error',
          message: 'Error!',
          description: 'Não foi possivel carregar dados do video.',
          key: `${interactiveClass?.video_id}-error-get`
        });
      });

    previewModal(videoData?.data);
  }, [previewModal, interactiveClass, notification]);

  const confirmJoinClass = () => {
    confirm({
      title: `${
        interactiveClass?.join_url
          ? 'Tem certeza que deseja iniciar a aula e entrar na sala?'
          : 'Aguarde...'
      }`,
      content: `${
        !interactiveClass?.join_url
          ? 'A aula ainda não possui link de acesso'
          : ''
      }`,
      icon: <ExclamationCircleFilled />,
      okText: `${interactiveClass?.join_url ? 'Entrar na sala' : 'Ok'}`,
      cancelText: 'Voltar',
      okButtonProps: { type: 'primary' },
      onOk() {
        if (interactiveClass?.join_url) {
          handleLinkAction();
        }
      }
    });
  };

  const defaultDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<PlayCircleOutlined className="!text-[#00000073]" />}
          onClick={() => confirmJoinClass()}
        >
          Entrar na sala
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EyeOutlined className="!text-[#00000073]" />}
          onClick={showModal(false, interactiveClass.id)}
        >
          Ver dados da aula
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EditOutlined className="!text-[#00000073]" />}
          onClick={showModal(true, interactiveClass.id)}
        >
          Editar aula
        </Button>
      )
    },
    {
      key: '4',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<CloseCircleOutlined className="!text-[#00000073]" />}
          onClick={() => confirmCancel()}
        >
          Cancelar aula
        </Button>
      )
    }
  ];

  const finishedDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<EyeOutlined className="!text-[#00000073]" />}
          onClick={showModal(false, interactiveClass.id)}
        >
          Ver dados da aula
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<PlayCircleOutlined className="!text-[#00000073]" />}
          onClick={handlePreviewData}
        >
          Assistir aula gravada
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className="!text-[#000000E0]"
          type="link"
          icon={<DownloadOutlined className="!text-[#00000073]" />}
          onClick={() => downloadReportFile(interactiveClass.id, notification)}
        >
          Baixar relatório de presença
        </Button>
      )
    }
  ];

  return (
    <Space className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100">
      <Space className="!gap-4">
        <Space
          direction="vertical"
          className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#E6F4FF] justify-center items-center !gap-0"
        >
          <Typography.Text className="!m-0 !text-[#1677FF]">
            {formatDate(interactiveClass.start_time, 'DD')}
          </Typography.Text>
          <Typography.Text className="!m-0 !text-[#1677FF] uppercase">
            {formatDate(interactiveClass.start_time, 'MMM')}
          </Typography.Text>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={5} className="!m-0">
            {interactiveClass.title}
          </Typography.Title>
          <Space>
            <Space className="!text-[#000000A6]">
              <CalendarOutlined />
              <Typography.Text className="!m-0 !text-[#000000A6]">
                {formatDate(interactiveClass.start_time, 'DD/MM/YYYY HH:mm')}
              </Typography.Text>
            </Space>
            <Space className="!text-[#000000A6]">
              <ClockCircleOutlined />
              <Typography.Text className="!m-0 !text-[#000000A6]">
                {interactiveClass.duration_in_minutes / 60}h
              </Typography.Text>
            </Space>
            <Space>
              <Avatar.Group
                size={'small'}
                maxStyle={{ color: '#000000E0', backgroundColor: '#F0F0F0' }}
                maxCount={3}
              >
                {interactiveClass.professors.map(professor => (
                  <Avatar
                    key={professor.id}
                    className="uppercase"
                    style={{ color: '#1677FF', backgroundColor: '#E6F4FF' }}
                  >
                    {professor.name
                      .split(' ', 2)
                      .map(word => word[0])
                      .join('')
                      .toUpperCase()}
                  </Avatar>
                ))}
              </Avatar.Group>
            </Space>
          </Space>
        </Space>
      </Space>
      <Space>
        <TagField
          value={translateStatus(interactiveClass.status)}
          color={getStatusColor(interactiveClass.status)}
        />
        <Space className="min-w-[40px]">
          {interactiveClass.status !== 'cancelled' && (
            <Dropdown
              menu={
                {
                  items:
                    interactiveClass.status === 'finished'
                      ? finishedDropdownItems
                      : defaultDropdownItems
                } as MenuProps
              }
              placement="bottomLeft"
              onOpenChange={setHover}
              className={clsx(
                'opacity-0 group-hover:opacity-100 group-active:opacity-100',
                hovered && 'opacity-100'
              )}
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          )}
        </Space>
      </Space>
    </Space>
  );
};
