import useNotification from 'hooks/useNotification';
import {
  Avatar,
  Button,
  Card,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Tag,
  Typography
} from 'antd';
import {
  DownloadOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { EditLayout } from 'components/professor_area/layout/EditLayout';
import { FormItemCol } from 'components/professor_area/FormItemCol';
import { HttpError, useBack, useOne } from '@refinedev/core';
import { getInitialsName } from 'utils/get-initials-name';
import { useCallback, useMemo } from 'react';
import { useForm } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import { useProfessorAreaAssignmentsStore } from '../assignments.store';
import type {
  IProfessorAreaEvaluationAttemptCorrection,
  IProfessorAreaEvaluationAttemptShow
} from 'interfaces/professor_area/evaluations';
import type { IUser } from 'interfaces/users';

const itemsDropdown: MenuProps['items'] = [
  {
    key: 'info',
    icon: <InfoCircleOutlined />,
    label: 'Infomações da atividade'
  },
  {
    key: 'history',
    icon: <HistoryOutlined />,
    label: 'Visualizar histórico'
  }
];

export const ProfessorAreaAssignmentAttemptEdit = () => {
  const params = useParams();
  const classroomId = params.classroomId;
  const evaluationId = params.evaluationId;
  const id = params.id;

  const back = useBack();
  const { showError } = useNotification();

  const selectedEvaluation = useProfessorAreaAssignmentsStore(
    s => s.selectedEvaluation
  );

  const { formProps, queryResult } = useForm<
    IProfessorAreaEvaluationAttemptShow,
    HttpError,
    IProfessorAreaEvaluationAttemptCorrection
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts`,
    action: 'edit',
    id
  });

  const { data: studentDetails } = useOne<IUser>({
    resource: `users`,
    id: queryResult?.data?.data?.user_id || ''
  });

  const studentName = useMemo(() => {
    if (studentDetails) {
      return studentDetails.data.name;
    }

    return '';
  }, [studentDetails]);

  const { isLoading, isFetched, data } = queryResult!;
  const record = useMemo(() => data?.data, [data]);

  const isEdit = useMemo(() => !!record?.score, [record]);

  const menu = {
    items: itemsDropdown,
    onClick: () => null
  };

  const onSubmit = useCallback(async () => {
    try {
      if (!formProps.form) {
        return;
      }

      const { score, feedback } = await formProps.form?.validateFields();

      if (score && feedback) {
        formProps.form?.submit();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showError('Não foi possivel realizar a correção. Tente novamente!');
    }
  }, [formProps.form, showError]);

  return (
    <EditLayout
      isFetched={isFetched}
      isLoading={isLoading}
      record={record}
      footerButtonProps={{ hidden: !!record?.score }}
      footerButtons={saveButtonProps => (
        <Flex gap={8}>
          <Button onClick={() => back()} disabled={isLoading}>
            Voltar
          </Button>
          <Button
            type="primary"
            {...saveButtonProps}
            onClick={onSubmit}
            disabled={isLoading}
          >
            Salvar correção
          </Button>
        </Flex>
      )}
    >
      <Card>
        <Flex align="center" justify="space-between">
          <Flex vertical gap={8} flex={1}>
            <Flex align="center" gap={8}>
              <Avatar className="bg-primary-500/10 text-primary-500">
                {getInitialsName(studentName)}
              </Avatar>
              <Typography.Text className="text-gray-400">
                {selectedEvaluation?.user?.name}
              </Typography.Text>
            </Flex>

            <Typography.Title level={3} className="!mb-0">
              {selectedEvaluation?.evaluation.name}
            </Typography.Title>

            <Flex>
              <Tag>Atividade</Tag>
              <Tag>{selectedEvaluation?.classroom.code}</Tag>
              <Tag>{selectedEvaluation?.classroom.discipline}</Tag>
            </Flex>
          </Flex>

          <Dropdown menu={menu}>
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Flex>
      </Card>

      <Card>
        <Form {...formProps} className="max-w-3xl">
          <Typography.Text
            className="block font-semibold mb-6"
            type="secondary"
          >
            Respostas
          </Typography.Text>

          {record?.criteria_files?.map((item, index) => (
            <FormItemCol
              key={item.id}
              label={`Critério ${index + 1}`}
              name="answer"
              itemsCenter={false}
            >
              <Flex vertical gap={8} className="flex-start">
                <Input value={item.name} readOnly disabled />
                <Button icon={<DownloadOutlined />} className="self-start">
                  Resposta do aluno
                </Button>
              </Flex>
            </FormItemCol>
          ))}

          <Divider />

          <FormItemCol
            label="Nota (0-100)"
            name="score"
            itemsCenter
            rules={[
              { required: true, message: 'Campo obrigatório' },
              {
                type: 'number',
                min: 0,
                max: 100,
                message: 'A nota deve estar entre 0 e 100'
              }
            ]}
          >
            <InputNumber
              className="max-w-[80px]"
              size="large"
              min={0}
              max={100}
              disabled={isEdit}
            />
          </FormItemCol>

          <FormItemCol
            label="Comentário"
            name="feedback"
            itemsCenter={false}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input.TextArea rows={4} size="large" disabled={isEdit} />
          </FormItemCol>
        </Form>
      </Card>
    </EditLayout>
  );
};
