import {
  CalendarOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { CardItem } from 'components/professor_area/CardItem';
import { IProfessorAreaNoticeBoardShow } from 'interfaces/professor_area/notice_boards';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Space, Tag, Typography } from 'antd';
import { formatDate } from 'services/date';
import { useCallback } from 'react';
import type { MenuProps } from 'antd/lib';

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar aviso',
    icon: <EyeOutlined />
  },
  {
    key: 'delete',
    label: 'Excluir aviso',
    icon: <DeleteOutlined />
  }
];

interface NoticeBoardItemProps {
  notice: IProfessorAreaNoticeBoardShow;
  onShow: (noticeId: string) => void;
  onRemove: (noticeId: string) => void;
}

export const NoticeBoardItem = ({
  notice,
  onShow,
  onRemove
}: NoticeBoardItemProps) => {
  const handleMenuClick = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'show') {
        onShow(notice.id);
      } else {
        onRemove(notice.id);
      }
    },
    [notice.id, onRemove, onShow]
  );

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick
  };

  return (
    <CardItem
      icon={CalendarOutlined}
      title={notice.title}
      menuProps={menuProps}
      meta={
        <Space>
          <CalendarOutlined />
          <Typography.Text>
            {formatDate(notice.created_at, 'DD/MM/YYYY')}
          </Typography.Text>
        </Space>
      }
      extraContent={
        <Tag color={notice.priority === 'high' ? 'red' : 'default'}>
          Prioridade {notice.priority === 'high' ? 'alta' : 'padrão'}
        </Tag>
      }
    />
  );
};
