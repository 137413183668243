import { Button, Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useCustom } from '@refinedev/core';

export const ModalContent = ({ gatewayId }: { gatewayId: string }) => {
  const { data, isLoading, isError } = useCustom({
    url: `/admin/franchises/${gatewayId}/authentication_link`,
    method: 'get',
    queryOptions: {
      retry: 0
    },
    errorNotification: false
  });

  return (
    <div>
      {isLoading && <span>Carregando...</span>}
      {isError && <span>Erro ao carregar link</span>}
      {data?.data?.data && (
        <Input.Group compact>
          <Input style={{ width: '90%' }} value={data?.data?.data} readOnly />
          <Tooltip title="Copiar link">
            <Button
              icon={<CopyOutlined />}
              onClick={() => navigator.clipboard.writeText(data?.data?.data)}
            />
          </Tooltip>
        </Input.Group>
      )}
    </div>
  );
};
