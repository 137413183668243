import { CardTabListType } from 'antd/lib/card';
import { TResourceAction } from 'interfaces/resources';
import { getTenant } from 'services/tenants';
import { useCanMultiple } from 'components/layout/sider';

export interface Tab {
  key: string;
  label: string;
  resource: `professor_area/${string}`;
  action: TResourceAction;
}

export const tabs: Tab[] = [
  {
    key: 'general',
    label: 'Visão geral',
    resource: 'professor_area/classrooms',
    action: 'list'
  },
  {
    key: 'content',
    label: 'Conteúdo',
    resource: 'professor_area/classrooms',
    action: 'list'
  },
  {
    key: 'interactiveClass',
    label: 'Aulas interativas',
    resource: 'professor_area/interactive_classes',
    action: 'list'
  },
  {
    key: 'assignments',
    label: 'Atividades',
    resource: 'professor_area/classroom_assignments',
    action: 'list'
  },
  {
    key: 'evaluations',
    label: 'Avaliações',
    resource: 'professor_area/classroom_evaluations',
    action: 'show'
  },
  {
    key: 'forums',
    label: 'Fórum',
    resource: 'professor_area/forums',
    action: 'list'
  },
  {
    key: 'noticeBoard',
    label: 'Mural de avisos',
    resource: 'professor_area/notice_boards',
    action: 'list'
  },
  {
    key: 'supplementaryMaterials',
    label: 'Material complementar',
    resource: 'professor_area/classrooms',
    action: 'list'
  },
  {
    key: 'students',
    label: 'Alunos',
    resource: 'professor_area/classroom_enrollments',
    action: 'list'
  }
];

export const useShownTabs = () => {
  const tenant = getTenant();
  const enableTab = ['development', 'demo', 'preview', 'staging'].includes(
    tenant.project
  );
  const unblockedTabs = enableTab
    ? tabs
    : tabs.filter(
        tab => tab.key !== 'evaluations' && tab.key !== 'assignments'
      );

  const canShowTab = useCanMultiple(
    unblockedTabs.map(({ action, resource }) => ({
      action,
      resource
    }))
  );

  const shownTabs = unblockedTabs.reduce((acc, { key, label }, i) => {
    if (canShowTab[i].data?.can) {
      acc.push({ key, label });
    }

    return acc;
  }, [] as CardTabListType[]);

  return { shownTabs };
};
