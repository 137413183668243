import * as Icons from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IAssignmentAttempt } from 'interfaces/assignments';
import { IClassroom, IClassroomEvaluation } from 'interfaces/classrooms';
import { IUser } from 'interfaces/users';
import { Show, TagField } from '@refinedev/antd';
import { getAPI } from 'requests/api';
import { getAssignmentAttemptsByUser } from 'requests/assignments';
import { getClassroomEvaluations, getClassrooms } from 'requests/classrooms';
import { tagColorByStatus } from 'utils/statusColorMapping';
import { translateAssignmentAttemptStatus } from 'services/assignments';
import { useGetIdentity, useNotification } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title } = Typography;

const columns = [
  {
    title: 'Tentativa',
    dataIndex: 'attempt',
    key: 'attempt'
  },
  {
    title: 'Nota',
    dataIndex: 'score',
    key: 'score'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    key: 'actions'
  }
];

export const UserAssignmentAttempts = () => {
  const { id } = useParams();
  const { href } = window.location;
  const url = new URL(href);
  const assignmentId = url.searchParams.get('assignmentId');
  const { open } = useNotification();
  const { data: user } = useGetIdentity<IUser>();

  const [attempts, setAttempts] = useState<IAssignmentAttempt[]>([]);
  const [classroomEvaluations, setClassroomEvaluations] = useState<
    IClassroomEvaluation[]
  >([]);
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [loading, setLoading] = useState(true);

  const getAttempts = useCallback(async () => {
    if (id && assignmentId) {
      const data = await getAssignmentAttemptsByUser(id, assignmentId);
      setAttempts(data);
    }
  }, [assignmentId, id]);

  const getEvaluations = useCallback(async () => {
    if (attempts) {
      const evaluationIds = attempts.map(
        evaluation => evaluation.evaluation_id
      );
      const data = await getClassroomEvaluations(evaluationIds);
      setClassroomEvaluations(data);
    }
  }, [attempts]);

  const getClassroom = useCallback(async () => {
    if (classroomEvaluations) {
      const classroomIds = classroomEvaluations.map(
        classroom => classroom.classroom_id
      );
      const data = await getClassrooms(classroomIds);
      setClassrooms(data);
    }
  }, [classroomEvaluations]);

  useEffect(() => {
    getAttempts();
  }, [getAttempts]);

  useEffect(() => {
    if (attempts.length > 0) {
      getEvaluations();
    }
  }, [attempts, getEvaluations]);

  useEffect(() => {
    if (classroomEvaluations.length > 0) {
      getClassroom();
      setLoading(false);
    }
  }, [classroomEvaluations, getClassroom]);

  const updateAttemptStatus = useCallback(
    async (status: string, attemptId: string) => {
      const redirect =
        (window.location.href = `/evaluations/assignment_attempts/edit/${attemptId}`);

      if (status === 'evaluation_started') {
        return redirect;
      }

      try {
        const { data, status } = await getAPI().put(
          `/admin/assignment_attempts/${attemptId}`
        );

        if (status === 200 && data.id) {
          return redirect;
        }
      } catch {
        open?.({
          type: 'error',
          description: 'Ops!',
          message: 'Não foi possível realizar a correção. Tente novamente!',
          key: attemptId
        });
      }
    },
    [open]
  );

  const acceptEvaluate = useCallback(
    (status: string, evaluatorId: string) => {
      const evaluator = user?.id;
      return (
        (evaluatorId === evaluator && status === 'evaluation_started') ||
        status === 'pending_evaluation'
      );
    },
    [user]
  );

  const hasClassroom = useMemo(() => {
    if (
      attempts.length > 0 &&
      classroomEvaluations.length > 0 &&
      classrooms.length > 0
    ) {
      return classrooms.flatMap(classroom => {
        return classroomEvaluations.flatMap(evaluation => {
          if (evaluation.classroom_id === classroom.id) {
            return attempts.filter(
              attempt => attempt.evaluation_id === evaluation.id
            );
          }
          return [];
        });
      });
    }

    return [];
  }, [attempts, classroomEvaluations, classrooms]);

  const dataSource = useMemo(() => {
    const data = hasClassroom.length > 0 ? hasClassroom : attempts;

    return data?.map(({ id, status, score, evaluator }, index) => ({
      attempt: `${index + 1}ª Tentativa`,
      status: (
        <TagField
          color={tagColorByStatus(status)}
          value={translateAssignmentAttemptStatus(status)}
        />
      ),
      score: score ?? '--',
      actions: (
        <Space>
          <Button
            size="small"
            disabled={!acceptEvaluate(status, evaluator?.id)}
            icon={<Icons.FileSearchOutlined />}
            onClick={() => updateAttemptStatus(status, id)}
          >
            Corrigir
          </Button>
        </Space>
      )
    }));
  }, [acceptEvaluate, attempts, hasClassroom, updateAttemptStatus]);

  return (
    <Show title="Tentativas do aluno">
      {loading && <p>Carregando...</p>}

      {!loading && dataSource?.length === 0 && (
        <p>Nenhuma tentativa encontrada</p>
      )}

      {!loading && dataSource?.length > 0 && (
        <>
          {hasClassroom.length > 0 ? (
            <>
              {classrooms?.map(classroom => (
                <Fragment key={classroom.id}>
                  {classroomEvaluations?.map(evaluation => (
                    <Fragment key={evaluation.id}>
                      {evaluation.classroom_id === classroom.id && (
                        <div className="mb-4">
                          <Title level={5}>
                            Turma: {classroom.code} - Disciplina:{' '}
                            {classroom.discipline.name}
                          </Title>
                          <Table dataSource={dataSource} columns={columns} />
                        </div>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </>
          ) : (
            <Table dataSource={dataSource} columns={columns} />
          )}
        </>
      )}
    </Show>
  );
};
