import * as Icons from '@ant-design/icons';
import { AssessmentAttemptLogModal } from 'components/modal/evaluations/assessment/AssessmentAttemptLogModal';
import { AssessmentEditScoreModal } from 'components/modal/evaluations/assessment/AssessmentEditScoreModal';
import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { IAssessmentAttemptShow } from 'interfaces/assessments';
import { QUESTION_STATUSES_MAP } from 'services/assessments';
import { Show, TagField } from '@refinedev/antd';
import { ShowFieldData } from 'components/ShowFieldData';
import { formatDate } from 'services/date';
import { tagColorByStatus } from '../../../utils/statusColorMapping';
import { translateAssignmentAttemptStatus } from '../../../services/assignments';
import { useMemo } from 'react';
import { useModal, useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const AssessmentAttemptShow = () => {
  const { queryResult } = useShow<IAssessmentAttemptShow>();
  const logModal = useModal();
  const editScoreModal = useModal();

  const attempt = queryResult?.data?.data;

  const onViewLogModal = () => {
    logModal.show();
  };

  const onEditScoreModal = () => {
    editScoreModal.show();
  };

  const questionsAnswersBytType = useMemo(() => {
    const answersByType = { blank: 0, wrong: 0, correct: 0, nullified: 0 };

    attempt?.questions.map(question => {
      if (question.nullified_feedback) {
        return answersByType.nullified++;
      }

      switch (question.status) {
        case 'wrong': {
          answersByType.wrong++;
          break;
        }

        case 'blank': {
          answersByType.blank++;
          break;
        }

        case 'correct': {
          answersByType.correct++;
          break;
        }

        default: {
          break;
        }
      }
    });

    return answersByType;
  }, [attempt?.questions]);

  return (
    <Show
      headerButtons={
        <>
          <Button
            icon={<Icons.EyeOutlined />}
            onClick={onEditScoreModal}
            disabled={
              !!attempt?.status &&
              !['passed', 'failed'].includes(attempt?.status)
            }
          >
            Editar nota
          </Button>

          <Button icon={<Icons.EyeOutlined />} onClick={onViewLogModal}>
            Visualizar alterações
          </Button>
        </>
      }
      title="Visualizar avaliação"
    >
      <Space direction="vertical" size="middle" className="w-full">
        <ShowFieldData
          label="Status"
          value={
            <TagField
              color={tagColorByStatus(attempt?.status || '')}
              value={translateAssignmentAttemptStatus(attempt?.status ?? '')}
            />
          }
        />

        <ShowFieldData
          label="Nome da Avaliação"
          value={attempt?.assessment.name}
        />

        <Row gutter={[24, 16]} className="w-full">
          <Col span={3}>
            <ShowFieldData
              label="Tipo de curso"
              value={
                attempt?.assessment.parent_type === 'Course'
                  ? 'Curso'
                  : 'Disciplina'
              }
            />
          </Col>

          <Col>
            <ShowFieldData
              label={
                attempt?.assessment.parent_type === 'Course'
                  ? 'Nome do curso'
                  : 'Nome da disciplina'
              }
              value={attempt?.assessment.course_name}
            />
          </Col>
        </Row>

        <Row gutter={24} className="w-full">
          <Col span={3}>
            <ShowFieldData
              label="Iniciado em"
              value={
                attempt?.created_at
                  ? formatDate(attempt.created_at, 'DD/MM/YYYY')
                  : '--'
              }
            />
          </Col>
          <Col>
            <ShowFieldData
              label="Finalizado em"
              value={
                attempt?.completed_at
                  ? formatDate(attempt.completed_at, 'DD/MM/YYYY')
                  : '--'
              }
            />
          </Col>
        </Row>

        <ShowFieldData label="Nota" value={attempt?.score ?? '--'} />
      </Space>

      <Divider />

      <Space direction="vertical" size="small" className="w-full mb-4">
        <Title level={5} className="!mb-0">
          Questões
        </Title>
        <Space size="middle">
          <Typography.Text style={{ fontSize: 12 }}>
            Corretas: {questionsAnswersBytType.correct}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12 }}>
            Erradas: {questionsAnswersBytType.wrong}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12 }}>
            Em branco: {questionsAnswersBytType.blank}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 12 }}>
            Anuladas: {questionsAnswersBytType.nullified}
          </Typography.Text>
        </Space>
      </Space>

      <Space size="middle" direction="vertical" className="w-full">
        {attempt?.questions.map((question, index) => (
          <div key={question.id} className="flex flex-col w-full gap-1">
            <div className="flex items-center gap-1">
              <Text>{index + 1} - </Text>
              <TagField
                color={
                  QUESTION_STATUSES_MAP[
                    question.nullified_feedback ? 'nullified' : question.status
                  ].color
                }
                value={
                  QUESTION_STATUSES_MAP[
                    question.nullified_feedback ? 'nullified' : question.status
                  ].label
                }
              />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: question.statement
              }}
            />
          </div>
        ))}
      </Space>

      {attempt?.id && logModal.visible && (
        <AssessmentAttemptLogModal attemptId={attempt.id} modal={logModal} />
      )}

      {attempt?.id && editScoreModal.visible && (
        <AssessmentEditScoreModal attempt={attempt} modal={editScoreModal} />
      )}
    </Show>
  );
};
