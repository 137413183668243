import React, { ReactNode, createContext, useContext } from 'react';
import { notification } from 'antd';

interface NotificationContextProps {
  showError: (description: string, message?: string) => void;
  showSuccess: (description: string, message?: string) => void;
}

export const NotificationContext =
  createContext<NotificationContextProps | null>(null);

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export const useErrorContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useErrorContext must be used within an ErrorContextProvider'
    );
  }
  return context;
};

const showError = (description: string, message = 'Oops, algo deu errado!') => {
  notification.error({
    description,
    message
  });
};

const showSuccess = (description: string, message = 'Tudo certo!') => {
  notification.success({
    description,
    message
  });
};

export const NotificationContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return (
    <NotificationContext.Provider value={{ showError, showSuccess }}>
      {children}
    </NotificationContext.Provider>
  );
};
