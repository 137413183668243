/* eslint-disable no-console */
import { Button, Form, FormProps, Modal, ModalProps, Space, Steps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ModalCreateAssigmentStep1 } from './ModalCreateAssignmentStep1';
import { ModalCreateAssigmentStep2 } from './ModalCreateAssignmentStep2';
import { ModalCreateAssigmentStep3 } from './ModalCreateAssignmentStep3';
import { useCallback, useState } from 'react';
import type { IProfessorAreaEvaluationAssignmentCreate } from 'interfaces/professor_area/assignments';

interface ModalCreateAssignmentProps {
  modalProps: ModalProps;
  formProps: FormProps<IProfessorAreaEvaluationAssignmentCreate>;
  classroomId: string;
  isLoading: boolean;
  onCloseModal: () => void;
}

const steps = [
  {
    title: 'Informações básicas',
    content: <ModalCreateAssigmentStep1 />
  },
  {
    title: 'Instruções e critérios',
    content: <ModalCreateAssigmentStep2 />
  },
  {
    title: 'Parâmetros',
    content: <ModalCreateAssigmentStep3 />
  }
];

export const ModalCreateAssigment = ({
  formProps,
  modalProps,
  classroomId,
  isLoading,
  onCloseModal
}: ModalCreateAssignmentProps) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] =
    useState<IProfessorAreaEvaluationAssignmentCreate>(
      {} as IProfessorAreaEvaluationAssignmentCreate
    );

  const onFinish = useCallback(async () => {
    try {
      await form.validateFields();
      const dataForm = { ...formData, ...form.getFieldsValue() };

      const finalData: IProfessorAreaEvaluationAssignmentCreate = {
        ...dataForm,
        evaluation_type: 'Assignment',
        classroom_id: classroomId
      };

      await formProps?.onFinish?.(finalData);
      onCloseModal();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  }, [form, formData, classroomId, formProps, onCloseModal]);

  const changeStep = async (step: number, validate?: boolean) => {
    try {
      if (validate) {
        await form.validateFields();
      }

      const currentValues = form.getFieldsValue();

      setFormData(prev => ({ ...prev, ...currentValues }));
      setCurrent(step);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      {...modalProps}
      title=""
      width={900}
      style={{ top: 20, paddingBottom: 20 }}
      centered
      maskClosable={false}
      afterClose={() => {
        form.resetFields();
        setFormData({} as IProfessorAreaEvaluationAssignmentCreate);
      }}
      footer={
        <Space className="justify-end border-t w-full pt-4">
          {current > 0 && (
            <Button
              size="large"
              icon={<LeftOutlined />}
              onClick={() => changeStep(current - 1)}
            >
              Voltar
            </Button>
          )}

          {current < steps.length - 1 && (
            <Button
              type="primary"
              size="large"
              icon={<RightOutlined />}
              iconPosition="end"
              onClick={() => changeStep(current + 1, true)}
            >
              Próximo
            </Button>
          )}

          {current === steps.length - 1 && (
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              onClick={onFinish}
            >
              Criar atividade
            </Button>
          )}
        </Space>
      }
    >
      <div className="grid grid-cols-[240px_1fr] divide-x">
        <Steps
          current={current}
          items={steps}
          className="h-fit overflow-y-auto [&_.ant-steps-item-finish_.ant-steps-item-icon]:bg-primary-500/10"
          direction="vertical"
        />
        <Form
          form={form}
          layout="vertical"
          initialValues={formData}
          className="pl-6"
        >
          {steps[current].content}
        </Form>
      </div>
    </Modal>
  );
};
