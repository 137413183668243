import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Space, Typography } from 'antd';

export const LtiContent: React.FC = () => {
  return (
    <Space direction="vertical" className="flex justify-center items-center">
      <div className="flex bg-primary-50/60 rounded-full justify-center items-center p-3">
        <div className="flex bg-primary-100 rounded-full justify-center items-center p-2">
          <InfoCircleFilled className="!text-xl !text-primary-500" />
        </div>
      </div>
      <Typography.Title level={5}>
        Conteúdo hospedado em outra plataforma via integração LTI
      </Typography.Title>
      <div className="flex flex-col text-center">
        <Typography.Text type="secondary" className="!m-0">
          Esta aula está disponível apenas na plataforma do aluno.
        </Typography.Text>
        <Typography.Text type="secondary" className="!m-0">
          Acesse através do seu ambiente de aprendizagem para visualizar.
        </Typography.Text>
      </div>
    </Space>
  );
};
