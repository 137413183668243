import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';
import { TabAssignments } from './TabAssignments';
import { TabContent } from './TabContent';
import { TabEvaluations } from './TabEvaluations/TabEvaluations';
import { TabForum } from './TabForum/TabForum';
import { TabGeneral } from './TabGeneral/TabGeneral';
import { TabInteractiveClasses } from './TabInteractiveClasses/TabInteractiveClasses';
import { TabNoticeBoard } from './TabNoticeBoard/TabNoticeBoard';
import { TabStudents } from './TabStudents/TabStudents';
import { TabSupplementarMaterial } from './TabSupplementarMaterial/TabSupplementarMaterial';
import { useCustomSearchParams } from 'hooks/useSearchParams';
import { useShownTabs } from './tabs';
import type { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';

export const TabList = ({ record }: { record: IProfessorAreaClassroom }) => {
  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({
      searchParams: 'tab'
    });

  const { shownTabs } = useShownTabs();

  const [activeTabKey, setActiveTabKey] = useState<string>(
    currentTab || 'general'
  );

  const tabContent: Record<string, React.ReactNode> | null = useMemo(() => {
    if (!record) {
      return null;
    }

    return {
      general: <TabGeneral data={record} />,
      content: <TabContent />,
      forums: <TabForum classroom={record} />,
      students: <TabStudents />,
      interactiveClass: <TabInteractiveClasses classroom={record} />,
      supplementaryMaterials: (
        <TabSupplementarMaterial classroomId={record.id} />
      ),
      noticeBoard: <TabNoticeBoard />,
      assignments: <TabAssignments />,
      evaluations: <TabEvaluations />
    };
  }, [record]);

  useEffect(() => {
    if (currentTab) {
      setActiveTabKey(currentTab);
    }
  }, [currentTab]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTabKey(tab);
      setSearchParams(tab);
    },
    [setSearchParams]
  );

  return (
    <Card
      tabList={shownTabs}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      prefixCls="custom-card"
    >
      {tabContent![activeTabKey]}
    </Card>
  );
};
