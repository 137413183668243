import { ProfessorAreaAssignmentEdit } from 'pages/teacher_space/classrooms/components/TabAssignments/assignments';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'professor_area/classroom_assignments',
  label: 'Atividades',
  edit: ProfessorAreaAssignmentEdit,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'professor_area',
  route: 'professor_area/classrooms/:classroomId/assignments'
};

export default generateResources(resource);
