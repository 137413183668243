/* eslint-disable react-hooks/exhaustive-deps */
import ContentSelector from 'components/ContentSelector';
import React, { useEffect, useState } from 'react';
import SupplementaryMaterialForm from '../forms/SupplementaryMaterialForm';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography
} from 'antd';
import { ICoreContent } from 'interfaces/core_content';
import { IDataContent } from 'interfaces/courses';
import { createPrometheusContent } from 'requests/mutation';
import { useList, useModalReturnType, useNotification } from '@refinedev/core';

interface Props {
  modal: useModalReturnType;
  node: IDataContent;
  onUpdate: (node: IDataContent) => void;
}

const ContentModal: React.FC<Props> = ({ modal, node, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<
    | {
        id: string;
        kind: string;
        libraryId: string;
      }
    | undefined
  >();

  const { open: openNotification } = useNotification();

  useEffect(() => {
    form.setFieldsValue({
      name: node.title
    });
  }, [node.title]);

  const coreContentResult = useList<ICoreContent>({
    resource: 'core_contents',

    filters: [
      {
        field: 'filter[id][]',
        operator: 'eq',
        value: [node.core_id]
      },

      {
        field: 'kind[]',
        operator: 'eq',
        value: [node.core_kind]
      }
    ]
  });

  useEffect(() => {
    const coreContentGroupedById: Record<string, ICoreContent> = {};

    if (coreContentResult.data) {
      for (const item of coreContentResult.data?.data) {
        coreContentGroupedById[item.id] = item;
      }
    }

    const core = coreContentGroupedById[node.core_id];

    if (core?.option) {
      node.core_reference = core.option.reference;
      node.core_provider = core.option.provider;
      node.core_url = core.option.url;
      node.core_status = core.option.status;
    }
  }, [coreContentResult]);

  const onClose = () => {
    // form.resetFields();
    modal.close();
  };

  const onSubmit = async () => {
    try {
      if (!content) {
        openNotification?.({
          message: 'Selecione um conteúdo',
          type: 'error'
        });

        return;
      }

      setLoading(true);

      const validatedForm = await form.validateFields();

      const prometheusContent = await createPrometheusContent(
        `${content.libraryId}:${content.id}`,
        validatedForm.name
      );

      node.core_kind = 'content';
      node.core_id = prometheusContent.id;
      node.core_provider = 'prometheus_content';
      node.name = validatedForm.name;
      node.title = validatedForm.name;

      if (node.core_id) {
        const updatedNode = {
          ...node,
          isLeaf: true
        };

        onUpdate(updatedNode);
        onClose();
        return node;
      }

      onUpdate(node);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Adicionar conteúdo"
      open={modal.visible}
      width={1000}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          disabled={!content}
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item name="core_id" style={{ height: 0, margin: 0 }}>
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Form.Item name="name" label="Nome" required>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <ContentSelector
          value={content?.id}
          onChange={(_id, content) => setContent(content)}
        />
        <Divider />
      </Form>

      <Typography.Title level={5}>Material Complementar</Typography.Title>

      <SupplementaryMaterialForm
        parent_reference={String(node.key)}
        parent_kind="content"
      />
    </Modal>
  );
};

export default ContentModal;
