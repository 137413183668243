import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface EvaluationState {
  evaluation: {
    id: string;
    name: string;
    assignment_id: string;
    passing_sore: number;
    maxAttempts: number;
  };
  classroom: {
    id: string;
    discipline: string;
    code: string;
  };
  user?: {
    id: string;
    name: string;
  };
}

export interface ProfessorAreaAssignmentsState {
  selectedEvaluation: EvaluationState | null;
}

interface ProfessorAreaAssignmentsActions {
  setSelectedEvaluation: (state: EvaluationState) => void;
}

export const useProfessorAreaAssignmentsStore = create<
  ProfessorAreaAssignmentsState & ProfessorAreaAssignmentsActions
>()(
  persist(
    set => ({
      selectedEvaluation: null,
      setSelectedEvaluation: selectedEvaluation => set({ selectedEvaluation })
    }),
    {
      name: 'professor-area-assignments-storage',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
