import { AttemptsShowTable } from './components/AttemptsShowTable';
import { Avatar, Card, Space, Typography } from 'antd';
import { EvaluationsSubheader } from 'components/professor_area/EvaluationsSubheader';
import {
  IProfessorAreaAssessmentAttemptsShow,
  IProfessorAreaAssessmentShow
} from 'interfaces/professor_area/assessments';
import { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';
import { LeftOutlined } from '@ant-design/icons';
import { Show, TagField } from '@refinedev/antd';
import { Stack } from '@qcx/ui';
import { getInitialsName } from 'utils/get-initials-name';
import { parseAttemptShowSubheaderData } from 'services/professor_area/evaluations';
import { useCustom } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const AttemptsShow = () => {
  const { classroomId, evaluationId, id } = useParams();
  const { data, isLoading } = useCustom<IProfessorAreaAssessmentAttemptsShow>({
    url: `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${id}`,
    method: 'get'
  });
  const { data: evaluationQuery } = useCustom({
    url: `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}`,
    method: 'get'
  });

  const { data: classroomData } = useCustom<IProfessorAreaClassroom>({
    url: `admin/professor_area/classrooms/${classroomId}`,
    method: 'get'
  });

  const evaluationData = evaluationQuery?.data as IProfessorAreaAssessmentShow;
  const assessmentAttempt = data?.data as IProfessorAreaAssessmentAttemptsShow;
  const classroom = classroomData?.data as IProfessorAreaClassroom;

  if (!assessmentAttempt || !evaluationData || !classroom) {
    return;
  }

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          body: {
            padding: 0,
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      <Card className="!mb-4">
        <Stack className="items-center justify-between">
          <Stack flow="column" className="gap-4">
            <Stack className="items-center gap-2">
              <Avatar size={32} className="bg-[#a855f7]/10 text-[#9333ea]">
                {assessmentAttempt.user.name &&
                  getInitialsName(assessmentAttempt.user.name)}
              </Avatar>
              <Text className="text-black/[.65] text-[16px]">
                {assessmentAttempt.user.name}
              </Text>
            </Stack>

            <Stack flow="column">
              <Title level={3}>{evaluationData.evaluation.name}</Title>
              <Stack flow="row" className="!gap-1">
                <TagField color="default" value={'Avaliação'} />
                <TagField
                  color="default"
                  value={
                    evaluationData.evaluation.kind === 'objective'
                      ? 'Objetiva'
                      : 'Discursiva'
                  }
                />
                <TagField color="default" value={classroom.code} />
                <TagField color="default" value={classroom.discipline_name} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <EvaluationsSubheader
        status={assessmentAttempt.status}
        fieldsData={parseAttemptShowSubheaderData(assessmentAttempt)}
        score={assessmentAttempt.score}
      />
      <Card>
        <Title level={4}>Tentativas do aluno</Title>
        <AttemptsShowTable dataSource={assessmentAttempt.user_attempts} />
      </Card>
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">Voltar</Text>
  </Space>
);
