import React from 'react';
import { DiscursiveQuestion } from './components/DiscursiveQuestion';
import { IProfessorAreaQuestionShow } from 'interfaces/professor_area/assessments';
import { Modal } from 'antd';
import { ObjectiveQuestion } from 'components/modal/professor_area/assessments/ModalShowQuestion/components/ObjectiveQuestion';
import { useModal } from '@refinedev/core';

interface modalProps {
  modalProps: ReturnType<typeof useModal>;
  questionData?: IProfessorAreaQuestionShow;
}

export const ModalShowQuestion: React.FC<modalProps> = ({
  modalProps,
  questionData
}) => {
  return (
    <Modal
      title="Visualizar questão"
      open={modalProps.visible}
      onCancel={modalProps.close}
      footer={null}
      cancelText="Cancelar"
      centered
    >
      {questionData?.kind === 'objective' ? (
        <ObjectiveQuestion questionData={questionData} />
      ) : (
        <DiscursiveQuestion questionData={questionData} />
      )}
    </Modal>
  );
};
