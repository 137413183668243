import { Create, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { IProduct } from 'interfaces/products';
import { IProductBundle } from 'interfaces/product_bundles';

export const ProductBundleCreate = () => {
  const { formProps, saveButtonProps } = useForm<IProductBundle>({
    redirect: 'show'
  });
  const { selectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar combo">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Produtos"
          tooltip="São permitidos no máximo 300 produtos por combo."
          name="product_ids"
          rules={[
            { required: true },
            {
              type: 'array',
              max: 300,
              message: 'Você ultrapassou o limite de 300 items'
            },
            {
              type: 'array',
              min: 2,
              message: 'Você precisa adicionar mais de um item'
            }
          ]}
        >
          <Select {...selectProps} allowClear mode="multiple" />
        </Form.Item>
      </Form>
    </Create>
  );
};
