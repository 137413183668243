import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  MessageOutlined
} from '@ant-design/icons';
import {
  DatePicker,
  Divider,
  Flex,
  Form,
  FormProps,
  Input,
  Radio,
  Space,
  Typography
} from 'antd';
import { FormItem } from 'components/modal/professor_area/classroom/FormItem';
import { IProfessorAreaNoticeBoardPost } from 'interfaces/professor_area/notice_boards';
import type { Store } from 'antd/es/form/interface';

interface FormNoticeBoardProps {
  formProps: FormProps<IProfessorAreaNoticeBoardPost>;
  isShow: boolean;
  initialValues?: Store;
  onFinish?: (formData: IProfessorAreaNoticeBoardPost) => void;
}

export const FormNoticeBoard = ({
  formProps,
  isShow,
  onFinish,
  initialValues
}: FormNoticeBoardProps) => {
  return (
    <Form
      {...formProps}
      disabled={isShow}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <fieldset>
        <Space className="items-center mb-4 mt-4">
          <MessageOutlined className="text-primary-500 text-lg" />
          <Typography.Title level={5} className="!mb-0">
            Mensagem
          </Typography.Title>
        </Space>

        <div className="pl-6">
          <FormItem
            name="title"
            label="Título"
            rules={[{ required: true, message: 'O título é obrigatório' }]}
          >
            <Input placeholder="Informe o título do aviso" />
          </FormItem>

          <FormItem
            name="description"
            label="Texto"
            rules={[{ required: true, message: 'A descrição é obrigatória' }]}
            className="mb-0"
          >
            <Input.TextArea placeholder="Escreva o texto do aviso" />
          </FormItem>
        </div>
      </fieldset>

      <Divider />

      <fieldset>
        <Flex gap={8} align="center" className="mb-2">
          <LinkOutlined className="text-primary-500 text-lg" />
          <Typography.Title level={5} className="!mb-0">
            Botão de ação
          </Typography.Title>
        </Flex>

        <Typography.Text className="block mb-4 text-neutral-500 pl-6">
          Caso necessário, adicione um botão no final da mensagem
        </Typography.Text>

        <div className="pl-6">
          <FormItem
            name="link_url"
            label="Link (URL)"
            rules={[
              {
                pattern: new RegExp(/^(https?):\/\/[^\s#$./?].\S*$/),
                message: 'Insira um link válido'
              }
            ]}
          >
            <Input placeholder="Ex.: http://site.com" />
          </FormItem>

          <FormItem name="link_text" label="Texto do link" className="mb-0">
            <Input placeholder="Ex: Veja aqui" />
          </FormItem>
        </div>
      </fieldset>

      <Divider />

      <fieldset>
        <Flex gap={8} align="center" className="mb-2">
          <CalendarOutlined className="text-primary-500 text-lg" />
          <Typography.Title level={5} className="!mb-0">
            Data de publicação
          </Typography.Title>
        </Flex>

        <Typography.Text className="block mb-4 text-neutral-500 pl-6">
          A data e hora que o aviso será enviado para a turma e ficará visível
          para os alunos
        </Typography.Text>

        <Form.Item
          name="starts_at"
          rules={[
            { required: true, message: 'A data de início é obrigatória' }
          ]}
          className="pl-6 mb-0"
        >
          <DatePicker
            showTime
            className="max-w-[200px]"
            placeholder="Selecione a data"
            format="DD/MM/YYYY hh:mm"
          />
        </Form.Item>
      </fieldset>

      <Divider />

      <fieldset className="mb-4">
        <Flex gap={8} align="center" className="mb-2">
          <ExclamationCircleOutlined className="text-primary-500 text-lg" />
          <Typography.Title level={5} className="!mb-0">
            Prioridade
          </Typography.Title>
        </Flex>

        <Typography.Text className="block mb-4 text-neutral-500 pl-6">
          A data e hora que o aviso será enviado para a turma e ficará visível
          para os alunos
        </Typography.Text>

        <Form.Item name="priority" initialValue="regular" className="pl-6">
          <Radio.Group
            defaultValue="regular"
            options={[
              { label: 'Regular', value: 'regular' },
              { label: 'Alta', value: 'high' }
            ]}
            optionType="button"
            buttonStyle="outline"
          />
        </Form.Item>
      </fieldset>
    </Form>
  );
};
