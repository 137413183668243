import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  AuditOutlined,
  CalendarOutlined,
  EllipsisOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import { EVALUATION_KIND_MAP } from 'services/graduate-enrollment/evaluations';
import {
  IProfessorAreaAssessment,
  IRequirementRule
} from 'interfaces/professor_area/assessments';
import { MenuInfo } from 'rc-menu/lib/interface';
import { TagField } from '@refinedev/antd';
import { formatDate } from 'services/date';
import { kindMap } from 'components/classroom/Evaluation';
import { useNavigation } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

const items: MenuProps['items'] = [
  {
    key: 'show',
    label: 'Visualizar avaliação',
    icon: <EyeOutlined />
  }
];

export const EvaluationsListItem: React.FC<{
  assessment: IProfessorAreaAssessment;
}> = ({ assessment }) => {
  const [hovered, setHover] = useState(false);
  const { show } = useNavigation();
  const params = useParams();
  const classroomId = params.id;

  const handleDropdown = useCallback(
    (event: MenuInfo) => {
      if (event.key === 'show') {
        show('professor_area/classroom_assessments', assessment.id, 'push', {
          classroomId
        });
      } else {
        return;
      }
    },
    [show, assessment, classroomId]
  );

  const returnDateRange = () => {
    return `${formatDate(
      assessment.requirements?.[0]?.rules.find(
        (rules: IRequirementRule) => rules.operator === 'after'
      )?.value,
      'DD/MM/YYYY'
    )} > ${formatDate(
      assessment.requirements?.[0]?.rules.find(
        (rules: IRequirementRule) => rules.operator === 'before'
      )?.value,
      'DD/MM/YYYY'
    )}`;
  };

  return (
    <div className="group flex w-full py-4 px-2 justify-between hover:bg-neutral-100 border-b-[1px] border-solid border-color-bg-neutral-100 first:border-t-[1px] first:border-solid first:border-color-bg-neutral-100">
      <Space>
        <Space
          direction="vertical"
          className="flex rounded-md w-[56px] h-[56px] p-2 bg-[#a855f7]/10 justify-center items-center !gap-0"
        >
          <AuditOutlined className="text-[#9333ea] text-2xl" />
        </Space>
        <Space direction="vertical" className="gap-0">
          <Text strong>{assessment.name}</Text>
          <Space className="gap-4">
            <Text className="!text-black/[.65]">
              {kindMap[assessment.kind]}
            </Text>
            <Text className="!text-black/[.65]">
              {EVALUATION_KIND_MAP[assessment.evaluation_kind]}
            </Text>
            <Space className="gap-1">
              <Text type="secondary">Peso</Text>
              <Text className="!text-black/[.65]">
                {assessment.weight ?? 0}
              </Text>
            </Space>
            <Space className="gap-1">
              <CalendarOutlined className="!text-black/[.45]" />
              <Text className="!text-black/[.65]">{returnDateRange()}</Text>
            </Space>
          </Space>
        </Space>
      </Space>
      <Space align="center">
        <Space>
          <TagField
            color={
              assessment.pending_evaluation_attempts_count > 0
                ? 'orange'
                : 'success'
            }
            value={`${assessment.pending_evaluation_attempts_count} avaliações a corrigir`}
          />
        </Space>
        <Dropdown
          menu={{ items, onClick: handleDropdown } as MenuProps}
          placement="bottomLeft"
          onOpenChange={setHover}
          className={clsx(
            'opacity-0 group-hover:opacity-100 group-active:opacity-100',
            hovered && 'opacity-100'
          )}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </Space>
    </div>
  );
};
