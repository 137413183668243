import React from 'react';
import { IProfessorAreaAssessmentAttempts } from 'interfaces/professor_area/assessments';
import { ShowButton, TagField, TextField } from '@refinedev/antd';
import { Table, TableProps } from 'antd';
import { evaluationsAttemptsStatus } from 'constants/admin/evaluations';
import { getStatusData } from 'services/professor_area/evaluations';
import { useParams } from 'react-router-dom';

export const AttempsTable: React.FC<{
  tableProps: TableProps<IProfessorAreaAssessmentAttempts>;
}> = ({ tableProps }) => {
  const { id } = useParams();
  return (
    <Table {...tableProps} rowKey="id">
      <Table.Column
        dataIndex={['user', 'name']}
        key="user_name"
        title="Aluno"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="registration"
        key="registration"
        title="Matrícula"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="pending_attempts"
        key="pending_attempts"
        title="Aguardando correção"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="corrected_attempts"
        key="corrected_attempts"
        title="Corrigido"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="total_attempts"
        key="total_attempts"
        title="Tentativas"
        render={value => <TextField value={value} />}
      />
      <Table.Column
        dataIndex="status"
        key="status"
        title="Status"
        render={value => (
          <TagField
            color={getStatusData(value, evaluationsAttemptsStatus)?.color}
            value={getStatusData(value, evaluationsAttemptsStatus)?.label}
          />
        )}
      />
      <Table.Column
        dataIndex="score"
        key="score"
        title="Nota"
        render={value => <TextField value={value ?? '-'} />}
      />
      <Table.Column<IProfessorAreaAssessmentAttempts>
        title="Ações"
        dataIndex="actions"
        align="center"
        render={(_, record) => {
          return (
            <ShowButton
              resource={'professor_area/classroom_assessment/attempt'}
              recordItemId={record.id}
              hideText
              size="small"
              meta={{
                evaluationId: id
              }}
            />
          );
        }}
      />
    </Table>
  );
};
