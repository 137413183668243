import React, { useCallback, useEffect } from 'react';
import useNotification from 'hooks/useNotification';
import { Avatar, Button, Card, Space, Typography } from 'antd';
import { DiscursiveQuestion } from './components/DiscursiveQuestion';
import { EvaluationsSubheader } from 'components/professor_area/EvaluationsSubheader';
import {
  IProfessorAreaAssessmentAttemptsEdit,
  IProfessorAreaAssessmentAttemptsShow
} from 'interfaces/professor_area/assessments';
import { IProfessorAreaClassroom } from 'interfaces/professor_area_classrooms';
import { LeftOutlined } from '@ant-design/icons';
import { ObjectiveQuestion } from './components/ObjectiveQuestion';
import { Show, TagField } from '@refinedev/antd';
import { Stack } from '@qcx/ui';
import {
  calcScore,
  parseAttemptEditSubheaderData,
  parseAttemptEditSubheaderDateData
} from 'services/professor_area/evaluations';
import { getAPI } from 'requests/api';
import { getInitialsName } from 'utils/get-initials-name';
import { useBack, useCustom } from '@refinedev/core';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export const AttemptsCorrectionShow: React.FC = () => {
  const { id, classroomId, evaluationId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const back = useBack();
  const { showError, showSuccess } = useNotification();
  const { data, isLoading, refetch } =
    useCustom<IProfessorAreaAssessmentAttemptsEdit>({
      url: `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${id}/edit`,
      method: 'get'
    });
  const { data: attemptshow, refetch: refetchAttempt } =
    useCustom<IProfessorAreaAssessmentAttemptsShow>({
      url: `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${id}`,
      method: 'get'
    });
  const { data: classroomData } = useCustom<IProfessorAreaClassroom>({
    url: `admin/professor_area/classrooms/${classroomId}`,
    method: 'get'
  });

  const assessmentAttempt =
    attemptshow?.data as IProfessorAreaAssessmentAttemptsShow;
  const userAttempt = data?.data as IProfessorAreaAssessmentAttemptsEdit;
  const classroom = classroomData?.data as IProfessorAreaClassroom;

  const getNumeroDaTentativa = () => {
    if (assessmentAttempt && id) {
      return assessmentAttempt.user_attempts.map(el => el.id).indexOf(id) + 1;
    }
  };

  const startEvalutation = useCallback(async () => {
    await getAPI()
      .patch(
        `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${id}/start_evaluation`
      )
      .finally(() => {
        setLoading(false);
        refetch();
        refetchAttempt();
      })
      .catch(() => {
        showError('Não foi possível iniciar a correção da avaliação.');
        setLoading(false);
      });
  }, [classroomId, evaluationId, id, refetch, refetchAttempt, showError]);

  useEffect(() => {
    if (
      ['pending', 'pending_evaluation', 'completed'].includes(
        assessmentAttempt?.status
      )
    ) {
      startEvalutation();
    }
  }, [startEvalutation, assessmentAttempt?.status]);

  const onFinishEvaluation = async () => {
    setLoading(true);
    await getAPI()
      .patch(
        `admin/professor_area/classrooms/${classroomId}/evaluations/${evaluationId}/attempts/${id}/finish_evaluation`
      )
      .finally(() => {
        showSuccess('Correção da avaliação finalizada com sucesso.');
        setLoading(false);
        refetch();
        refetchAttempt();
      })
      .catch(() => {
        showError('Não foi possível finalizar a correção da avaliação.');
        setLoading(false);
      });
  };

  if (!userAttempt || !assessmentAttempt || !classroom) {
    return null;
  }

  return (
    <Show
      breadcrumb={false}
      isLoading={isLoading || loading}
      headerProps={{
        title: false
      }}
      goBack={<GoBack />}
      headerButtonProps={{
        hidden: true
      }}
      contentProps={{
        className: '!bg-transparent !shadow-none',
        styles: {
          body: {
            padding: 0,
            backgroundColor: 'transparent'
          }
        }
      }}
    >
      <Card className="!mb-4">
        <Stack className="items-center justify-between">
          <Stack flow="column" className="gap-4">
            <Stack className="items-center gap-2">
              <Avatar size={32} className="bg-[#a855f7]/10 text-[#9333ea]">
                {assessmentAttempt?.user?.name &&
                  getInitialsName(assessmentAttempt?.user?.name)}
              </Avatar>
              <Text className="text-black/[.65] text-[16px]">
                {assessmentAttempt?.user?.name}
              </Text>
            </Stack>

            <Stack flow="column">
              <Title level={3}>{`${getNumeroDaTentativa()}ª Tentativa`}</Title>
              <Stack flow="row" className="!gap-1">
                <TagField color="default" value={'Avaliação'} />
                <TagField color="default" value={classroom.code} />
                <TagField color="default" value={classroom.discipline_name} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <EvaluationsSubheader
        status={assessmentAttempt?.status}
        fieldsData={parseAttemptEditSubheaderData(
          assessmentAttempt,
          userAttempt
        )}
        dateFields={parseAttemptEditSubheaderDateData(assessmentAttempt)}
        score={calcScore(userAttempt)}
      />
      <Card styles={{ body: { padding: 0 } }}>
        <Stack flow="column" className="p-6">
          {userAttempt.assessment.kind === 'discursive' ? (
            <>
              {userAttempt.answers.map((question, index) => (
                <DiscursiveQuestion
                  key={index.toString()}
                  question={question}
                  index={(index + 1).toString()}
                  refetchData={refetch}
                  attemptStatus={assessmentAttempt.status}
                />
              ))}
              <Space className="w-full flex rounded-md bg-gray-100 py-6 px-4 justify-end border border-color-bg-gray-300">
                <Title level={5} className="!m-0">
                  {`Nota: ${
                    calcScore(userAttempt) / userAttempt.questions.length
                  }`}
                </Title>
              </Space>
            </>
          ) : (
            <>
              {userAttempt.answers.map((answer, index) => (
                <ObjectiveQuestion
                  key={index.toString()}
                  answer={answer}
                  questions={userAttempt.questions}
                  index={(index + 1).toString()}
                />
              ))}
            </>
          )}
        </Stack>
        {userAttempt.assessment.kind === 'discursive' && (
          <Stack className="gap-2 justify-end p-4 border-t-solid border-t border-color-black/[0.8]">
            <Button type="default" loading={loading} onClick={back}>
              Voltar
            </Button>
            {assessmentAttempt?.status === 'evaluation_started' && (
              <Button
                type="primary"
                loading={loading}
                onClick={onFinishEvaluation}
              >
                Publicar nota
              </Button>
            )}
          </Stack>
        )}
      </Card>
    </Show>
  );
};

const GoBack = () => (
  <Space className="items-center !text-neutral-500">
    <LeftOutlined />
    <Text className="!text-neutral-500 font-normal">Voltar</Text>
  </Space>
);
