import { AuditOutlined } from '@ant-design/icons';
import { Card, Flex, Tag } from 'antd';
import { EditLayout } from 'components/professor_area/layout/EditLayout';
import { HttpError, useOne } from '@refinedev/core';
import { PageHeader } from 'components/professor_area/PageHeader';
import { TabAttempts } from './components/TabAttempts';
import { TabGeneral } from './components/TabGeneral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomSearchParams } from 'hooks/useSearchParams';
import { useForm } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import { useProfessorAreaAssignmentsStore } from './assignments.store';
import type {
  IProfessorAreaEvaluationEdit,
  IProfessorAreaEvaluationShow
} from 'interfaces/professor_area/evaluations';
import type { IProfessorClassroom } from 'interfaces/professor_area/classrooms';

const tabs = [
  {
    key: 'general',
    tab: 'Visão geral'
  },
  {
    key: 'attempts',
    tab: 'Atividades realizadas'
  }
];

export const ProfessorAreaAssignmentEdit = () => {
  const paramas = useParams();
  const classroomId = paramas.classroomId;
  const evaluationId = paramas.id;

  const setSelectedEvaluation = useProfessorAreaAssignmentsStore(
    s => s.setSelectedEvaluation
  );

  const { currentSearchParams: currentTab, setSearchParams } =
    useCustomSearchParams({
      searchParams: 'tab'
    });

  const [activeTabKey, setActiveTabKey] = useState<string>(
    currentTab || 'general'
  );

  const { data, isLoading: classroomDetailsLoading } =
    useOne<IProfessorClassroom>({
      resource: `professor_area/classrooms`,
      id: classroomId
    });

  const { formProps, queryResult, formLoading, form, onFinish } = useForm<
    IProfessorAreaEvaluationShow,
    HttpError,
    IProfessorAreaEvaluationEdit
  >({
    resource: `professor_area/classrooms/${classroomId}/evaluations`,
    action: 'edit',
    id: evaluationId,
    errorNotification: () => {
      return {
        message: 'Erro ao atualizar atividade. Tente novamente!',
        description: 'Erro',
        type: 'error'
      };
    },
    successNotification: () => {
      return {
        message: 'Atividade atualizada com sucesso',
        description: 'Sucesso',
        type: 'success'
      };
    }
  });

  const isLoading = useMemo(() => {
    if (queryResult) {
      const { isLoading } = queryResult;
      return isLoading && classroomDetailsLoading;
    }

    return false;
  }, [queryResult, classroomDetailsLoading]);

  const record = useMemo(() => queryResult?.data?.data, [queryResult]);
  const classroomDetails = useMemo(() => data?.data, [data]);

  useEffect(() => {
    if (record && classroomDetails) {
      setSelectedEvaluation({
        evaluation: {
          id: record?.id,
          name: record.name,
          assignment_id: record?.evaluation_id,
          maxAttempts: record?.max_attempts,
          passing_sore: record?.passing_score
        },
        classroom: {
          code: classroomDetails?.code,
          discipline: classroomDetails?.discipline_name,
          id: classroomDetails?.id
        }
      });
    }
  }, [record, classroomDetails, setSelectedEvaluation]);

  const onTabChange = useCallback(
    (tab: string) => {
      setActiveTabKey(tab);
      setSearchParams(tab);
    },
    [setSearchParams]
  );

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();

      const { weight, starts_at, ends_at, name, max_attempts, passing_score } =
        form.getFieldsValue();

      const formData: IProfessorAreaEvaluationEdit = {
        weight,
        starts_at,
        ends_at,
        name,
        max_attempts,
        passing_score
      };

      await onFinish(formData);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.scrollToField((error as any).errorFields[0].name);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [form, onFinish]);

  const tabContent: Record<string, React.ReactNode> | null = useMemo(() => {
    if (!record) {
      return null;
    }

    return {
      general: (
        <TabGeneral
          formProps={formProps}
          onSubmit={onSubmit}
          data={record}
          isLoading={formLoading}
        />
      ),
      attempts: <TabAttempts />
    };
  }, [formLoading, formProps, onSubmit, record]);

  return (
    <EditLayout isLoading={isLoading} footerButtonProps={{ hidden: true }}>
      <PageHeader
        title={record?.name || ''}
        icon={AuditOutlined}
        meta={
          <Flex gap={4}>
            <Tag>Atividade</Tag>
            <Tag>{classroomDetails?.code}</Tag>
            <Tag>{classroomDetails?.discipline_name}</Tag>
          </Flex>
        }
      />

      <Card
        tabList={tabs}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        prefixCls="custom-card"
        className="rounded-b-none"
        styles={{
          body: {
            borderRadius: 0
          }
        }}
      >
        {record && <>{tabContent![activeTabKey]}</>}
      </Card>
    </EditLayout>
  );
};
