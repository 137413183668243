import Icon from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import type { ReactNode } from 'react';

interface FieldsetTitleProps {
  title: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  hint?: ReactNode;
}

export const FieldsetTitle = ({ title, icon, hint }: FieldsetTitleProps) => {
  return (
    <Flex vertical gap={8} className="mb-4">
      <Flex gap={8} align="center">
        {icon && <Icon component={icon} className="text-primary-500 text-lg" />}
        <Typography.Title level={5} className="!mb-0">
          {title}
        </Typography.Title>
      </Flex>

      {hint && (
        <Typography.Text className="block text-neutral-500 pl-6">
          {hint}
        </Typography.Text>
      )}
    </Flex>
  );
};
