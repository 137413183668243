import { HomeOutlined } from '@ant-design/icons';
import { Homepage } from 'pages/home/Homepage';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'initial',
  label: 'Início',
  list: Homepage,
  icon: <HomeOutlined />,
  route: 'home',
  parentName: 'home',
  onlyPermission: true
};

export default generateResources(resource);
