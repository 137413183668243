import React from 'react';
import { Divider, Spin, Typography } from 'antd';
import { EvaluationsList } from './components/EvaluationsList';
import { IProfessorAreaAssessment } from 'interfaces/professor_area/assessments';
import { useParams } from 'react-router-dom';
import { useTable } from '@refinedev/antd';

export const TabEvaluations: React.FC = () => {
  const { id } = useParams();
  const {
    tableQueryResult: { data, isLoading },
    current,
    setCurrent,
    pageSize
  } = useTable<IProfessorAreaAssessment>({
    resource: `professor_area/classrooms/${id}/evaluations`,
    initialPageSize: 10,
    filters: {
      permanent: [
        {
          operator: 'eq',
          field: 'filter[by_evaluation_type]',
          value: 'Assessment'
        }
      ]
    }
  });

  const evaluations = data?.data as IProfessorAreaAssessment[];

  return (
    <div>
      <Typography.Title level={5} className="!m-0 !mt-2">
        Avaliações da turma
      </Typography.Title>

      <Divider className="!m-0 !mt-4" />
      {isLoading && <Spin size="large" />}
      {evaluations?.length > 0 && (
        <EvaluationsList
          data={evaluations}
          total={data?.total ?? 0}
          pageSize={pageSize}
          currentPage={current}
          onPageChange={setCurrent}
        />
      )}
    </div>
  );
};
