import CourseProfessor from 'components/professors/CourseProfessor';
import TabDocumentation from './components/TabDocumentation';
import TabStudents from './components/TabStudents';
import { Edit, useForm } from '@refinedev/antd';
import { IGraduateCourseShow } from 'interfaces/graduate_courses';
import { TabDisciplines } from './components/TabDisciplines';
import { TabForum } from './components/TabForum';
import { TabGeneralInfo } from './components/TabGeneralInfo';
import { Tabs } from 'antd';
import { getTenant } from 'services/tenants';
import { useMemo, useState } from 'react';
import type { HttpError } from '@refinedev/core';

export const GraduateCourseEdit = () => {
  const [activeTab, setActiveTab] = useState('info');
  const tenant = getTenant();

  const { formProps, saveButtonProps, queryResult } = useForm<
    IGraduateCourseShow,
    HttpError,
    IGraduateCourseShow
  >({
    redirect: 'edit'
  });

  const record = useMemo(() => queryResult?.data?.data, [queryResult]);

  return (
    <Edit
      title="Editar curso regulado"
      footerButtonProps={{
        style: {
          justifyContent: 'flex-end',
          display: ['info', 'professor'].includes(activeTab) ? 'flex' : 'none'
        }
      }}
      saveButtonProps={saveButtonProps}
    >
      <Tabs
        defaultActiveKey={activeTab}
        onChange={activeKey => setActiveTab(activeKey)}
      >
        <Tabs.TabPane tab="Informações gerais" key="info">
          <TabGeneralInfo formProps={formProps} record={record} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Módulos e Disciplinas" key="courseContent">
          <TabDisciplines />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Alunos" key="courseStudents">
          <TabStudents />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Documentação" key="courseDocumentation">
          <TabDocumentation />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Coordenador do curso" key="professor">
          {queryResult?.data?.data && (
            <CourseProfessor course={queryResult.data.data} />
          )}
        </Tabs.TabPane>

        {!tenant.disabledFeatures?.includes('forums') && (
          <Tabs.TabPane tab="Fóruns" key="forum">
            <TabForum />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Edit>
  );
};
